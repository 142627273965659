// src/components/layout/AuthLayout.js
import React, { useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Home, LogIn, UserPlus, MessageCircle, Check, Menu } from 'lucide-react';
import kodavaLogo  from './kodava_symbol.png';

const AuthHeader = () => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  return (
    <header className="fixed top-0 left-0 right-0 bg-white bg-opacity-80 backdrop-filter backdrop-blur-md border-b border-gray-200 h-16 sm:h-20 z-20 shadow-sm overflow-hidden">
      {/* Decorative background elements - adjusted for mobile */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-10 -right-10 w-24 sm:w-32 h-24 sm:h-32 bg-blue-300 rounded-full opacity-10"></div>
        <div className="absolute top-5 right-20 w-12 sm:w-16 h-12 sm:h-16 bg-purple-300 rounded-full opacity-10"></div>
        <div className="absolute -top-5 right-40 w-16 sm:w-24 h-16 sm:h-24 bg-indigo-300 rounded-full opacity-15"></div>
        <div className="absolute top-10 left-20 w-16 sm:w-20 h-16 sm:h-20 bg-sky-300 rounded-full opacity-10"></div>
        <div className="absolute -left-10 -top-10 w-24 sm:w-32 h-24 sm:h-32 bg-teal-300 rounded-full opacity-10"></div>
        
        {/* Subtle wave pattern */}
        <svg className="absolute inset-0 h-full w-full" preserveAspectRatio="none" viewBox="0 0 1200 120" xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.05 }}>
          <path d="M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18.19 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z" opacity=".25" />
          <path d="M0 0v15.81c13 21.11 27.64 41.05 47.69 56.24C99.41 111.27 165 111 224.58 91.58c31.15-10.15 60.09-26.07 89.67-39.8 40.92-19 84.73-46 130.83-49.67 36.26-2.85 70.9 9.42 98.6 31.56 31.77 25.39 62.32 62 103.63 73 40.44 10.79 81.35-6.69 119.13-24.28s75.16-39 116.92-43.05c59.73-5.85 113.28 22.88 168.9 38.84 30.2 8.66 59 6.17 87.09-7.5 22.43-10.89 48-26.93 60.65-49.24V0z" opacity=".5" />
          <path d="M0 0v5.63C149.93 59 314.09 71.32 475.83 42.57c43-7.64 84.23-20.12 127.61-26.46 59-8.63 112.48 12.24 165.56 35.4C827.93 77.22 886 95.24 951.2 90c86.53-7 172.46-45.71 248.8-84.81V0z" />
        </svg>
      </div>

      <div className="h-full max-w-7xl mx-auto px-3 sm:px-6 lg:px-8 relative z-10">
        <div className="flex items-center justify-between h-full">
          {/* Logo and branding - responsive sizing */}
          <div className="flex items-start">
            <div className="flex flex-col">
              <Link to="/" className="flex items-center group">
                <span className="text-lg sm:text-2xl font-serif font-bold bg-gradient-to-r from-indigo-500 to-purple-500 bg-clip-text text-transparent transition-all duration-300 group-hover:from-purple-600 group-hover:to-indigo-600">Kodava Sangama</span>
              </Link>
              <div className="mt-0 inline-flex items-center justify-center px-2 sm:px-4 py-1 rounded-full bg-teal-50 border border-teal-100">
                <Check className="h-3 w-3 sm:h-4 sm:w-4 text-teal-500 mr-1 sm:mr-2" />
                <span className="text-teal-600 font-medium text-xs sm:text-sm">Exclusive Kodava Matrimony</span>
              </div>
            </div>
            <img 
              src={kodavaLogo} 
              alt="Kodava Symbol" 
              className="h-14 w-16 sm:h-20 sm:w-22 ml-1 sm:ml-2 opacity-80 self-center" 
            />
          </div>
          
          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-4">
            <Link 
              to="/" 
              className={`flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 shadow-sm ${
                isActive('/') 
                  ? 'bg-gradient-to-r from-indigo-500 to-purple-600 text-white border-2 border-blue-700' 
                  : 'bg-white text-gray-700 hover:bg-gradient-to-r hover:from-indigo-50 hover:to-purple-50 hover:text-indigo-700 border-2 border-blue-300 hover:border-blue-500'
              }`}
            >
              <Home className={`h-5 w-5 mr-2 ${isActive('/') ? 'text-white' : 'text-indigo-400 group-hover:text-indigo-500'}`} />
              <span className="font-serif">Home</span>
            </Link>
            
            <Link 
              to="/login" 
              className={`flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 shadow-sm ${
                isActive('/login') 
                  ? 'bg-gradient-to-r from-indigo-500 to-purple-600 text-white border-2 border-blue-700' 
                  : 'bg-white text-gray-700 hover:bg-gradient-to-r hover:from-indigo-50 hover:to-purple-50 hover:text-indigo-700 border-2 border-blue-300 hover:border-blue-500'
              }`}
            >
              <LogIn className={`h-5 w-5 mr-2 ${isActive('/login') ? 'text-white' : 'text-indigo-400 group-hover:text-indigo-500'}`} />
              <span className="font-serif">Login</span>
            </Link>
            
            <Link 
              to="/register" 
              className={`flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 shadow-sm ${
                isActive('/register') 
                  ? 'bg-gradient-to-r from-indigo-500 to-purple-600 text-white border-2 border-blue-700' 
                  : 'bg-white text-gray-700 hover:bg-gradient-to-r hover:from-indigo-50 hover:to-purple-50 hover:text-indigo-700 border-2 border-blue-300 hover:border-blue-500'
              }`}
            >
              <UserPlus className={`h-5 w-5 mr-2 ${isActive('/register') ? 'text-white' : 'text-indigo-400 group-hover:text-indigo-500'}`} />
              <span className="font-serif">Register</span>
            </Link>
            
            <Link 
              to="/support" 
              className={`flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 shadow-sm ${
                isActive('/support') 
                  ? 'bg-gradient-to-r from-indigo-500 to-purple-600 text-white border-2 border-blue-700' 
                  : 'bg-white text-gray-700 hover:bg-gradient-to-r hover:from-indigo-50 hover:to-purple-50 hover:text-indigo-700 border-2 border-blue-300 hover:border-blue-500'
              }`}
            >
              <MessageCircle className={`h-5 w-5 mr-2 ${isActive('/support') ? 'text-white' : 'text-indigo-400 group-hover:text-indigo-500'}`} />
              <span className="font-serif">Support</span>
            </Link>
          </nav>
          
          {/* Mobile Navigation */}
          <div className="flex md:hidden">
            {/* Icon-only navigation buttons for small devices */}
            <nav className="flex items-center space-x-1 sm:space-x-2">
              <Link 
                to="/" 
                className={`flex items-center justify-center p-2 text-sm font-medium rounded-lg transition-all duration-200 shadow-sm h-9 w-9 ${
                  isActive('/') 
                    ? 'bg-gradient-to-r from-indigo-500 to-purple-600 text-white border border-blue-700' 
                    : 'bg-white text-gray-700 hover:bg-gray-100 border border-blue-300'
                }`}
              >
                <Home className={`h-5 w-5 ${isActive('/') ? 'text-white' : 'text-indigo-400'}`} />
              </Link>
              
              <Link 
                to="/login" 
                className={`flex items-center justify-center p-2 text-sm font-medium rounded-lg transition-all duration-200 shadow-sm h-9 w-9 ${
                  isActive('/login') 
                    ? 'bg-gradient-to-r from-indigo-500 to-purple-600 text-white border border-blue-700' 
                    : 'bg-white text-gray-700 hover:bg-gray-100 border border-blue-300'
                }`}
              >
                <LogIn className={`h-5 w-5 ${isActive('/login') ? 'text-white' : 'text-indigo-400'}`} />
              </Link>
              
              <Link 
                to="/register" 
                className={`flex items-center justify-center p-2 text-sm font-medium rounded-lg transition-all duration-200 shadow-sm h-9 w-9 ${
                  isActive('/register') 
                    ? 'bg-gradient-to-r from-indigo-500 to-purple-600 text-white border border-blue-700' 
                    : 'bg-white text-gray-700 hover:bg-gray-100 border border-blue-300'
                }`}
              >
                <UserPlus className={`h-5 w-5 ${isActive('/register') ? 'text-white' : 'text-indigo-400'}`} />
              </Link>
              
              <Link 
                to="/support" 
                className={`flex items-center justify-center p-2 text-sm font-medium rounded-lg transition-all duration-200 shadow-sm h-9 w-9 ${
                  isActive('/support') 
                    ? 'bg-gradient-to-r from-indigo-500 to-purple-600 text-white border border-blue-700' 
                    : 'bg-white text-gray-700 hover:bg-gray-100 border border-blue-300'
                }`}
              >
                <MessageCircle className={`h-5 w-5 ${isActive('/support') ? 'text-white' : 'text-indigo-400'}`} />
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

const AuthFooter = () => (
  <footer className="bg-white bg-opacity-90 backdrop-filter backdrop-blur-sm border-t border-gray-200 py-3 w-full">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <span className="text-xs sm:text-sm font-serif font-bold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent mr-2">Kodava Sangama</span>
          <span className="text-xs text-gray-500">&copy; {new Date().getFullYear()}</span>
        </div>
        
        <div>
          <Link to="/support" className="text-xs font-serif text-indigo-600 hover:text-indigo-700 transition-colors">Contact Us</Link>
        </div>
      </div>
    </div>
  </footer>
);

const AuthLayout = () => {
  // Add effect to force scrollbar to be present
  useEffect(() => {
    // Add overflow-y-scroll to html element to force scrollbar to always be visible
    document.documentElement.classList.add('overflow-y-scroll');
    
    // Cleanup function to remove the class when component unmounts
    return () => {
      document.documentElement.classList.remove('overflow-y-scroll');
    };
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-purple-50 to-pink-50 flex flex-col relative">
      {/* Decorative elements */}
      <div className="absolute top-40 right-20 w-64 sm:w-80 h-64 sm:h-80 bg-gradient-to-br from-purple-300 to-indigo-300 rounded-full filter blur-3xl opacity-20 -z-10"></div>
      <div className="absolute bottom-40 left-20 w-64 sm:w-80 h-64 sm:h-80 bg-gradient-to-br from-amber-300 to-rose-300 rounded-full filter blur-3xl opacity-20 -z-10"></div>
      
      <AuthHeader />
      
      {/* Mobile-only notification banner */}
      <div className="block md:hidden fixed top-16 left-0 right-0 bg-indigo-100 border-b border-indigo-200 py-2 px-4 text-center z-10 shadow-sm">
        <p className="text-sm text-red-400 font-medium">
          <span className="inline-flex items-center">
            <span>This matrimony site is not accessible on mobile devices. Please use a computer or laptop to browse and connect.</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
            </svg>
          </span>
        </p>
      </div>

      <main className="flex-1 pt-16 pb-16 min-h-[calc(100vh-4rem)]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="md:hidden h-8"></div>
          <Outlet />
        </div>
      </main>
      
      <AuthFooter />
    </div>
  );
};

export default AuthLayout;