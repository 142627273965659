import React from 'react';
import { Globe, Building, FileText } from 'lucide-react';
import { InfoCard, InlineInputWithValidation, InputWithValidation, EnhancedSelect } from '../../components';
import { COUNTRY_OPTIONS } from '../../constants';

export const Residence = ({ formData, handleChange }) => {
  // Validation states
  const validations = {
    residence_country: formData.residence_country && formData.residence_country !== '',
    residence_city: formData.residence_city && formData.residence_city !== '',
    parents_city: formData.parents_city && formData.parents_city !== '',
    parents_country: formData.parents_country && formData.parents_country !== '',
    residence_details: formData.residence_details !== ''
  };

  return (
    <div className="space-y-6">
      {/* Current Residence */}
      <InfoCard 
        title="Current Residence" 
        icon={Globe} 
        type="personal"
      >
        <div className="grid grid-cols-1 gap-4">
          <InlineInputWithValidation
            label="Country of Residence"
            icon={Globe}
            value={formData.residence_country}
            isValid={validations.residence_country}
            helper="Choose the country where you live and work currently"
            required={true}
          >
            <EnhancedSelect
              name="residence_country"
              value={formData.residence_country}
              onChange={handleChange}
              options={COUNTRY_OPTIONS}
              placeholder="Select Country"
              icon={Globe}
              required
            />
          </InlineInputWithValidation>

          <InlineInputWithValidation
            label="City / Town"
            icon={Building}
            value={formData.residence_city}
            isValid={validations.residence_city}
            helper="Example: Madikeri | Austin, Texas"
            required={true}
          >
            <input
              type="text"
              name="residence_city"
              value={formData.residence_city}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder="Enter your city"
              required
            />
          </InlineInputWithValidation>
        </div>
      </InfoCard>

      <InfoCard 
        title="Parents Residence" 
        icon={Globe} 
        type="personal"
      >
        <div className="grid grid-cols-1 gap-4">
          <InlineInputWithValidation
            label="Country of your parents' residence"
            icon={Globe}
            value={formData.parents_country}
            isValid={validations.parents_country}
            helper="Choose the country where your parents live"
            required={true}
          >
            <EnhancedSelect
              name="parents_country"
              value={formData.parents_country}
              onChange={handleChange}
              options={COUNTRY_OPTIONS}
              placeholder="Select Country"
              icon={Globe}
              required
            />
          </InlineInputWithValidation>

          <InlineInputWithValidation
            label="City / Town"
            icon={Building}
            value={formData.parents_city}
            isValid={validations.parents_city}
            helper="Example: Madikeri | Austin, Texas"
            required={true}
          >
            <input
              type="text"
              name="parents_city"
              value={formData.parents_city}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder="Enter your parents city"
              required
            />
          </InlineInputWithValidation>
        </div>
      </InfoCard>

      {/* Additional Details */}
      <InfoCard 
        title="Additional Residence Details" 
        icon={FileText} 
        type="verification"
      >
        <div className="grid grid-cols-1 gap-4">
          <InputWithValidation
            label="Additional Information"
            value={formData.residence_details}
            isValid={validations.residence_details}
            required={true}
            helper={
              <div className="space-y-1">
                <p>Examples:</p>
                <ul className="list-disc pl-4 space-y-1">
                  <li>Mumbai's where I work, but my family's got a coffee estate in Kodagu where my parents live</li>
                  <li>I grew up here in the US, and that's where I live, but I make sure to visit my relatives in India once a year</li>
                  <li>Working in Bangalore for 5 years, parents live in ancestral home in Suntikoppa. Willing to relocate to any location.</li>
                  <li>Based in Singapore, planning to return to family property in Coorg within 2 years</li>
                  <li>Currently working in US with H1B visa, parents maintain our family house in Madikeri</li>
                </ul>
              </div>
            }
          >
            <textarea
              name="residence_details"
              value={formData.residence_details}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-400 focus:border-teal-400 bg-white min-h-[120px]"
              placeholder="Describe your residence situation..."
            />
          </InputWithValidation>
        </div>
      </InfoCard>
    </div>
  );
};

export default Residence;