import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Search as SearchIcon,
  Users, 
  Heart, 
  Ruler, 
  MapPin, 
  GraduationCap,
  Filter,
  ArrowLeft,
  ArrowRight,
  ExternalLink,
  Calendar,
  User,
  UserCircle2,
  Clock,
  Check
} from 'lucide-react';
import { 
  COMMUNITY_CHOICES, 
  MARITAL_STATUS_CHOICES, 
  COUNTRY_OPTIONS,
  EDUCATION_CHOICES 
} from '../constants';
import { InfoCard, EnhancedSelect, ErrorMessage } from '../components';
import { browseProfiles } from '../../services/api';
import { generateHeightOptions, cmToFeetInches } from '../utils';

const ITEMS_PER_PAGE = 9;

const SearchFilters = ({ filters, handleFilterChange, handleSubmit, clearFilters, loading }) => (
  <InfoCard title="Search Filters" icon={Filter} type="verification">
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="bg-white p-4 rounded-lg shadow-sm border-2 border-sky-100 hover:border-sky-300 transition-all duration-200">
          <label className="block text-sm font-medium text-gray-800 mb-2 flex items-center">
            <Users className="h-4 w-4 text-sky-600 mr-2" />
            Community
          </label>
          <EnhancedSelect
            name="community"
            value={filters.community}
            onChange={handleFilterChange}
            options={COMMUNITY_CHOICES}
            placeholder="All Communities"
            icon={Users}
          />
        </div>

        <div className="bg-white p-4 rounded-lg shadow-sm border-2 border-sky-100 hover:border-sky-300 transition-all duration-200">
          <label className="block text-sm font-medium text-gray-800 mb-2 flex items-center">
            <Heart className="h-4 w-4 text-sky-600 mr-2" />
            Marital Status
          </label>
          <EnhancedSelect
            name="marital_status"
            value={filters.marital_status}
            onChange={handleFilterChange}
            options={MARITAL_STATUS_CHOICES}
            placeholder="All Statuses"
            icon={Heart}
          />
        </div>

        <div className="bg-white p-4 rounded-lg shadow-sm border-2 border-sky-100 hover:border-sky-300 transition-all duration-200">
          <label className="block text-sm font-medium text-gray-800 mb-2 flex items-center">
            <GraduationCap className="h-4 w-4 text-sky-600 mr-2" />
            Highest Education
          </label>
          <EnhancedSelect
            name="highest_education"
            value={filters.highest_education}
            onChange={handleFilterChange}
            options={EDUCATION_CHOICES}
            placeholder="All Education Levels"
            icon={GraduationCap}
          />
        </div>

        <div className="bg-gradient-to-br from-sky-50 to-indigo-50 p-4 rounded-lg shadow-md border-2 border-sky-100 hover:shadow-lg transition-all duration-200">
          <label className="block text-sm font-medium text-gray-800 mb-2 flex items-center">
            <Calendar className="h-4 w-4 text-sky-600 mr-2" />
            Age Range
          </label>
          <div className="grid grid-cols-2 gap-4">
            <div className="relative">
              <input
                type="number"
                name="min_age"
                value={filters.min_age}
                onChange={handleFilterChange}
                placeholder="Min Age"
                className="w-full p-2 border-2 border-sky-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-300 bg-white"
                min="18"
                max="100"
              />
            </div>
            <div className="relative">
              <input
                type="number"
                name="max_age"
                value={filters.max_age}
                onChange={handleFilterChange}
                placeholder="Max Age"
                className="w-full p-2 border-2 border-sky-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-300 bg-white"
                min="18"
                max="100"
              />
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-br from-sky-50 to-indigo-50 p-4 rounded-lg shadow-md border-2 border-sky-100 hover:shadow-lg transition-all duration-200">
          <label className="block text-sm font-medium text-gray-800 mb-2 flex items-center">
            <Ruler className="h-4 w-4 text-sky-600 mr-2" />
            Height Range
          </label>
          <div className="grid grid-cols-2 gap-4">
            <div className="relative">
              <select
                name="min_height"
                value={filters.min_height}
                onChange={handleFilterChange}
                className="w-full pl-3 pr-10 py-2 border-2 border-sky-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-300 bg-white text-sm appearance-none"
              >
                <option value="">Min Height</option>
                {generateHeightOptions().map(({ feet, inches, cm }) => (
                  <option key={cm} value={cm}>
                    {`${feet}' ${inches}" (${cm} cm)`}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <svg className="h-5 w-5 text-sky-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
            <div className="relative">
              <select
                name="max_height"
                value={filters.max_height}
                onChange={handleFilterChange}
                className="w-full pl-3 pr-10 py-2 border-2 border-sky-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-300 bg-white text-sm appearance-none"
              >
                <option value="">Max Height</option>
                {generateHeightOptions().map(({ feet, inches, cm }) => (
                  <option key={cm} value={cm}>
                    {`${feet}' ${inches}" (${cm} cm)`}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <svg className="h-5 w-5 text-sky-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow-sm border-2 border-sky-100 hover:border-sky-300 transition-all duration-200">
          <label className="block text-sm font-medium text-gray-800 mb-2 flex items-center">
            <MapPin className="h-4 w-4 text-sky-600 mr-2" />
            Country
          </label>
          <EnhancedSelect
            name="country"
            value={filters.country}
            onChange={handleFilterChange}
            options={COUNTRY_OPTIONS}
            placeholder="All Countries"
            icon={MapPin}
          />
        </div>
      </div>

      <div className="flex justify-center gap-6 pt-4 pb-1 border-t-2 border-sky-100">
        <button
          type="submit"
          disabled={loading}
          className="w-1/3 bg-gradient-to-r from-sky-300 to-indigo-400 text-white px-6 py-2 text-sm font-medium rounded-lg hover:from-sky-600 hover:to-indigo-700 shadow-md hover:shadow-lg disabled:opacity-50 transition-all duration-200 border-2 border-sky-600"
        >
          {loading ? 'Loading...' : 'Apply Filters'}
        </button>
        <button
          type="button"
          onClick={clearFilters}
          className="w-1/3 bg-gray-100 text-gray-700 px-6 py-2 text-sm font-medium rounded-lg hover:bg-gray-200 transition-colors duration-200 border-2 border-gray-300 shadow-md hover:shadow-lg"
        >
          Clear Filters
        </button>
      </div>
    </form>
  </InfoCard>
);

const ProfileCard = ({ profile }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-200 border-2 border-dotted border-gray-300">
    <div className="bg-gradient-to-br from-sky-50 to-indigo-50 px-4 py-3 border-b border-sky-100 border-dotted">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-serif font-semibold text-gray-800 italic flex items-center">
          <UserCircle2 className="h-4 w-4 text-sky-500 mr-1" />
            User ID:
            <a 
              href={`/profile/${profile.user_id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sky-600 no-underline hover:underline inline-flex items-center ml-2"
            >
              {profile.user_id}
              <ExternalLink className="w-4 h-4 ml-1" />
            </a>
          </h3>
      </div>
       {/* Last active status */}
       <div className="flex items-center text-sm mb-3">
          <Clock className="h-4 w-4 text-sky-500 mr-2" />
         <span className="font-medium">Last active:</span>
         <span className="ml-2 text-gray-600">{profile.last_login || 'Never logged in'}</span>
      </div>
    </div>
    
    <div className="p-4 space-y-3">
      <div className="text-sm text-gray-900 mb-4 flex items-center gap-4">
          <span className="flex items-center">
            <User className="h-4 w-4 text-sky-500 mr-1" />
            {profile.gender}
          </span>
          <span className="text-gray-400">|</span>
          <span className="flex items-center">
            <Heart className="h-4 w-4 text-sky-500 mr-1" />
            {profile.marital_status}
          </span>
          <span className="text-gray-400">|</span>
          <span className="flex items-center">
            <Calendar className="h-4 w-4 text-sky-500 mr-1" />
            {profile.age} years old
          </span>
      </div>
      
      <InfoRow icon={Users} label="Community" value={profile.community} />
      <InfoRow icon={Ruler} label="Height" value={cmToFeetInches(profile.height)} />
      <InfoRow icon={MapPin} label="Location" value={ `${profile.residence_city || ''}, ${profile.residence_country || ''}` || 'Not specified'} />
      <InfoRow icon={GraduationCap} label="Education" value={profile.degree || 'Not specified'} />
      <InfoRow icon={GraduationCap} label="Job" value={profile.job_designation || 'Not specified'} />
      <InfoRow icon={Heart} label="Income" value={profile.annual_income || 'Not specified'} />
      {profile.connected && (
        <div className="mb-3">
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            <Check className="h-3 w-3 mr-1" />
            Previously Interacted
          </span>
        </div>
      )}
      {!profile.connected && (
        <div className="mb-3">
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-green-800">
            Not interacted with you
          </span>
        </div>
      )}
    </div>
  </div>
);

const InfoRow = ({ icon: Icon, label, value }) => (
  <div className="flex items-center text-sm">
    <Icon className="h-4 w-4 text-indigo-400 mr-2" />
    <span className="font-medium">{label}:</span>
    <span className="ml-2 text-gray-600">{value}</span>
  </div>
);

const Pagination = ({ currentPage, totalPages, setCurrentPage }) => (
  <div className="flex items-center justify-end px-4 py-3 bg-white sm:px-6 rounded-lg shadow-sm border border-gray-300">
    <div className="flex items-center gap-8">
      <p className="text-sm text-gray-700">
        Showing page <span className="font-medium">{currentPage}</span> of{' '}
        <span className="font-medium">{totalPages}</span>
      </p>
      <div className="flex gap-2">
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="inline-flex items-center px-3 py-2 border border-sky-300 rounded-md text-sm font-medium text-sky-700 bg-white hover:bg-sky-50 disabled:opacity-50 transition-colors"
        >
          <ArrowLeft className="h-4 w-4 mr-1" />
          Previous
        </button>
        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="inline-flex items-center px-3 py-2 border border-sky-300 rounded-md text-sm font-medium text-sky-700 bg-white hover:bg-sky-50 disabled:opacity-50 transition-colors"
        >
          Next
          <ArrowRight className="h-4 w-4 ml-1" />
        </button>
      </div>
    </div>
  </div>
);

const Search = () => {
  const [allProfiles, setAllProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [filters, setFilters] = useState({
    community: '',
    marital_status: '',
    min_height: '',
    max_height: '',
    min_age: '',
    max_age: '',
    country: '',
    highest_education: '',
  });

  const navigate = useNavigate ? useNavigate() : () => {}; // Safe navigation

  useEffect(() => {
    const fetchProfiles = async () => {
      setLoading(true);
      try {
        const response = await browseProfiles();
        
        if (response.status === 'success' && Array.isArray(response.results)) {
          setAllProfiles(response.results);
          setFilteredProfiles(response.results);
        } else {
          console.error('Invalid response format:', response);
          setError('Failed to load profiles. Please try logging in again. If the error persists, contact support.');
        }
      } catch (err) {
        setError('Session expired. Please log in again.');
        console.error('Fetch error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchProfiles();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const applyFilters = () => {
    let results = [...allProfiles];

    if (filters.community) {
      results = results.filter(profile => profile.community === filters.community);
    }
    if (filters.marital_status) {
      results = results.filter(profile => profile.marital_status === filters.marital_status);
    }
    if (filters.min_height) {
      results = results.filter(profile => profile.height >= parseInt(filters.min_height));
    }
    if (filters.max_height) {
      results = results.filter(profile => profile.height <= parseInt(filters.max_height));
    }
    if (filters.min_age) {
      results = results.filter(profile => profile.age >= parseInt(filters.min_age));
    }
    if (filters.max_age) {
      results = results.filter(profile => profile.age <= parseInt(filters.max_age));
    }
    if (filters.country) {
      results = results.filter(profile => profile.residence_country === filters.country);
    }
    if (filters.highest_education) {
      results = results.filter(profile => profile.highest_education === filters.highest_education);
    }

    setFilteredProfiles(results);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    applyFilters();
  };

  const clearFilters = () => {
    setFilters({
      community: '',
      marital_status: '',
      min_height: '',
      max_height: '',
      min_age: '',
      max_age: '',
      country: '',
      highest_education: '',
    });
    setFilteredProfiles(allProfiles);
    setCurrentPage(1);
  };

  const totalPages = filteredProfiles.length > 0 ? Math.ceil(filteredProfiles.length / ITEMS_PER_PAGE) : 0;
  const paginatedProfiles = filteredProfiles.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // Custom error component - not using imported ErrorMessage to avoid login redirect
  const CustomErrorMessage = ({ message }) => (
    <div className="flex justify-center items-center p-8">
      <div className="bg-red-50 text-red-500 p-6 rounded-lg border border-red-100 max-w-lg">
        <h3 className="text-lg font-medium mb-2">Error</h3>
        <p className="mb-4">{message}</p>
        <div className="flex justify-center">
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-sky-500 text-white rounded-md hover:bg-sky-600 transition-colors"
          >
            Refresh Page
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-4 max-w-7xl">
      <div className="space-y-4">
        {error ? (
          <CustomErrorMessage message={error} />
        ) : (
          <>
            <SearchFilters 
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleSubmit={handleSubmit}
              clearFilters={clearFilters}
              loading={loading}
            />

            {loading ? (
              <div className="text-center py-8">
                <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-sky-500 border-t-transparent"/>
              </div>
            ) : (
              <>
                {filteredProfiles.length > 0 ? (
                  <div className="space-y-4">
                    <div className="flex items-center justify-between bg-white px-4 py-3 rounded-lg shadow-sm border border-gray-300">
                      <h2 className="text-lg font-medium text-gray-900">
                        Found <span className="text-sky-600 font-semibold">{filteredProfiles.length}</span> matches
                      </h2>
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        setCurrentPage={setCurrentPage}
                      />
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                      {paginatedProfiles.map(profile => (
                        <ProfileCard 
                          key={profile.user_id} 
                          profile={profile}
                        />
                      ))}
                    </div>
                    
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                ) : (
                  <div className="text-center py-8">
                    <div className="bg-white bg-opacity-90 rounded-lg p-8 inline-block shadow-md border-2 border-dotted border-gray-300">
                      <SearchIcon className="h-12 w-12 text-sky-400 mx-auto mb-4" />
                      <h3 className="text-lg font-medium text-gray-900 mb-2">No Matches Found</h3>
                      <p className="text-gray-600 mb-4">Try adjusting your search filters to see more results</p>
                      <button
                        onClick={clearFilters}
                        className="text-sky-600 hover:text-sky-700 font-medium transition-colors border border-sky-100 px-4 py-2 rounded-lg"
                      >
                        Clear all filters
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Search;