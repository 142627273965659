import React from 'react';
import { Phone, Mail, MessageCircle, User, AlertCircle, Shield, CheckCircle2 } from 'lucide-react';
import { InfoCard, InlineInputWithValidation, Alert, EnhancedSelect } from '../../components';
import { RELATIONSHIP_CHOICES } from '../../constants';

export const ContactInfo = ({ formData, handleChange }) => {
  // Updated validation for international numbers
  const validations = {
    whatsapp_number: formData.whatsapp_number && formData.whatsapp_number.replace(/[^0-9]/g, '').length >= 8,
    phone_number: formData.phone_number && formData.phone_number.replace(/[^0-9]/g, '').length >= 8,
    whatsapp_owner: formData.whatsapp_owner && formData.whatsapp_owner !== '',
    phone_owner: formData.phone_owner && formData.phone_owner !== '',
  };

  return (
    <div className="space-y-6">
      <InfoCard title="Privacy Notice" icon={Shield} type="visibility">
        <div>
          <ul className="list-disc pl-4 space-y-1">
            <li>This information will be given to your matches so you can connect.</li>
            <li>Contact details remain private until there's a mutual match.</li>
          </ul>
        </div>
      </InfoCard>

      <InfoCard 
        title="Phone Contact" 
        icon={Phone} 
        type="personal"
      >
        <div className="grid grid-cols-1 gap-4">
          <InlineInputWithValidation
            label="Mobile Number"
            icon={Phone}
            value={formData.phone_number}
            isValid={validations.phone_number}
            helper="Include country code ( Example: +91 8123835718 )"
            required={true}
          >
            <input
              type="tel"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder="Enter phone number with country code"
              required
            />
          </InlineInputWithValidation>

          <InlineInputWithValidation
            label="Contact Owner"
            icon={User}
            value={formData.phone_owner}
            isValid={validations.phone_owner}
            required={true}
          >
            <EnhancedSelect
              name="phone_owner"
              value={formData.phone_owner}
              onChange={handleChange}
              options={RELATIONSHIP_CHOICES}
              placeholder="Select Relationship"
              icon={User}
              required
            />
          </InlineInputWithValidation>
        </div>
      </InfoCard>

      <InfoCard 
        title="WhatsApp Contact" 
        icon={MessageCircle} 
        type="profile"
      >
        <div className="grid grid-cols-1 gap-4">
          <InlineInputWithValidation
            label="WhatsApp Number"
            icon={MessageCircle}
            value={formData.whatsapp_number}
            isValid={validations.whatsapp_number}
            helper="Include country code ( Example: +91 8123835718 )"
            required={true}
          >
            <input
              type="tel"
              name="whatsapp_number"
              value={formData.whatsapp_number}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder="Enter WhatsApp number with country code"
              required
            />
          </InlineInputWithValidation>

          <InlineInputWithValidation
            label="Contact Owner"
            icon={User}
            value={formData.whatsapp_owner}
            isValid={validations.whatsapp_owner}
            required={true}
          >
            <EnhancedSelect
              name="whatsapp_owner"
              value={formData.whatsapp_owner}
              onChange={handleChange}
              options={RELATIONSHIP_CHOICES}
              placeholder="Select Relationship"
              icon={User}
              required
            />
          </InlineInputWithValidation>
        </div>
      </InfoCard>
    </div>
  );
};

export default ContactInfo;