// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, Link } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import { Home } from 'lucide-react';

// Import RelationsProvider
import { RelationsProvider } from './components/layout/RelationsContext';

// Auth components
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import DeleteAccount from './components/auth/DeleteAccount';
import ProtectedRoute from './components/auth/ProtectedRoute';

// Layout components
import Layout from './components/layout/Layout';
import AuthLayout from './components/layout/AuthLayout';

// Profile components
import ProfileView from './components/profile/Profile';
import MyProfile from './components/profile/MyProfile';

// Requests & Connections components
import ExpressInterests from './components/connections/ExpressInterests';
import ContactRequests from './components/connections/ContactRequests';
import BlockedProfiles from './components/connections/BlockedProfiles';

// Other components
import HomePage from './components/home/HomePage';
import Search from './components/search/Search'; 
import Support from './components/support/Support';
import PublicSupport from './components/support/PublicSupport';
import ResetPassword from './components/auth/ResetPassword';

// Create a NotFound component that matches the site's aesthetic
const NotFound = () => (
  <div className="flex flex-col items-center justify-center py-8">
    <div className="max-w-md w-full bg-white bg-opacity-90 backdrop-filter backdrop-blur-md shadow-xl rounded-lg p-8 border border-gray-200">
      <div className="text-center">
        <h1 className="text-3xl font-bold font-serif bg-gradient-to-r from-red-500 to-orange-500 bg-clip-text text-transparent mb-4">Page Not Found</h1>
        <div className="flex justify-center mb-6">
          <div className="h-24 w-24 bg-red-100 rounded-full flex items-center justify-center">
            <span className="text-5xl">404</span>
          </div>
        </div>
        <p className="text-gray-600 mb-8">We couldn't find the page you're looking for. The page may have been moved or doesn't exist.</p>
        <Link 
          to="/" 
          className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <Home className="h-5 w-5 mr-2" />
          Return Home
        </Link>
      </div>
    </div>
  </div>
);

// Create a wrapper component that applies RelationsProvider only to protected routes
const ProtectedLayoutWithRelations = () => (
  <RelationsProvider>
    <Layout />
  </RelationsProvider>
);

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          {/* Public routes with AuthLayout */}
          <Route element={<AuthLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/support" element={<PublicSupport />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            
            {/* 404 handling - Moved inside AuthLayout */}
            <Route path="/404" element={<NotFound />} />
          </Route>
          
          {/* Protected routes with Layout wrapped in RelationsProvider */}
          <Route element={<ProtectedRoute />}>
            <Route element={<ProtectedLayoutWithRelations />}>
              <Route path="/my_profile" element={<MyProfile />} />
              <Route path="/profile/:userId" element={<ProfileView />} />
              
              {/* New separate routes for connections functionality */}
              <Route path="/express-interests" element={<ExpressInterests />} />
              <Route path="/contact-requests" element={<ContactRequests />} />
              <Route path="/blocked-profiles" element={<BlockedProfiles />} />
              
              {/* Redirect old connections URL to express interests */}
              <Route path="/connections" element={<Navigate to="/express-interests" replace />} />
              
              <Route path="/search" element={<Search />} />
              <Route path="/delete-account" element={<DeleteAccount />} />
              <Route path="/protected-support" element={<Support />} />
            </Route>
          </Route>
          
          {/* Catch-all route - Keep outside the layout routes */}
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;