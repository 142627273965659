import React from 'react';
import { UserCog, MessageCircle } from 'lucide-react';
import { updateConnectionRequest } from '../../services/api';
import { ConnectionsTab } from './Components';
import { ErrorMessage } from '../components';
import { useRelations } from '../layout/RelationsContext';

const ExpressInterests = () => {
  // Use the relations context instead of local state
  const { relations, loading, error, fetchRelations } = useRelations();

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center gap-3 min-h-screen text-gray-600">
        <div className="animate-spin rounded-full h-8 w-8 border-4 border-sky-500 border-t-transparent" />
        Loading express interests...
      </div>
    );
  }

  if (error) {
    return <ErrorMessage />;
  }

  const getTotalCount = (relations) => {
    if (!relations?.connections) return 0;
    return relations.connections.pending_received_requests.length +
      relations.connections.pending_sent_requests.length +
      relations.connections.accepted_connections.length +
      relations.connections.declined_received_requests.length +
      relations.connections.declined_sent_requests.length;
  };

  return (
    <div className="container mx-auto px-4 py-4 max-w-7xl">
      <h1 className="text-2xl font-bold bg-gradient-to-r from-sky-600 to-indigo-600 bg-clip-text text-transparent mb-6 flex items-center gap-3">
        <UserCog className="w-7 h-7 text-sky-500" />
        Express Interests ({getTotalCount(relations)})
      </h1>
      
      <div className="border border-gray-300 bg-sky-50/50 p-4 mb-4 text-gray-600 rounded-lg">
        <p className="text-sm flex items-center gap-2">
          <span className="italic">This section displays all your express interest requests. Express interest requests may be sent from member profiles. </span>
        </p>
      </div>

      {relations && (
        <ConnectionsTab 
          data={{
            ...relations.connections,
            contact_requests: relations.contact_requests
          }}
          onUpdateConnection={(userId, action) => {
            updateConnectionRequest(userId, action)
              .then(() => {
                // Refresh the data with force=true to bypass caching
                fetchRelations(true);
              })
              .catch(err => {
                console.error('Error updating connection:', err);
              });
          }}
          onRefresh={() => {
              // Refresh the data with force=true to bypass caching
              fetchRelations(true);
          }}
        />
      )}
    </div>
  );
};

export default ExpressInterests;