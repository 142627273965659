import React from 'react';
import { Shield, MessageCircle } from 'lucide-react';
import { unblockUser } from '../../services/api';
import { BlockedUsersTab } from './Components';
import { ErrorMessage } from '../components';
import { useRelations } from '../layout/RelationsContext';

const BlockedProfiles = () => {
  // Use the relations context instead of local state
  const { relations, loading, error, fetchRelations } = useRelations();

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center gap-3 min-h-screen text-gray-600">
        <div className="animate-spin rounded-full h-8 w-8 border-4 border-sky-500 border-t-transparent" />
        Loading blocked profiles...
      </div>
    );
  }

  if (error) {
    return <ErrorMessage />;
  }

  return (
    <div className="container mx-auto px-4 py-4 max-w-7xl">
      <h1 className="text-2xl font-bold bg-gradient-to-r from-sky-600 to-indigo-600 bg-clip-text text-transparent mb-6 flex items-center gap-3">
        <Shield className="w-7 h-7 text-red-500" />
        Blocked Profiles ({relations?.blocks?.length || 0})
      </h1>
      
      <div className="border border-gray-300 bg-sky-50/50 p-4 mb-4 text-gray-600 rounded-lg">
        <p className="text-sm flex items-center gap-2">
          <span className="italic">To protect your privacy, block any users you don't want to see your profile. Blocked users cannot find you, nor can you find them in search. They are not notified.</span>
        </p>
      </div>

      {relations && (
        <BlockedUsersTab 
          data={relations.blocks} 
          onUnblock={(userId) => {
            unblockUser(userId)
              .then(() => {
                // Refresh the data with force=true to bypass caching
                fetchRelations(true);
              })
              .catch(err => {
                console.error('Error unblocking user:', err);
              });
          }}
        />
      )}
    </div>
  );
};

export default BlockedProfiles;