import React from 'react';
import { Briefcase, Building, MapPin, DollarSign, FileText, Shield } from 'lucide-react';
import { InfoCard, InlineInputWithValidation, InputWithValidation } from '../../components';

export const Professional = ({ formData, handleChange }) => {
  const validations = {
    designation: !formData.currently_unemployed ? (formData.designation && formData.designation !== '') : true,
    company: !formData.currently_unemployed ? (formData.company && formData.company !== '') : true,
    work_city: !formData.currently_unemployed ? (formData.work_city && formData.work_city !== '') : true,
    annual_income: !formData.currently_unemployed ? (formData.annual_income && formData.annual_income !== '') : true,
    employment_explanation: formData.currently_unemployed ? (formData.employment_explanation && formData.employment_explanation !== '') : true,
  };

  return (
    <div className="space-y-6">
      {/* Employment Status */}
      <InfoCard 
        title="Employment Status" 
        icon={Briefcase} 
        type="visibility"
      >
        <div className="grid grid-cols-1 gap-4">
          <div className="flex items-center space-x-3 bg-sky-50 p-3 rounded-lg">
            <input
              type="checkbox"
              id="currently_unemployed"
              name="currently_unemployed"
              checked={formData.currently_unemployed}
              onChange={handleChange}
              className="h-5 w-5 text-sky-600 border-gray-300 rounded focus:ring-sky-500 appearance-auto"
              style={{ appearance: 'auto' }}
            />
            <label className="text-sm text-gray-700" htmlFor="currently_unemployed">
              Select this if you are presently unemployed
            </label>
          </div>
        </div>

        <hr className="my-4 border-gray-200" />
  
        {formData.currently_unemployed ? (
        <div className="grid grid-cols-1 gap-6">
          <InputWithValidation
            label="Please explain your current employment situation"
            value={formData.employment_explanation}
            isValid={validations.employment_explanation}
            helper={
              <div className="space-y-1">
                <p>Examples:</p>
                <ul className="list-disc pl-4 space-y-1">
                  <li>Taking care of family coffee estate and homestay business in Coorg</li>
                  <li>Recently completed MBA, currently interviewing with top firms</li>
                  <li>Preparing for civil services examination</li>
                  <li>Came back from Dubai last year. Curently managing family properties and investments in Coorg</li>
                  <li>I'm a Data Science PhD student at Stanford, and I want to become a professor.</li>
                </ul>
              </div>
            }
          >
            <textarea
              name="employment_explanation"
              value={formData.employment_explanation}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white min-h-[200px]"
              placeholder="Enter your explanation here..."
              required
            />
          </InputWithValidation>
          </div>
        ) : null}
      </InfoCard>

      {!formData.currently_unemployed ? (
        <>
          <InfoCard 
            title="Employment Details" 
            icon={Building} 
            type="profile"
          >
            <div className="grid grid-cols-1 gap-4">
              <InlineInputWithValidation
                label="Designation"
                icon={Briefcase}
                value={formData.designation}
                isValid={validations.designation}
                required={true}
                helper="Examples: Software Engineer, Senior Manager, Doctor, Business Owner"
              >
                <input
                  type="text"
                  name="designation"
                  value={formData.designation}
                  onChange={handleChange}
                  className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
                  placeholder="Enter your designation"
                  required
                />
              </InlineInputWithValidation>

              <InlineInputWithValidation
                label="Company"
                icon={Building}
                value={formData.company}
                isValid={validations.company}
                required={true}
                helper="Examples: State Bank of India, Apollo Hospitals, Infosys, Own Business"
              >
                <input
                  type="text"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
                  placeholder="Enter company name"
                  required
                />
              </InlineInputWithValidation>

              <InlineInputWithValidation
                label="Work Location"
                icon={MapPin}
                value={formData.work_city}
                isValid={validations.work_city}
                required={true}
                helper="Examples: Bangalore, Mumbai, Singapore, San Francisco Bay Area, London UK"
              >
                <input
                  type="text"
                  name="work_city"
                  value={formData.work_city}
                  onChange={handleChange}
                  className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
                  placeholder="Enter work location"
                  required
                />
              </InlineInputWithValidation>

              <InlineInputWithValidation
                label="Annual Income"
                icon={DollarSign}
                value={formData.annual_income}
                isValid={validations.annual_income}
                required={true}
                helper="Examples: 8 lakhs per annum, USD 160K per year"
              >
                <input
                  type="text"
                  name="annual_income"
                  value={formData.annual_income}
                  onChange={handleChange}
                  className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
                  placeholder="Enter annual income"
                  required
                />
              </InlineInputWithValidation>
            </div>
          </InfoCard>
        </>
      ) : (
        null
      )}
    </div>
  );
};

export default Professional;