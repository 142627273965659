import React from 'react';
import { GraduationCap, Building, MapPin, Calendar } from 'lucide-react';
import { InfoCard, InlineInputWithValidation, EnhancedSelect } from '../../components';
import { EDUCATION_CHOICES } from '../../constants';

const degreeExamples = {
  bachelors: {
    degree: "Examples: B.E in Computer Science, B.Tech in Electronics, B.Com, BA in Economics, BBA",
    university: "Examples: Manipal Institute of Technology, St. Joseph's College Bangalore, Christ University",
    location: "Examples: Manipal, Bangalore, Mumbai"
  },
  masters: {
    degree: "Examples: MS in Computer Science, MBA in Finance, M.Tech in AI, MA in Economics",
    university: "Examples: University of California Berkeley, IIM Bangalore, Georgia Tech, NUS Singapore",
    location: "Examples: Berkeley CA, Bangalore, Atlanta GA, Singapore"
  },
  phd: {
    degree: "Examples: PhD in Machine Learning, PhD in Financial Economics, PhD in Data Science",
    university: "Examples: Stanford University, ETH Zurich, University of Toronto, IISc Bangalore",
    location: "Examples: Stanford CA, Zurich, Toronto, Bangalore"
  }
};

export const Education = ({ formData, handleChange }) => {
  const validations = {
    highest_education: formData.highest_education && formData.highest_education !== '',
    bachelors_degree: (formData.highest_education === 'Bachelors / Diploma' ||
                      formData.highest_education === 'Masters' ||
                      formData.highest_education === 'Phd') 
                      ? (formData.bachelors_degree && formData.bachelors_degree !== '') : true,
    bachelors_university: (formData.highest_education === 'Bachelors / Diploma' ||
                          formData.highest_education === 'Masters' ||
                          formData.highest_education === 'Phd')
                          ? (formData.bachelors_university && formData.bachelors_university !== '') : true,
    bachelors_location: (formData.highest_education === 'Bachelors / Diploma' ||
                    formData.highest_education === 'Masters' ||
                    formData.highest_education === 'Phd')
                    ? (formData.bachelors_location && formData.bachelors_location !== '') : true,
    bachelors_year: (formData.highest_education === 'Bachelors / Diploma' ||
                      formData.highest_education === 'Masters' ||
                      formData.highest_education === 'Phd')
                      ? (formData.bachelors_year && formData.bachelors_year !== '') : true,

    masters_degree: (formData.highest_education === 'Masters' ||
                    formData.highest_education === 'Phd')
                    ? (formData.masters_degree && formData.masters_degree !== '') : true,
    masters_university: (formData.highest_education === 'Masters' ||
                        formData.highest_education === 'Phd')
                        ? (formData.masters_university && formData.masters_university !== '') : true,
    masters_location: (formData.highest_education === 'Masters' ||
                            formData.highest_education === 'Phd')
                            ? (formData.masters_location && formData.masters_location !== '') : true,
    masters_year: (formData.highest_education === 'Masters' ||
                            formData.highest_education === 'Phd')
                            ? (formData.masters_year && formData.masters_year !== '') : true,
    
    phd_degree: formData.highest_education === 'Phd' 
                ? (formData.phd_degree && formData.phd_degree !== '') : true,
    phd_university: formData.highest_education === 'Phd' 
                   ? (formData.phd_university && formData.phd_university !== '') : true,
    phd_location: formData.highest_education === 'Phd' 
                   ? (formData.phd_location && formData.phd_location !== '') : true,
    phd_year: formData.highest_education === 'Phd' 
                      ? (formData.phd_year && formData.phd_year !== '') : true,
  };

  const renderEducationBox = (level, required = true) => {
    const levelData = {
      bachelors: {
        title: "Bachelor's Degree / Diploma",
        type: 'bachelors',
        degree: 'bachelors_degree',
        university: 'bachelors_university',
        location: 'bachelors_location',
        year: 'bachelors_year'
      },
      masters: {
        title: "Master's Degree",
        type: 'masters',
        degree: 'masters_degree',
        university: 'masters_university',
        location: 'masters_location',
        year: 'masters_year'
      },
      phd: {
        title: 'PhD',
        type: 'phd',
        degree: 'phd_degree',
        university: 'phd_university',
        location: 'phd_location',
        year: 'phd_year'
      }
    };

    const data = levelData[level];
    const examples = degreeExamples[level];
    
    // Define color schemes based on level
    const colorSchemes = {
      bachelors: 'personal',
      masters: 'profile',
      phd: 'verification'
    };

    return (
      <InfoCard 
        title={data.title} 
        icon={GraduationCap} 
        type={colorSchemes[level]}
      >
        <div className="grid grid-cols-1 gap-4">
          <InlineInputWithValidation
            label="Degree / Specialization"
            icon={GraduationCap}
            value={formData[data.degree]}
            isValid={validations[data.degree]}
            helper={examples.degree}
            required={true}
          >
            <input
              type="text"
              name={data.degree}
              value={formData[data.degree]}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder={`Enter your ${level} degree`}
              required={required}
            />
          </InlineInputWithValidation>

          <InlineInputWithValidation
            label="University / Institution"
            icon={Building}
            value={formData[data.university]}
            isValid={validations[data.university]}
            helper={examples.university}
            required={true}
          >
            <input
              type="text"
              name={data.university}
              value={formData[data.university]}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder="Enter university name"
              required={required}
            />
          </InlineInputWithValidation>

          <InlineInputWithValidation
            label="Location"
            icon={MapPin}
            value={formData[data.location]}
            isValid={validations[data.location]}
            helper={examples.location}
            required={true}
          >
            <input
              type="text"
              name={data.location}
              value={formData[data.location]}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder="Enter location"
            />
          </InlineInputWithValidation>

          <InlineInputWithValidation
            label="Year of Completion"
            icon={Calendar}
            value={formData[data.year]}
            isValid={validations[data.year]}
            required={true}
          >
            <input
              type="number"
              name={data.year}
              value={formData[data.year]}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder="YYYY"
              min="1950"
              max={new Date().getFullYear()}
            />
          </InlineInputWithValidation>
        </div>
      </InfoCard>
    );
  };

  return (
    <div className="space-y-6">
      <InfoCard 
        title="Education Level" 
        icon={GraduationCap} 
        type="visibility"
      >
        <div className="grid grid-cols-1 gap-4">
          
          <InlineInputWithValidation
            label="Highest Education"
            icon={GraduationCap}
            value={formData.highest_education}
            isValid={validations.highest_education}
            required={true}
          >
          <EnhancedSelect
            name="highest_education"
            value={formData.highest_education}
            onChange={handleChange}
            options={EDUCATION_CHOICES}
            placeholder="Select Education Level"
            icon={GraduationCap}
            required
          />
          </InlineInputWithValidation>
        </div>
      </InfoCard>
      {formData.highest_education === 'Phd' && renderEducationBox('phd')}

      {(formData.highest_education === 'Masters' ||
        formData.highest_education === 'Phd') && renderEducationBox('masters')}
  
      {(formData.highest_education === 'Bachelors / Diploma' ||
        formData.highest_education === 'Masters' ||
        formData.highest_education === 'Phd') && renderEducationBox('bachelors')}
    </div>
  );
};

export default Education;