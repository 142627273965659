import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserCircle2, GraduationCap, Briefcase, Home, Phone, Calendar, Users, Images, MessageCircle } from 'lucide-react';
import { getProfile, sendConnectionRequest, sendContactRequest, blockUser } from '../../services/api';
import {
  ProfileSection,
  EducationSection,
  ProfessionalSection,
  ContactSection,
  HoroscopeSection,
  PersonalDetailsSection,
  ResidenceSection,
  FamilySection,
  PhotosSection
} from './Components';
import { ErrorMessage } from '../components';
import ProfileControls from './ProfileControls';

const ProfileView = () => {
  const { userId } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleSendConnectionRequest = async () => {
    try {
      await sendConnectionRequest(userId);
      return true;
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to send connection request');
      return false;
    }
  };

  const handleSendContactRequest = async () => {
    try {
      await sendContactRequest(userId);
      return true;
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to send contact request');
      return false;
    }
  };

  const handleBlockUser = async () => {
    try {
      await blockUser(userId);
      return true;
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to block user');
      return false;
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile(userId);
        setProfileData(response);
        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to fetch profile');
        setLoading(false);
      }
    };

    fetchProfile();
  }, [userId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-sky-500 border-t-transparent"/>
      </div>
    );
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  if (!profileData) return null;

  const { profile, profile_type, connection, contact_request } = profileData;
  const isFull = (profile_type === 'full' || profile_type === 'connected');

  return (
    <div className="container mx-auto px-4 py-4 min-h-screen">
      <div className="mb-4">
        <h1 className="text-2xl font-medium bg-gradient-to-r from-sky-600 to-indigo-600 bg-clip-text text-transparent">User Profile</h1>
      </div>

      {/* First row: Basic Information and a split section for control buttons and contact */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div>
          <ProfileSection title="Basic Information" icon={UserCircle2} type="education">
            <PersonalDetailsSection profile={profile} isFull={isFull} />
          </ProfileSection>
        </div>
        
        <div className="grid grid-cols-1 gap-4">
          {/* Connection Request Section */}
          <ProfileControls
            profile={profile}
            profileType={profile_type}
            connection={connection}
            contactRequest={contact_request}
            onSendConnectionRequest={handleSendConnectionRequest}
            onSendContactRequest={handleSendContactRequest}
            onBlockUser={handleBlockUser}
          />
          
          {/* Contact information section */}
          <div>
            <ProfileSection title="Contact Information" icon={Phone} type="personal">
              <ContactSection contact={profile?.contact} isFull={profile_type === 'full'} />
            </ProfileSection>
          </div>
        </div>
      </div>
      
      {/* Second row: Photos section spanning the full width */}
      <div className="mb-4">
        <ProfileSection title="Photos" icon={Images} type="professional">
          <PhotosSection photos={profile.photos} shouldDisplay={profile?.photos?.public_visibility || profile_type !== 'basic'}/>
        </ProfileSection>
      </div>

      {/* Third row: About Me section spanning the full width */}
      <div className="mb-4">
        <ProfileSection title="About Me" icon={UserCircle2} type="horoscope">
          {profile.about_me || 'No information available'}
        </ProfileSection>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Left Column */}
        <div className="flex flex-col gap-4">
          <ProfileSection title="Family" icon={Users} type="education">
              <FamilySection family={profile?.family} isFull={isFull} />
          </ProfileSection>

          <ProfileSection title="Education" icon={GraduationCap} type="education">
            <EducationSection education={profile?.education} isFull={isFull} />
          </ProfileSection> 
        </div>
        
        {/* Right Column */}
        <div className="flex flex-col gap-4">
          <ProfileSection title="Horoscope" icon={Calendar} type="education">
            <HoroscopeSection profile={profile} />
          </ProfileSection>

          <ProfileSection title="Residence" icon={Home} type="education">
            <ResidenceSection residence={profile?.residence} isFull={isFull} />
          </ProfileSection>
          
          <ProfileSection title="Professional" icon={Briefcase} type="education">
            <ProfessionalSection professional={profile?.professional} isFull={isFull} />
          </ProfileSection>
        </div>
      </div>
    </div>
  );
};

export default ProfileView;