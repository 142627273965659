import React from 'react';
import { UserCog, MessageCircle, Phone } from 'lucide-react';
import { updateContactRequest } from '../../services/api';
import { ContactRequestsTab } from './Components';
import { ErrorMessage } from '../components';
import { useRelations } from '../layout/RelationsContext';

const ContactRequests = () => {
  // Use the relations context instead of local state
  const { relations, loading, error, fetchRelations } = useRelations();

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center gap-3 min-h-screen text-gray-600">
        <div className="animate-spin rounded-full h-8 w-8 border-4 border-sky-500 border-t-transparent" />
        Loading contact requests...
      </div>
    );
  }

  if (error) {
    return <ErrorMessage />;
  }

  const getContactsCount = (relations) => {
    if (!relations?.contact_requests) return 0;
    return relations.contact_requests.pending_received_requests.length +
      relations.contact_requests.pending_sent_requests.length +
      relations.contact_requests.accepted_requests.length +
      relations.contact_requests.declined_received_requests.length +
      relations.contact_requests.declined_sent_requests.length;
  };

  return (
    <div className="container mx-auto px-4 py-4 max-w-7xl">
      <h1 className="text-2xl font-bold bg-gradient-to-r from-sky-600 to-indigo-600 bg-clip-text text-transparent mb-6 flex items-center gap-3">
        <Phone className="w-7 h-7 text-amber-500" />
        Contact Requests ({getContactsCount(relations)})
      </h1>
      
      <div className="border border-gray-300 bg-sky-50/50 p-4 mb-4 text-gray-600 rounded-lg">
        <p className="text-sm flex items-center gap-2">
          <span className="italic">Contact requests are available after a mutual express interest. Once a contact request is accepted, contact information is shared. </span>
        </p>
      </div>

      {relations && (
        <ContactRequestsTab 
          data={relations.contact_requests}
          onUpdateContact={(requestId, action) => {
            updateContactRequest(requestId, action)
              .then(() => {
                // Refresh the data with force=true to bypass caching
                fetchRelations(true);
              })
              .catch(err => {
                console.error('Error updating contact request:', err);
              });
          }}
        />
      )}
    </div>
  );
};

export default ContactRequests;