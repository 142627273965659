// src/components/support/PublicSupport.js
import React, { useState } from 'react';
import { MessageCircle, Send, Mail, User, Heart } from 'lucide-react';
import { SUPPORT_EMAIL } from '../constants';
import { sendSupportMessage } from '../../services/api';

const PublicSupport = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [status, setStatus] = useState({ type: null, message: null });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus({ type: null, message: null });

    try {
      // Call the API with the form data
      await sendSupportMessage(formData);
      
      setStatus({
        type: 'success',
        message: 'Your message has been sent successfully. We will contact you via email soon.'
      });
      
      // Reset form
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      setStatus({
        type: 'error',
        message: error.response?.data?.message || 
          `Failed to send your message. Please try again later. If issues persist, contact us at ${SUPPORT_EMAIL}`
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto relative">
      {/* Decorative elements */}
      <div className="absolute -top-16 right-4 w-48 h-48 bg-blue-100 rounded-full opacity-20" style={{ zIndex: -1 }}></div>
      <div className="absolute -bottom-10 left-4 w-48 h-48 bg-indigo-100 rounded-full opacity-20" style={{ zIndex: -1 }}></div>
      
      <div className="bg-white rounded-lg shadow-sm overflow-hidden border border-blue-100 relative">
        {/* Decorative pattern - top */}
        <div className="absolute top-0 left-0 right-0 h-6 overflow-hidden">
          <svg className="absolute h-12 w-full" preserveAspectRatio="none" viewBox="0 0 800 100" xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.05 }}>
            <path d="M0,0 C100,30 200,70 300,20 C400,-20 500,30 600,50 C700,70 800,30 900,40 L900,0 L0,0 Z" fill="#4F46E5"></path>
          </svg>
        </div>
        
        <div className="md:flex">
          {/* Left Column - Info */}
          <div className="md:w-1/3 bg-gradient-to-br from-blue-400 to-indigo-400 p-8 text-white relative overflow-hidden">
            {/* Decorative elements */}
            <div className="absolute top-0 left-0 w-full h-full">
              <div className="absolute -top-10 -right-10 w-40 h-40 bg-white rounded-full opacity-5"></div>
              <div className="absolute top-40 -left-10 w-32 h-32 bg-white rounded-full opacity-5"></div>
              <div className="absolute bottom-10 right-10 w-24 h-24 bg-white rounded-full opacity-5"></div>
            </div>
            
            <div className="relative">
              <h2 className="text-2xl font-bold mb-6 flex items-center font-serif">
                <MessageCircle className="h-6 w-6 mr-2" />
                Contact Us
              </h2>
              
              <div className="space-y-6">
                <div>
                  <h3 className="font-medium text-lg mb-2 font-serif">Need Assistance?</h3>
                  <p className="text-blue-50">
                    Our team is here to help you with any questions about our matrimony service.
                  </p>
                </div>
                
                <div>
                  <h3 className="font-medium text-lg mb-2 font-serif">Response Time</h3>
                  <p className="text-blue-50">
                    We typically respond to all inquiries within 24 hours during business days.
                  </p>
                </div>
                
                <div>
                  <h3 className="font-medium text-lg mb-2 font-serif">Contact Information</h3>
                  <div className="space-y-2 text-blue-50">
                    <p className="flex items-center">
                      <Mail className="h-4 w-4 mr-2" />
                      {SUPPORT_EMAIL}
                    </p>
                  </div>
                </div>
              </div>
              
              {/* Celebrative element */}
              <div className="mt-8 pt-8 border-t border-blue-300 border-opacity-30">
                <div className="flex items-center">
                  <Heart className="h-5 w-5 text-pink-200 mr-2" />
                  <p className="text-sm text-blue-50 italic">
                    Helping our community build beautiful connections since 2025
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          {/* Right Column - Form */}
          <div className="md:w-2/3 p-8 relative">
            {/* Decorative elements */}
            <div className="absolute top-4 right-4">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.07 }}>
                <path d="M30 0L36.5 23.5L60 30L36.5 36.5L30 60L23.5 36.5L0 30L23.5 23.5L30 0Z" fill="#4F46E5"/>
              </svg>
            </div>
            <div className="absolute bottom-4 left-4">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.07 }}>
                <circle cx="20" cy="20" r="20" fill="#4F46E5"/>
              </svg>
            </div>
            
            <h2 className="text-2xl font-bold mb-6 text-blue-500 font-serif relative">
              Send Us a Message
            </h2>
            
            <form onSubmit={handleSubmit} className="space-y-4 relative">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700 font-serif">Name</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <User className="h-5 w-5 text-blue-400" />
                    </div>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full pl-10 pr-3 py-2 border-2 border-blue-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 bg-white"
                      placeholder="Your name"
                      required
                    />
                  </div>
                </div>
                
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700 font-serif">Email</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Mail className="h-5 w-5 text-blue-400" />
                    </div>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full pl-10 pr-3 py-2 border-2 border-blue-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 bg-white"
                      placeholder="Your email"
                      required
                    />
                  </div>
                </div>
              </div>
              
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700 font-serif">Subject</label>
                <input
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border-2 border-blue-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 bg-white"
                  placeholder="How can we help you?"
                  required
                />
              </div>
              
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700 font-serif">Message</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="5"
                  className="w-full px-3 py-3 border-2 border-blue-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 bg-white"
                  placeholder="Please describe your issue or question in detail..."
                  required
                ></textarea>
              </div>
              
              {status.message && (
                <div className={`p-3 rounded-lg ${
                  status.type === 'success' 
                    ? 'bg-green-50 text-green-700 border border-green-200'
                    : 'bg-red-50 text-red-700 border border-red-200'
                }`}>
                  {status.message}
                </div>
              )}
              
              <button
                type="submit"
                disabled={isSubmitting}
                className="inline-flex items-center justify-center w-full md:w-auto px-6 py-3 border-2 border-blue-300 text-base font-medium rounded-lg shadow-sm text-white bg-gradient-to-r from-blue-400 to-indigo-400 hover:from-blue-500 hover:to-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 font-serif"
              >
                {isSubmitting ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Sending...
                  </>
                ) : (
                  <>
                    <Send className="h-5 w-5 mr-2" />
                    Send Message
                  </>
                )}
              </button>
            </form>
          </div>
        </div>
        
        {/* Decorative pattern - bottom */}
        <div className="absolute bottom-0 left-0 right-0 h-6 overflow-hidden">
          <svg className="absolute bottom-0 h-12 w-full" preserveAspectRatio="none" viewBox="0 0 800 100" xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.05 }}>
            <path d="M0,100 C100,70 200,30 300,80 C400,120 500,70 600,50 C700,30 800,70 900,60 L900,100 L0,100 Z" fill="#4F46E5"></path>
          </svg>
        </div>
      </div>
      
    </div>
  );
};

export default PublicSupport;