import React from 'react';
import { User, Users, Heart, Ruler, Upload, Shield, CheckCircle2, Calendar } from 'lucide-react';
import { COMMUNITY_CHOICES, GENDER_CHOICES, MARITAL_STATUS_CHOICES } from '../../constants';
import { generateHeightOptions, formatDate } from '../../utils';
import { EnhancedSelect, FileInputWithValidation, InlineInputWithValidation, InfoCard } from '../../components';

const ProfileVisibilitySection = ({ formData, handleChange }) => (
  <div className="space-y-4">

    {/* Fixed height message box with consistent styling */}
    <div className="bg-sky-50 border border-sky-100 rounded-lg p-4 flex items-center min-h-[6rem] shadow-sm">
      <p className="text-gray-700">
        By default, your profile is private and doesn't reveal personal details like your name, family information, or date of birth. Once you match with someone, you can choose to send a contact request. If they accept, you'll both be able to see each other's contact details.
          
      </p>
    </div>
  </div>
);

const DateInputWithValidation = ({ label, value, onChange, isValid, required = false }) => {
  const defaultDate = '1995-01-01';

  return (
    <InlineInputWithValidation
      label="Date of Birth"
      icon={Calendar}
      value={value}
      isValid={isValid} // Changed from validations.date_of_birth to isValid
      required={true}
    >
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Calendar className="h-5 w-5 text-sky-400" />
        </div>
        <div
          className="w-full cursor-pointer"
          onClick={() => document.getElementById('date_of_birth').showPicker()}
        >
          <input
            type="date"
            id="date_of_birth"
            name="date_of_birth"
            defaultValue={defaultDate}
            value={value || defaultDate}
            onChange={onChange}
            className="sr-only"
            required={required}
          />
          <div className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white">
            <span className="text-gray-700">
              {value ? formatDate(value) : formatDate(defaultDate)}
            </span>
          </div>
        </div>
      </div>
    </InlineInputWithValidation>
  );
};

export const BasicInfo = ({ formData, handleChange, heightSelection, handleHeightChange, identityFile, handleFileChange, selectedFileName, handleRemoveFile }) => {
  const heightOptions = generateHeightOptions();

  // Validation states
  const validations = {
    first_name: formData.first_name.length >= 2,
    family_name: formData.family_name.length >= 2,
    community: formData.community !== '',
    gender: formData.gender !== '',
    height: formData.height >= 100 && formData.height <= 250,
    marital_status: formData.marital_status !== '',
    date_of_birth: !!formData.date_of_birth,

    identity_proof: identityFile !== null
  };

  return (
    <div className="space-y-6 m-0 p-0">
      <InfoCard
        title="Profile Visibility"
        icon={Shield}
        type="visibility"
      >
        <ProfileVisibilitySection
          formData={formData}
          handleChange={handleChange}
        />
      </InfoCard>

      <InfoCard
        title="Personal Information"
        icon={User}
        type="personal"
      >
        <div className="space-y-4">
          <InlineInputWithValidation
            label="First Name"
            icon={User}
            value={formData.first_name}
            isValid={validations.first_name}
            required={true}
          >
            <input
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder="Enter your first name"
              required
            />
          </InlineInputWithValidation>

          <InlineInputWithValidation
            label="Community"
            icon={Users}
            value={formData.community}
            isValid={validations.community}
            required={true}
          >
            <EnhancedSelect
              name="community"
              value={formData.community}
              onChange={handleChange}
              options={COMMUNITY_CHOICES}
              placeholder="Select Community"
              icon={Users}
              required
            />
          </InlineInputWithValidation>

          <InlineInputWithValidation
            label="Family Name (Manepedha)"
            icon={Users}
            value={formData.family_name}
            isValid={validations.family_name}
            helper="Please provide your father's caste or religion if you do not have a family name."
            required={true}
          >
            <input
              type="text"
              name="family_name"
              value={formData.family_name}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder="Enter your family name"
              required
            />
          </InlineInputWithValidation>
        </div>
      </InfoCard>

      <InfoCard
        title="Profile Details"
        icon={Heart}
        type="profile"
      >
        <div className="space-y-4">
          <InlineInputWithValidation
            label="Gender"
            icon={User}
            value={formData.gender}
            isValid={validations.gender}
            required={true}
          >
            <EnhancedSelect
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              options={GENDER_CHOICES}
              placeholder="Select Gender"
              icon={User}
              required
            />
          </InlineInputWithValidation>

          <InlineInputWithValidation
            label="Height"
            icon={Ruler}
            value={heightSelection.feet && heightSelection.inches}
            isValid={validations.height}
            required={true}
          >
            <div className="flex items-center gap-4">
              <div className="relative flex-grow">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Ruler className="h-5 w-5 text-sky-400" />
                </div>
                <select
                  value={heightSelection.feet ? `${heightSelection.feet}-${heightSelection.inches}` : ''}
                  onChange={handleHeightChange}
                  className="w-full pl-10 pr-12 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 appearance-none bg-white"
                  required
                >
                  <option value="" className="text-gray-400 font-light italic">Select Height</option>
                  {heightOptions.map(({ feet, inches, cm }) => (
                    <option
                      key={`${feet}-${inches}`}
                      value={`${feet}-${inches}`}
                      className="text-gray-900 italic font-medium tracking-wide"
                    >
                      {feet}' {inches}" ({cm} cm)
                    </option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <svg className="h-5 w-5 text-sky-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>
          </InlineInputWithValidation>

          <InlineInputWithValidation
            label="Marital Status"
            icon={Heart}
            value={formData.marital_status}
            isValid={validations.marital_status}
            required={true}
          >
            <EnhancedSelect
              name="marital_status"
              value={formData.marital_status}
              onChange={handleChange}
              options={MARITAL_STATUS_CHOICES}
              placeholder="Select Status"
              icon={Heart}
              required
            />
          </InlineInputWithValidation>

          <DateInputWithValidation
            label="Date of Birth"
            icon={Calendar}
            value={formData.date_of_birth}
            onChange={handleChange}
            isValid={validations.date_of_birth}
            required={true}
          />
        </div>
      </InfoCard>

      <InfoCard
        title="Verification"
        icon={Upload}
        type="verification"
      >
        <div className="p-2">
          <FileInputWithValidation
            label="Identity Verification"
            icon={Upload}
            value={selectedFileName}
            isValid={validations.identity_proof}
            helper="Please upload a government-issued ID (Aadhar, PAN, Passport, Driver's License)"
            required={true}
            onChange={handleFileChange}
            onRemove={handleRemoveFile}
          />
        </div>
      </InfoCard>
    </div>
  );
};