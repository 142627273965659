import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, forgotPassword } from '../../services/api';
import { loginStart, loginSuccess, loginFailure } from './authSlice';
import { useNavigate, Link } from 'react-router-dom';
import { Mail, Lock, Eye, EyeOff, AlertCircle, Heart, Sparkles, Check } from 'lucide-react';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error } = useSelector(state => state.auth);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);

  // Ensure modal doesn't create scrollbar issues
  useEffect(() => {
    if (showForgotPassword) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    
    return () => {
      document.body.style.overflow = '';
    };
  }, [showForgotPassword]);

  const handleRememberMeChange = () => {
    console.log("Remember me clicked, previous state:", rememberMe);
    setRememberMe(!rememberMe);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loginStart());
  
    try {
      const response = await login(email, password);
      if (response.status === 'success') {
        dispatch(loginSuccess({
          access: response.data.access,
          refresh: response.data.refresh,
          user_id: response.data.user_id,
          email: email,
          first_name: response.data.first_name,
        }));
  
        if (rememberMe) {
          // Set a longer expiration for tokens - 30 days
          localStorage.setItem('tokenExpiry', new Date(new Date().getTime() + 30*24*60*60*1000).toString());
          // Remove sessionOnly flags
          sessionStorage.removeItem('sessionOnly');
          localStorage.removeItem('wasSessionOnly');
        } else {
          // Set session-only flags
          sessionStorage.setItem('sessionOnly', 'true');
          localStorage.setItem('wasSessionOnly', 'true');
          // Remove token expiry
          localStorage.removeItem('tokenExpiry');
        }
  
        navigate('/my_profile');
      } else {
        dispatch(loginFailure(response.message || 'Login failed'));
      }
    } catch (err) {
      // Extract error message from the response data if available
      let errorMessage = 'Unable to connect to the server. Please try again later.';
      
      if (err.response) {
        // Handle 401 Unauthorized specifically
        if (err.response.status === 401) {
          // Try to get the message from the response
          if (err.response.data && err.response.data.message) {
            errorMessage = err.response.data.message;
          } else {
            errorMessage = 'Incorrect email or password. Please try again.';
          }
        } 
        // For other status codes, try to extract the error message
        else if (err.response.data) {
          if (typeof err.response.data === 'string') {
            errorMessage = err.response.data;
          } else if (err.response.data.message) {
            errorMessage = err.response.data.message;
          }
        }
      }
      
      console.log("Login error handled:", errorMessage);
      dispatch(loginFailure(errorMessage));
    }
  };

  const handleForgotPassword = async () => {
    try {
      const response = await forgotPassword(forgotPasswordEmail);
      
      setForgotPasswordStatus({
        type: 'success',
        message: 'Reset instructions sent to your email'
      });
      setForgotPasswordEmail('');
      
    } catch (error) {
      setForgotPasswordStatus({
        type: 'error',
        message: error.response?.data?.message || 'Failed to send reset instructions'
      });
    }
  };

  return (
    <div className="max-w-md mx-auto relative min-h-[60vh] flex items-center justify-center">
      {/* Decorative elements */}
      <div className="absolute top-20 right-10 w-64 h-64 bg-blue-100 rounded-full opacity-20 animate-pulse" style={{ animationDuration: '8s', zIndex: -1 }}></div>
      <div className="absolute bottom-20 left-10 w-64 h-64 bg-indigo-100 rounded-full opacity-20 animate-pulse" style={{ animationDuration: '10s', zIndex: -1 }}></div>
      
      {/* Decorative pattern - top */}
      <div className="absolute -top-10 left-0 right-0 h-16 overflow-hidden">
        <svg className="absolute h-32 w-full" preserveAspectRatio="none" viewBox="0 0 1200 120" xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.05 }}>
          <path d="M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18.19 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z" fill="#4299E1" />
        </svg>
      </div>
      
      {/* Floating decorative elements */}
      <div className="absolute top-10 left-5 w-8 h-8 text-blue-200 opacity-20 animate-bounce" style={{ animationDuration: '3s' }}>
        <Sparkles className="w-full h-full" />
      </div>
      
      {/* Main container with dotted border */}
      <div className="rounded-xl bg-transparent border-solid border-4 border-blue-100 p-4 relative overflow-hidden w-full">
        
        {/* Inner content with subtle background */}
        <div className="bg-blue-50 bg-opacity-30 rounded-lg p-6">
          <div className="text-center space-y-2">
            <div className="inline-flex items-center justify-center mb-2">
              <div className="w-12 h-12 relative">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-300 to-sky-400 rounded-full opacity-30 animate-ping" style={{ animationDuration: '3s' }}></div>
                <div className="relative flex items-center justify-center w-full h-full bg-gradient-to-r from-blue-400 to-sky-400 rounded-full">
                  <Heart className="h-6 w-6 text-white" />
                </div>
              </div>
            </div>
            <h2 className="text-2xl font-bold text-blue-500 font-serif">Welcome Back</h2>
            <p className="text-gray-600 font-serif text-sm">Please enter your details to sign in</p>
            
            <div className="mt-2 inline-flex items-center justify-center px-3 py-1 rounded-full bg-blue-50 border border-blue-100">
              <Check className="h-3 w-3 text-blue-500 mr-1" />
              <span className="text-blue-700 font-medium text-xs">Exclusive Kodava Matrimony</span>
            </div>
          </div>
          
          <form onSubmit={handleSubmit} className="space-y-4 mt-6">
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700 font-serif">Email</label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="text-blue-400 h-5 w-5" />
                </div>
                <input 
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 bg-white border-2 border-blue-100 rounded-lg focus:ring-2 focus:ring-blue-300 focus:border-blue-300 transition-all font-serif"
                  placeholder="Enter your email"
                  required
                />
              </div>
            </div>
            
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700 font-serif">Password</label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="text-blue-400 h-5 w-5" />
                </div>
                <input 
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full pl-10 pr-12 py-2 bg-white border-2 border-blue-100 rounded-lg focus:ring-2 focus:ring-blue-300 focus:border-blue-300 transition-all font-serif"
                  placeholder="••••••••"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-blue-600 transition-colors"
                >
                  {showPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                </button>
              </div>
            </div>

            {error && (
              <div className="p-4 rounded-lg bg-red-50 border border-red-200">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <AlertCircle className="h-5 w-5 text-red-400" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-800 font-serif">{error}</p>
                  </div>
                </div>
              </div>
            )}
            
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input 
                  type="checkbox" 
                  id="remember"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                  className="w-4 h-4 rounded border-2 border-blue-300 text-blue-500 focus:ring-blue-400 transition-colors cursor-pointer appearance-auto" 
                />
                <label 
                  htmlFor="remember" 
                  className="ml-2 text-sm text-gray-600 font-serif cursor-pointer"
                  onClick={() => setRememberMe(!rememberMe)}
                >
                  Remember me
                </label>
              </div>
              <button 
                type="button" 
                onClick={() => setShowForgotPassword(true)} 
                className="text-sm text-blue-500 hover:text-blue-600 font-medium transition-colors font-serif">
                Forgot password?
              </button>
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full py-2 px-4 bg-gradient-to-r from-blue-400 to-sky-400 hover:from-blue-500 hover:to-sky-500 text-white font-medium rounded-lg shadow-sm hover:shadow-md transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed font-serif border-2 border-blue-300"
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Signing in...
                </div>
              ) : 'Sign in'}
            </button>
          </form>
          
          <p className="text-center text-sm text-gray-600 font-serif mt-6">
            Don't have an account?{' '}
            <Link to="/register" className="text-blue-500 hover:text-blue-600 font-medium transition-colors">
              Sign up
            </Link>
          </p>
        </div>
      </div>

      {/* Forgot Password Modal */}
      {showForgotPassword && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl p-6 w-full max-w-md relative shadow-sm border-dotted border-4 border-blue-100 overflow-hidden">
            
            <button
              onClick={() => {
                setShowForgotPassword(false);
                setForgotPasswordStatus(null);
                setForgotPasswordEmail('');
              }}
              className="absolute top-4 right-4 text-gray-400 hover:text-blue-600 transition-colors z-10"
            >
              ×
            </button>
            
            <div className="bg-blue-50 bg-opacity-30 p-4 rounded-lg relative">
              <h3 className="text-xl font-semibold mb-4 text-blue-500 font-serif">Reset Password</h3>
              <div className="space-y-4">
                <div>
                  <label className="text-sm font-medium text-gray-700 font-serif">Email</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Mail className="text-blue-400 h-5 w-5" />
                    </div>
                    <input
                      type="email"
                      value={forgotPasswordEmail}
                      onChange={(e) => setForgotPasswordEmail(e.target.value)}
                      className="w-full pl-10 pr-4 py-2 bg-white border-2 border-blue-100 rounded-lg focus:ring-2 focus:ring-blue-300 focus:border-blue-300 transition-all font-serif"
                      placeholder="Enter your email"
                    />
                  </div>
                </div>
                
                {forgotPasswordStatus && (
                  <div className={`p-4 rounded-lg ${
                    forgotPasswordStatus.type === 'success' 
                      ? 'bg-green-50 text-green-800 border border-green-200'
                      : 'bg-red-50 text-red-800 border border-red-200'
                  }`}>
                    <p className="text-sm font-serif">{forgotPasswordStatus.message}</p>
                  </div>
                )}

                <button
                  onClick={handleForgotPassword}
                  className="w-full py-2 px-4 bg-gradient-to-r from-blue-400 to-sky-400 hover:from-blue-500 hover:to-sky-500 text-white font-medium rounded-lg shadow-sm hover:shadow-md transition-all duration-300 font-serif border-2 border-blue-300"
                >
                  Send Reset Instructions
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;