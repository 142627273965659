import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestAccountDeletion, logoutApi as logoutAPI } from '../../services/api';
import { AlertCircle, AlertTriangle } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { logout } from '../auth/authSlice';
import { SUPPORT_EMAIL } from '../constants';

const DeleteAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reason, setReason] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await requestAccountDeletion(reason);
      
      if (response.status === 'success') {
        // Logout process
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
          await logoutAPI(refreshToken);
        }
        
        // Clear local storage and redux state
        localStorage.clear();
        dispatch(logout());
        
        // Redirect to login with success message
        navigate('/login', { 
          state: { 
            message: 'Account deletion request submitted successfully. You have been logged out.' 
          }
        });
      } else {
        setError(response.message || `There was a brief interruption in sending your message. To ensure you receive prompt support, please contact us directly at ${SUPPORT_EMAIL}.`);
        setLoading(false);
      }
    } catch (err) {
      setError(err.response?.data?.message || `There was a brief interruption in sending your message. To ensure you receive prompt support, please contact us directly at ${SUPPORT_EMAIL}.`);
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-7xl bg-gradient-to-b from-sky-50 to-blue-50 min-h-screen">
      <div className="mb-8 text-center">
        <h1 className="text-3xl font-serif font-bold italic text-gray-900">
          Request Account Deletion
        </h1>
      </div>

      <div className="max-w-2xl mx-auto">
        <div className="bg-white rounded-xl shadow-md border border-sky-100 overflow-hidden">
          <div className="p-6 sm:p-8">
            <div className="text-center mb-8">
              <div className="inline-flex items-center justify-center p-3 bg-gradient-to-br from-sky-100 to-blue-100 rounded-full mb-4">
                <AlertTriangle className="h-8 w-8 text-sky-600" />
              </div>
              <h2 className="text-xl font-serif italic text-gray-800 mb-2">We're sorry to see you go</h2>
              <p className="text-gray-600 font-serif italic">
                Please let us know why you're leaving so we can improve our services.
              </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label 
                  htmlFor="reason" 
                  className="block text-sm font-serif italic font-medium text-gray-700 mb-2"
                >
                  Reason for leaving <span className="text-sky-500">*</span>
                </label>
                <div className="relative">
                  <textarea
                    id="reason"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    required
                    rows={4}
                    className="w-full px-4 py-3 border border-sky-200 rounded-lg focus:ring-2 focus:ring-sky-400 focus:border-sky-400 resize-none font-serif"
                    placeholder="Please tell us why you want to delete your account..."
                  />
                </div>
                <p className="mt-2 text-xs text-gray-500 font-serif italic">
                  Your feedback helps us improve our service for the community
                </p>
              </div>

              {error && (
                <div className="mb-6 bg-red-50 text-red-500 px-4 py-3 rounded-lg border border-blue-200 flex items-center gap-2 shadow-sm">
                  <span className="font-serif">{error}</span>
                </div>
              )}
              <div className="border-t border-gray-200 pt-6">
                <div className="flex gap-4 justify-end flex-wrap">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="px-5 py-2 text-sm font-serif italic font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-400 transition-all shadow-sm"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={loading || !reason.trim()}
                    className="px-5 py-2 text-sm font-serif italic font-medium text-white bg-gradient-to-r from-sky-500 to-blue-600 rounded-lg hover:from-sky-600 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 disabled:opacity-50 disabled:cursor-not-allowed transition-all shadow-sm"
                  >
                    {loading ? (
                      <span className="flex items-center gap-2">
                        <div className="h-4 w-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                        Processing...
                      </span>
                    ) : (
                      'Submit Request'
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* Information box */}
        <div className="mt-8 bg-gradient-to-r from-sky-50 to-blue-50 rounded-lg border border-sky-200 p-4 shadow-sm">
          <h3 className="font-serif italic font-medium text-gray-800 mb-2 flex items-center gap-2">
            <AlertCircle className="w-5 h-5 text-sky-500" />
            What happens next?
          </h3>
          <ul className="space-y-2 text-sm text-gray-600 pl-7 list-disc">
            <li className="font-serif italic">Your account deletion request will be processed within 24-48 hours.</li>
            <li className="font-serif italic">All your personal data will be permanently removed from our systems.</li>
            <li className="font-serif italic">If you change your mind, you can create a new account after 30 days.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;