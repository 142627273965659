export const GENDER_CHOICES = ['Male', 'Female'];
export const EDUCATION_CHOICES = [
  'High school',
  'PUC / 12th',
  'Bachelors / Diploma',
  'Masters',
  'Phd'
];

export const GANA_CHOICES = ["Deva Gana","Manushya Gana","Raksha Gana"]
export const RASHI_CHOICES = ["Mesh (Aries)", "Vrishabha (Taurus)", "Mithuna (Gemini)", "Karka (Cancer)", "Simha (Leo)", "Kanya (Virgo)", 
  "Tula (Libra)", "Vrishchika (Scorpio)", "Dhanu (Sagittarius)", "Makara (Capricorn)", "Kumbh (Aquarius)", "Meena (Pisces)"];
export const NAKSHATRA_CHOICES = ["Anuradha/Anusham/Anizham","Ardra/Thiruvathira","Ashlesha/Ayilyam","Ashwini/Ashwathi",
  "Bharani","Chitra/Chitha","Dhanista/Avittam","Hastha/Atham","Jyesta/Kettai","Krithika/Karthika","Makha/Magam",
  "Moolam/Moola","Mrigasira/Makayiram","Poorvabadrapada/Puratathi","Poorvapalguni/Puram/Pubbhe","Poorvashada/Pooradam",
  "Punarvasu/Punarpusam","Pushya/Poosam/Pooyam","Revathi","Rohini","Shatataraka/Sadayam/Satabishek","Shravan/Thiruvonam",
  "Swati/Chothi","Uttarabadrapada/Uthratadhi","Uttarapalguni/Uthram","Uttarashada/Uthradam","Vishaka/Vishakam"];

export const RELATIONSHIP_CHOICES = ["Self", "Father", "Mother", "Brother", "Sister", "Friend", "Other relationship"];
export const COMMUNITY_CHOICES = [
  'Kodava',
  'Amma Kodava',
  'Heggade',
  'Other Kodava Community'
];
export const MARITAL_STATUS_CHOICES = [
  'Unmarried',
  'Divorced',
  'Widowed',
  'Separated'
];

export const COUNTRY_OPTIONS = [
  "India", "United States", "Canada", "Ireland", "Germany", "United Kingdom", "Australia",
  "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Austria", "Azerbaijan",
  "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi",
  "Cabo Verde", "Cambodia", "Cameroon", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic",
  "Democratic Republic of the Congo", "Denmark", "Djibouti", "Dominica", "Dominican Republic",
  "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia",
  "Fiji", "Finland", "France",
  "Gabon", "Gambia", "Georgia", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana",
  "Haiti", "Honduras", "Hungary",
  "Iceland", "Indonesia", "Iran", "Iraq", "Israel", "Italy", "Ivory Coast",
  "Jamaica", "Japan", "Jordan",
  "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan",
  "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg",
  "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar",
  "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Korea", "North Macedonia", "Norway",
  "Oman",
  "Pakistan", "Palau", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal",
  "Qatar",
  "Romania", "Russia", "Rwanda",
  "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Korea", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria",
  "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu",
  "Uganda", "Ukraine", "United Arab Emirates" , "Uruguay", "Uzbekistan",
  "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
  "Yemen",
  "Zambia", "Zimbabwe"
  ]
  export const STATE_OPTIONS = [
    "Karnataka", "Andhra Pradesh", "Maharashtra",
    "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh",
    "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand",
    "Kerala", "Madhya Pradesh", "Manipur",
    "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab",
    "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura",
    "Uttar Pradesh", "Uttarakhand", "West Bengal", "Andaman and Nicobar Islands", "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu", "Delhi", "Jammu and Kashmir", "Ladakh", "Lakshadweep",
    "Puducherry"
];


export const SUPPORT_EMAIL = "thande.bendhe@gmail.com"