import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { getUserRelations } from '../../services/api';

// Create the context
const RelationsContext = createContext();

// Custom hook for using the context
export const useRelations = () => useContext(RelationsContext);

export const RelationsProvider = ({ children }) => {
  const [relations, setRelations] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastFetched, setLastFetched] = useState(null);

  // Function to fetch relations data
  const fetchRelations = useCallback(async (force = false) => {
    // Don't fetch if we already have data and it's less than 2 minutes old, unless forced
    const now = new Date();
    if (
      !force && 
      relations && 
      lastFetched && 
      (now.getTime() - lastFetched.getTime()) < 2 * 60 * 1000
    ) {
      return;
    }

    setLoading(true);
    try {
      const response = await getUserRelations();
      if (response.status === 'success') {
        setRelations(response.data);
        setLastFetched(now);
      } else {
        setError(response.message || 'Failed to load data');
      }
    } catch (err) {
      setError('Failed to load relations');
      console.error('Fetch error:', err);
    } finally {
      setLoading(false);
    }
  }, [relations, lastFetched]);

  // Calculate the counts for sidebar badges
  const counts = React.useMemo(() => {
    if (!relations) return {
      expressInterests: 0,
      contactRequests: 0,
      blockedProfiles: 0
    };

    // Express interests count
    const expressCount = relations.connections
      ? relations.connections.pending_received_requests.length +
        relations.connections.pending_sent_requests.length +
        relations.connections.accepted_connections.length +
        relations.connections.declined_received_requests.length +
        relations.connections.declined_sent_requests.length
      : 0;

    // Contact requests count
    const contactCount = relations.contact_requests
      ? relations.contact_requests.pending_received_requests.length +
        relations.contact_requests.pending_sent_requests.length +
        relations.contact_requests.accepted_requests.length +
        relations.contact_requests.declined_received_requests.length +
        relations.contact_requests.declined_sent_requests.length
      : 0;

    // Blocked profiles count
    const blockedCount = Array.isArray(relations.blocks) ? relations.blocks.length : 0;

    return {
      expressInterests: expressCount,
      contactRequests: contactCount,
      blockedProfiles: blockedCount
    };
  }, [relations]);

  // Initial fetch
  useEffect(() => {
    fetchRelations();
  }, [fetchRelations]);

  return (
    <RelationsContext.Provider
      value={{
        relations,
        loading,
        error,
        counts,
        fetchRelations,
        setRelations
      }}
    >
      {children}
    </RelationsContext.Provider>
  );
};

export default RelationsContext;