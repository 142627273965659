import React, { useState } from 'react';
import { Mail, Key, Eye, EyeOff, Info, Shield, CheckCircle2 } from 'lucide-react';
import { InfoCard, InlineInputWithValidation, Alert, AlertDescription } from '../../components';

export const LoginInfo = ({ formData, handleChange }) => {
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  // Validation states
  const validations = {
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email),
    password: formData.password && formData.password.length >= 6,
    confirmPassword: formData.password === formData.confirm_password && formData.confirm_password !== ''
  };

  const sectionCompletions = {
    credentials: validations.email && validations.password && validations.confirmPassword
  };

  const togglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <div className="space-y-6">
      <InfoCard title="Security Notice" icon={Shield} type="visibility">
        <div className="bg-sky-50 p-4 rounded-lg border border-sky-100">
          <p className="text-gray-700">
            These credentials will be used to access your account. Please ensure you use a secure password 
            and keep it confidential. Your login details are encrypted and stored securely.
          </p>
        </div>
      </InfoCard>

      <InfoCard 
        title="Account Credentials" 
        icon={Mail} 
        type="personal"
        isCompleted={sectionCompletions.credentials}
      >
        <div className="grid grid-cols-1 gap-6">
          <InlineInputWithValidation
            label="Email Address"
            icon={Mail}
            value={formData.email}
            isValid={validations.email}
            required={true}
            helper="This email will be used for account login and important notifications"
          >
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder="Enter your email address"
              required
            />
          </InlineInputWithValidation>

          <div className="space-y-4">
          <InlineInputWithValidation
            label="Password"
            icon={Key}
            value={formData.password}
            isValid={validations.password}
            required={true}
            helper="Password must be at least 6 characters long"
          >
            {/* Remove the wrapping div with relative class */}
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full pl-10 pr-12 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder="Enter password"
              required
              minLength="8"
            />
            <button
              type="button"
              onClick={() => togglePasswordVisibility('password')}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-sky-600"
            >
              {showPassword ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
            </button>
          </InlineInputWithValidation>
          </div>

          <InlineInputWithValidation
            label="Confirm Password"
            icon={Key}
            value={formData.confirm_password}
            isValid={validations.password && validations.confirmPassword}
            required={true}
          >
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirm_password"
                value={formData.confirm_password}
                onChange={handleChange}
                className="w-full pl-10 pr-12 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
                placeholder="Confirm your password"
                required
                minLength="8"
              />
              <button
                type="button"
                onClick={() => togglePasswordVisibility('confirm')}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-sky-600"
              >
                {showConfirmPassword ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
              </button>
          </InlineInputWithValidation>
        </div>
      </InfoCard>
    </div>
  );
};

export default LoginInfo;