import React, { useState, useEffect } from 'react';
import { User, Image, Eye, EyeOff, AlertCircle, HelpCircle, CheckCircle2, ChevronDown } from 'lucide-react';
import { InfoCard, InputWithValidation, Alert, AlertDescription } from '../../components';

const bioExamples = [
  "I love reading old books and museums. History and art are my passions. I'm quiet, enjoying alone time or deep talks. I fix old furniture. I enjoy simple things. I want to be a museum curator. I seek someone kind, smart, and who likes calm evenings and meaningful chats.",
  
  "I love to relax by baking or working on creative projects like painting or sketching. I'm passionate about making beautiful things and sharing them. I'm both introverted and extroverted, enjoying quiet time at home and fun gatherings with friends and family. I'm good at storytelling and love making up imaginative stories for kids. My life philosophy is to value family and friends, find beauty in everyday moments, and stay curious. I want to grow my freelance graphic design business so I can spend more time with family. I'm looking for a partner who loves family, is kind, and wants to create a warm, cozy home. I picture a life with shared meals, laughter, and a loving family.",
  
  "I love being outdoors, hiking or playing beach volleyball. I'm passionate about protecting the environment and living sustainably. I'm an extrovert, energized by people and new things. I also play the ukulele! My life philosophy is to live fully, embrace adventure, and seek new experiences. I want a career in environmental policy. I want a partner who is adventurous, open-minded, and loves the outdoors. I picture a life of travel, shared activities, and community.",
  
  "In my free time, I love trying new foods, eating out, and cooking. I'm always trying to learn and improve myself. I'm comfortable being around people, but I also enjoy my alone time. I'm good at public speaking, which has helped me a lot. I believe in doing my best, being honest, and kind. I want to keep growing in my job in [mention your field], and eventually lead a team. I'm looking for a partner who is smart, driven, and funny. I value honesty and respect. I want a life that balances work success with fun experiences, like traveling and spending time with family and friends. I want someone who enjoys both quiet nights at home and exploring new places with me."
];

const calculateRemainingChars = ( formData ) => {
  const currentLength = formData.about_me ? formData.about_me.length : 0;
  const minRequired = 50;
  
  if (currentLength < minRequired) {
    return minRequired - currentLength;
  }
  return 0;
};

export const AboutMe = ({ formData, handleChange, setParentPhotos, parentPhotos  }) => {
  const [showExamples, setShowExamples] = useState(false);
  const [photos, setPhotos] = useState({
    photo1: parentPhotos.photo1 || null,
    photo2: parentPhotos.photo2 || null,
    photo3: parentPhotos.photo3 || null
  });

  const [photoErrors, setPhotoErrors] = useState({
    photo1: '',
    photo2: '',
    photo3: ''
  });

  const validations = {
    about_me: formData.about_me && formData.about_me.length >= 50 && formData.about_me.length <= 1000,
    photo1: !!photos.photo1
  };

  useEffect(() => {
    if (setParentPhotos) {
      setParentPhotos({
        ...photos,
        isMainPhotoValid: !!photos.photo1 // Pass validation status to parent
      });
    }
  }, [photos, setParentPhotos]);

  const handlePhotoChange = (e, photoNumber) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setPhotoErrors({
          ...photoErrors,
          [photoNumber]: 'File size should be less than 5MB'
        });
        return;
      }
      
      if (!file.type.startsWith('image/')) {
        setPhotoErrors({
          ...photoErrors,
          [photoNumber]: 'Please upload an image file'
        });
        return;
      }

      const updatedPhotos = {
        ...photos,
        [photoNumber]: file
      };
      
      setPhotos(updatedPhotos);
      
      // Pass photos to parent component
      if (setParentPhotos) {
        setParentPhotos(updatedPhotos);
      }
      
      setPhotoErrors({
        ...photoErrors,
        [photoNumber]: ''
      });
    }
  };

  return (
    <div className="space-y-6">
      <InfoCard 
        title="About Me" 
        icon={User} 
        type="profile"
        isCompleted={validations.about_me}
      >
        <div className="grid grid-cols-1 gap-6">
        <InputWithValidation
          label="Tell us about yourself"
          value={formData.about_me}
          isValid={validations.about_me}
          helper="Be genuine and descriptive about yourself, your background, and what you're looking for. What are your favorite ways to spend time? What are you most passionate about? Are you more of an introvert or an extrovert? Do you have any unique talents or skills? What is your philosophy on life? What are your career goals? What qualities are you looking for in a partner? What kind of lifestyle are you looking for in a partner?"
        >
          <div className="w-full">
            <textarea
              name="about_me"
              value={formData.about_me}
              onChange={handleChange}
              className="w-full pl-2 pr-10 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              style={{ minHeight: "240px", height: "240px", resize: "vertical" }}
              placeholder="Share details about yourself, your interests, values, and what you're looking for..."
              required
            />
            {calculateRemainingChars(formData) > 0 && (
              <div className="text-xs text-amber-600 bg-amber-50 px-2 py-1 rounded-md border border-amber-200 mt-2 inline-block">
                {calculateRemainingChars(formData)} more characters needed
              </div>
            )}
          </div>
        </InputWithValidation>

          <div className="bg-blue-50 p-4 rounded-lg">
              <button 
                onClick={() => setShowExamples(!showExamples)}
                className="w-full flex items-center justify-between gap-2 mb-3"
              >
                <div className="flex items-center gap-2">
                  <HelpCircle className="h-5 w-5 text-blue-400" />
                  <h4 className={`text-gray-700`}>Example Bios</h4>
                </div>
                <ChevronDown className={`h-5 w-5 text-blue-600 transition-transform duration-200 ${showExamples ? 'rotate-180' : ''}`} />
              </button>
            
              {showExamples && (
                <div className="space-y-4 mt-2">
                  {bioExamples.map((example, index) => (
                    <div key={index} className="pb-4 border-b border-black-100 last:border-50">
                      <p className={`text-sm text-gray-600`}>
                        {example}
                      </p>
                    </div>
                  ))}
                </div>
              )}
          </div>
        </div>
      </InfoCard>

      <InfoCard 
        title="Profile Photos" 
        icon={Image} 
        type="profile"
        isCompleted={validations.photo1}
      >
        <div className="space-y-4">          
          <Alert className={`mb-4 border-2 border-dashed border-emerald-300`}>
            <AlertDescription>
            One photo is mandatory. 
            You can add up to two additional photos.
            Maximum file size: 5MB per photo.
          </AlertDescription>
          </Alert>
        </div>
        <hr className="my-4 border-gray-200" />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Main Profile Photo */}
          <div className="space-y-2">
            <label className={`block text-sm`}>
              Main Profile Photo *
            </label>
            <div className="relative">
              <input
                type="file"
                id="photo1"
                accept="image/*"
                onChange={(e) => handlePhotoChange(e, 'photo1')}
                className="hidden"
              />
              <label
                htmlFor="photo1"
                className="flex flex-col items-center justify-center p-6 border-2 border-dashed border-emerald-300 rounded-lg hover:border-emerald-500 transition-colors cursor-pointer w-full h-full"
              >
                {photos.photo1 ? (
                  <div className="relative w-full">
                    <img
                      src={URL.createObjectURL(photos.photo1)}
                      alt="Profile preview"
                      className="w-full h-40 object-cover rounded"
                    />
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setPhotos({ ...photos, photo1: null });
                        setPhotoErrors({ ...photoErrors, photo1: '' });
                      }}
                      className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-600 transition-colors"
                    >
                      ×
                    </button>
                  </div>
              ) : (
                <div className="text-center">
                      <Image className="mx-auto h-12 w-12 text-emerald-400" />
                      <div className="mt-2">
                        <span className="text-sm text-emerald-600 hover:text-emerald-500">
                          Upload photo
                        </span>
                      </div>
                    </div>
                    )}
                  </label>
                  {photoErrors.photo1 && (
                    <p className="mt-2 text-sm text-red-600">{photoErrors.photo1}</p>
                  )}
                </div>
              </div>

            {/* Additional Photos */}
            {['photo2', 'photo3'].map((photoKey, index) => (
              <div key={photoKey} className="space-y-2">
                <label className={`block text-sm`}>
                  Additional Photo {index + 1}
                </label>
                <div className="relative">
                  <input
                    type="file"
                    id={photoKey}
                    accept="image/*"
                    onChange={(e) => handlePhotoChange(e, photoKey)}
                    className="hidden"
                  />
                  <label
                    htmlFor={photoKey}
                    className="flex flex-col items-center justify-center p-6 border-2 border-dashed border-emerald-300 rounded-lg hover:border-emerald-500 transition-colors cursor-pointer w-full h-full"
                  >
                    {photos[photoKey] ? (
                      <div className="relative w-full">
                        <img
                          src={URL.createObjectURL(photos[photoKey])}
                          alt={`Additional photo ${index + 1}`}
                          className="w-full h-40 object-cover rounded"
                        />
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setPhotos({ ...photos, [photoKey]: null });
                            setPhotoErrors({ ...photoErrors, [photoKey]: '' });
                          }}
                          className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-600 transition-colors"
                        >
                          ×
                        </button>
                      </div>
                    ) : (
                      <div className="text-center">
                        <Image className="mx-auto h-12 w-12 text-emerald-400" />
                        <div className="mt-2">
                          <span className="text-sm text-emerald-600 hover:text-emerald-500">
                            Upload photo
                          </span>
                        </div>
                      </div>
                    )}
                  </label>
                  {photoErrors[photoKey] && (
                    <p className="mt-2 text-sm text-red-600">{photoErrors[photoKey]}</p>
                  )}
                </div>
              </div>
            ))}
        </div>
        <br />
        <div className="flex items-center space-x-3">
            <input
              type="checkbox"
              id="public_visibility"
              name="public_visibility"
              checked={formData.public_visibility}
              onChange={handleChange}
              className="h-5 w-5 text-purple-600 border-gray-300 rounded focus:ring-purple-500 appearance-auto"
              style={{ appearance: 'auto' }}
            />
            <label htmlFor="public_visibility" className={`text-sm`}>
              Make my photos visible to all members
            </label>
          </div>
      </InfoCard>
    </div>
  );
};

export default AboutMe;