import React from 'react';
import { Star, Moon, Sun, ExternalLink } from 'lucide-react';
import { InlineInputWithValidation, EnhancedSelect, InfoCard } from '../../components';
import { GANA_CHOICES, RASHI_CHOICES, NAKSHATRA_CHOICES } from '../../constants';

export const Horoscope = ({ formData, handleChange }) => {
  // Validation states
  const validations = {
    gana: formData.gana && formData.gana.trim() !== '',
    rashi: formData.rashi && formData.rashi.trim() !== '',
    nakshatra: formData.nakshatra && formData.nakshatra.trim() !== ''
  };

  return (
    <div className="space-y-6">
      <InfoCard 
        title="Horoscope Information" 
        icon={Star} 
        type="profile"
      >
        <div className="space-y-4">
          <div className="flex items-center text-sm bg-indigo-50 p-3 rounded-lg border border-indigo-100 shadow-sm">
            <a 
              href="https://rforrabbit.com/pages/nakshatra-calculator" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-indigo-600 hover:text-indigo-800 flex items-center"
            >
              <span>Click here to know your Rashi and Nakshatra by birth time</span>
              <ExternalLink className="h-4 w-4 ml-2 flex-shrink-0" />
            </a>
          </div>
          
          <InlineInputWithValidation
            label="Gana"
            icon={Star}
            value={formData.gana}
            isValid={validations.gana}
            required={true}
          >
            <EnhancedSelect
              name="gana"
              value={formData.gana}
              onChange={handleChange}
              options={GANA_CHOICES}
              placeholder="Select Gana"
              icon={Star}
              required
            />
          </InlineInputWithValidation>

          <InlineInputWithValidation
            label="Rashi"
            icon={Moon}
            value={formData.rashi}
            isValid={validations.rashi}
            required={true}
          >
            <EnhancedSelect
              name="rashi"
              value={formData.rashi}
              onChange={handleChange}
              options={RASHI_CHOICES}
              placeholder="Select Rashi"
              icon={Moon}
              required
            />
          </InlineInputWithValidation>

          <InlineInputWithValidation
            label="Nakshatra"
            icon={Sun}
            value={formData.nakshatra}
            isValid={validations.nakshatra}
            required={true}
          >
            <EnhancedSelect
              name="nakshatra"
              value={formData.nakshatra}
              onChange={handleChange}
              options={NAKSHATRA_CHOICES}
              placeholder="Select Nakshatra"
              icon={Sun}
              required
            />
          </InlineInputWithValidation>
        </div>
      </InfoCard>
    </div>
  );
};

export default Horoscope;