// Support.js
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { sendSupportMessage } from '../../services/api';
import { MessageCircle, Send, AlertCircle, CheckCircle } from 'lucide-react';
import { SUPPORT_EMAIL } from '../constants';

const Alert = ({ children, className = '', variant }) => {
  const baseClasses = "p-4 rounded-lg flex items-start";
  
  if (variant === "destructive") {
    return (
      <div className={`${baseClasses} bg-red-50 border border-red-200 ${className}`}>
        <AlertCircle className="h-5 w-5 text-red-500 mt-0.5 mr-2 flex-shrink-0" />
        <div>{children}</div>
      </div>
    );
  }
  
  return (
    <div className={`${baseClasses} ${className}`}>
      <CheckCircle className="h-5 w-5 text-green-500 mt-0.5 mr-2 flex-shrink-0" />
      <div>{children}</div>
    </div>
  );
};

const AlertDescription = ({ children }) => (
  <div className="text-sm">{children}</div>
);

const Support = () => {
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  
  // Get email from Redux state
  const email = useSelector(state => localStorage.getItem('email'));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      // Send the message with subject
      await sendSupportMessage({
        message,
        subject: subject || 'Support Request' // Default subject if none provided
      });
      
      setSuccess('Your message has been sent successfully. We will get back to you soon.');
      // Clear the form fields
      setMessage('');
      setSubject('');
    } catch (err) {
      setError(err.response?.data?.message || `We encountered a temporary issue sending your message. Please try logging out and back in. If the problem persists, please email us at ${SUPPORT_EMAIL} for prompt assistance.`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-4">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-2xl font-medium bg-gradient-to-r from-sky-600 to-indigo-600 bg-clip-text text-transparent mb-4">Contact Support</h1>
        
        <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-300">
          <div className="space-y-4">
            <div className="flex items-center gap-4 border-b border-gray-200 pb-4 mb-4">
              <div className="bg-gradient-to-r from-sky-100 to-indigo-100 w-14 h-14 rounded-lg flex items-center justify-center">
                <MessageCircle className="h-7 w-7 text-sky-500" />
              </div>
              <div>
                <p className="text-gray-600">
                  How can we help you? We'll respond to your message at <span className="font-medium text-sky-600">{email}</span>
                </p>
              </div>
            </div>

            {error && (
              <Alert variant="destructive">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {success && (
              <Alert className="bg-green-50 text-green-800 border border-green-200">
                <AlertDescription>{success}</AlertDescription>
              </Alert>
            )}

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label 
                  htmlFor="subject" 
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Subject
                </label>
                <input
                  id="subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
                  placeholder="What is your request about?"
                />
              </div>

              <div>
                <label 
                  htmlFor="message" 
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Your Message
                </label>
                <textarea
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  rows={6}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
                  placeholder="Please describe your issue or question..."
                />
              </div>

              <div className="flex justify-end pt-2 border-t border-gray-200">
                <button
                  type="submit"
                  disabled={loading || !message.trim()}
                  className="inline-flex items-center px-6 py-2 text-sm font-medium text-white bg-gradient-to-r from-sky-500 to-indigo-600 hover:from-sky-600 hover:to-indigo-700 rounded-lg shadow-sm border border-sky-600 hover:shadow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-400 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200"
                >
                  {loading ? (
                    <div className="flex items-center">
                      <div className="inline-block animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent mr-2"/>
                      Sending...
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <Send className="h-4 w-4 mr-2" />
                      Send Message
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;