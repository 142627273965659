import React from 'react';
import { Check, CheckCircle2, XCircle } from 'lucide-react';
import { SUPPORT_EMAIL } from './constants';


export const InlineInputWithValidation = ({ label, icon: Icon, children, isValid, helper, required = false }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-[200px_1fr_24px] gap-1 items-start py-1">
      <label className="text-sm font-medium text-gray-700 pt-2">
        {label}
        {required && <span className="text-red-500 ml-1">*</span>}
      </label>
      <div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Icon className="h-5 w-5 text-sky-400" />
          </div>
          {children}
        </div>
        {helper && (
          <div className="mt-2 text-sm text-gray-500">
            {typeof helper === 'string' ? (
              <p>{helper}</p>
            ) : (
              helper
            )}
          </div>
        )}
      </div>
      <div className="flex items-start pt-2 justify-end md:justify-start">
        {isValid && <CheckCircle2 className="h-5 w-5 text-teal-500" />}
      </div>
    </div>
  );
};

export const InputWithValidation1 = ({ label, icon: Icon, value, isValid, children, helper }) => (
  <div className="space-y-2">
    <label className="text-sm font-medium text-gray-700">{label} *</label>
    <div className="relative flex items-center">
      <div className="absolute left-3 top-1/2 -translate-y-1/2">
        <Icon className="text-sky-400 h-5 w-5" />
      </div>
      {children}
      {value && isValid && (
        <div className="absolute right-3 top-1/2 -translate-y-1/2">
          <Check className="text-teal-500 h-5 w-5" />
        </div>
      )}
    </div>
    {helper && <p className="text-xs text-gray-500">{helper}</p>}
  </div>
);

export const InputWithValidation = ({ label, icon: Icon, value, isValid, children, helper }) => (
  <div className="space-y-2">
    <label className="text-sm font-medium text-gray-700">{label} *</label>
    <div className="relative flex items-center">
      {Icon && (
        <div className="absolute left-3 top-1/2 -translate-y-1/2">
          <Icon className="text-sky-400 h-5 w-5" />
        </div>
      )}
      {React.cloneElement(children, {
        className: `${children.props.className || ''} ${Icon ? 'pl-10' : 'pl-3'}`.trim()
      })}
      {value && isValid && (
        <div className="absolute right-3 top-1/2 -translate-y-1/2">
          <Check className="text-teal-500 h-5 w-5" />
        </div>
      )}
    </div>
    {helper && <p className="text-xs text-gray-500">{helper}</p>}
  </div>
);

export const Alert = ({ children, className = '' }) => (
  <div className={`p-4 rounded-lg ${className}`}>
    {children}
  </div>
);

export const AlertDescription = ({ children }) => (
  <div className="flex items-start">
    <div className="flex-1 ml-3">
      {children}
    </div>
  </div>
);

export const EnhancedSelect = ({ 
  name, 
  value, 
  onChange, 
  options, 
  placeholder, 
  icon: Icon, 
  className = '' 
}) => {
  return (
    <div className="relative flex-grow">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <Icon className="h-5 w-5 text-sky-400" />
      </div>
      <select
        name={name}
        value={value}
        onChange={onChange}
        className={`w-full pl-10 pr-12 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 appearance-none bg-white
          font-serif
          [&>option]:font-serif [&>option]:text-gray-900 [&>option]:italic
          [&>option[value=""]]:text-gray-400 [&>option[value=""]]:italic [&>option[value=""]]:font-light ${className}`}
      >
        <option value="" className="text-gray-400 font-light italic">{placeholder}</option>
        {options.map(option => (
          <option 
            key={option} 
            value={option} 
            className="text-gray-900 italic font-medium tracking-wide"
          >
            {option}
          </option>
        ))}
      </select>
      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        <svg className="h-5 w-5 text-sky-400" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </div>
    </div>
  );
};

export const FileInputWithValidation = ({ 
  label, 
  icon: Icon, 
  isValid, 
  helper, 
  required = false, 
  onChange, 
  value,
  onRemove
}) => {
  const fileInputRef = React.useRef(null);
  const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB limit

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        alert('File size should not exceed 1MB');
        e.target.value = '';
        if (onRemove) onRemove();
        return;
      }
      onChange(e);
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-[200px_1fr_32px] gap-4 items-start">
      <label className="text-sm font-medium text-gray-700 pt-2">
        {label}
        {required && <span className="text-red-500 ml-1">*</span>}
      </label>
      <div className="flex flex-col gap-2">
        <div className="relative flex items-center">
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            accept=".pdf,.jpg,.jpeg,.png"
            className="hidden"
            id="identity_proof"
            required={required}
          />
          {value ? (
            <div className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg bg-sky-50 flex items-center justify-between">
              <div className="flex items-center flex-1 min-w-0">
                <div className="absolute left-3 top-1/2 -translate-y-1/2">
                  <Icon className="h-5 w-5 text-sky-500" />
                </div>
                <span className="truncate text-sky-700">{value}</span>
              </div>
              <button
                type="button"
                onClick={() => {
                  if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                  }
                  if (onRemove) onRemove();
                }}
                className="ml-2 text-red-500 hover:text-red-700 p-1 rounded-full hover:bg-red-50"
              >
                <XCircle className="h-5 w-5" />
              </button>
            </div>
          ) : (
            <label
              htmlFor="identity_proof"
              className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg cursor-pointer flex items-center text-gray-700 bg-white hover:bg-sky-50"
            >
              <div className="absolute left-3 top-1/2 -translate-y-1/2">
                <Icon className="h-5 w-5 text-sky-400" />
              </div>
              Choose a file...
            </label>
          )}
        </div>
        {helper && (
          <p className="text-sm text-gray-500">{helper}</p>
        )}
        <p className="text-xs text-gray-500">Maximum file size: 1MB | Supported formats: PDF, JPG, PNG</p>
      </div>
      <div className="flex items-center justify-center pt-2 md:pt-0 md:justify-start">
       {/* {isValid && <CheckCircle2 className="h-5 w-5 text-green-500" />} */ }
      </div>
    </div>
  );
};

// Color themes for different card types
const cardThemes = {
  visibility: {
    gradient: 'bg-sky-50',
    border: 'border-sky-200',
    iconBg: 'bg-sky-100',
    iconColor: 'text-sky-500',
    headerBg: 'bg-sky-50',
    typography: {
      title: 'font-serif font-bold tracking-wide italic',
      label: 'font-mono tracking-wider font-medium',
      content: 'font-sans italic',
      helper: 'font-serif italic font-medium',
      radioLabel: 'font-light italic'
    }
  },
  personal: {
    gradient: 'bg-blue-50',
    border: 'border-blue-200',
    iconBg: 'bg-blue-100',
    iconColor: 'text-blue-500',
    headerBg: 'bg-blue-50',
    typography: {
      title: 'font-serif font-bold tracking-wide italic',
      label: 'font-serif tracking-wide font-medium italic',
      content: 'font-sans italic',
      helper: 'font-serif italic font-medium',
      inputLabel: 'font-light italic'
    }
  },
  profile: {
    gradient: 'bg-indigo-50',
    border: 'border-indigo-200',
    iconBg: 'bg-indigo-100',
    iconColor: 'text-indigo-500',
    headerBg: 'bg-indigo-50',
    typography: {
      title: 'font-serif font-bold tracking-wide italic',
      label: 'font-serif tracking-wide font-medium italic',
      content: 'font-sans italic',
      helper: 'font-serif italic font-medium',
      selectLabel: 'font-light italic'
    }
  },
  verification: {
    gradient: 'bg-teal-50',
    border: 'border-teal-200',
    iconBg: 'bg-teal-100',
    iconColor: 'text-teal-500',
    headerBg: 'bg-teal-50',
    typography: {
      title: 'font-serif font-bold tracking-wide italic',
      label: 'font-serif tracking-wide font-medium italic',
      content: 'font-sans italic',
      helper: 'font-serif italic font-medium',
      fileLabel: 'font-light italic'
    }
  }
};

// More compatible version
export const InfoCard = ({ title, icon: Icon, children, type }) => {
  const theme = cardThemes[type];
  
  return (
    <div className={`rounded-xl border ${theme.border} overflow-hidden transition-all duration-300 hover:shadow-lg 
    bg-white bg-opacity-90`}>
      <div className={`${theme.gradient} bg-opacity-30`}>
        <div className={`px-6 py-4 ${theme.headerBg} bg-opacity-70`}>
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className={`p-2 rounded-lg ${theme.iconBg}`}>
                <Icon className={`h-5 w-5 ${theme.iconColor}`} />
              </div>
              <h3 className={`text-lg text-gray-800 ${theme.typography.title}`}>{title}</h3>
            </div>
          </div>
        </div>
        <div className={`p-6 ${theme.typography.content}`}>{children}</div>
      </div>
    </div>
  );
};

export const ErrorMessage = (error) => {
  // Handle both error string and error object
  const errorMessage = typeof error === 'string' ? error : (error.error || error.message || "Unknown error occurred");
  
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="bg-red-50 text-red-500 p-4 rounded-lg border border-red-100">
        {errorMessage}
        <br/>
        Please reload or log in. If you believe this is an error, contact us at <b>{SUPPORT_EMAIL}</b> with details.
      </div>
    </div>
  );
};