import { createSlice } from '@reduxjs/toolkit';

// Helper to check token expiration
const isTokenExpired = (token) => {
  if (!token) return true;
  
  try {
    // Tokens have 3 parts separated by dots
    const parts = token.split('.');
    if (parts.length !== 3) {
      console.error('Invalid token format');
      return true;
    }
    
    const payload = parts[1];
    // Base64 decode and parse JSON
    const decoded = JSON.parse(atob(payload));
    // Check if exp timestamp is in the past
    return decoded.exp * 1000 < Date.now();
  } catch (error) {
    console.error('Error checking token expiration:', error);
    return true;
  }
};

// Helper to check if we should clear tokens based on "Remember Me" settings
const shouldClearTokens = () => {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  
  // If no tokens, nothing to clear
  if (!accessToken || !refreshToken) return false;
  
  // Check if this is a new browser session (not just a new tab)
  // and if the user had session-only tokens
  const wasSessionOnly = localStorage.getItem('wasSessionOnly') === 'true';
  
  // Define currentTime at the function scope level so it's available throughout
  const currentTime = new Date().getTime();
  
  // Don't clear tokens for new tabs by detecting sessionStorage
  if (wasSessionOnly && !sessionStorage.getItem('tabSession')) {
    // Set a marker in this tab's sessionStorage
    sessionStorage.setItem('tabSession', 'active');
    
    // Check timestamp only if we're in what appears to be a new browser session
    const lastSessionTime = localStorage.getItem('lastSessionTime');
    
    if (lastSessionTime) {
      // Consider the session expired if it's been more than 30 minutes
      const sessionTimeout = 60 * 60 * 1000; // 60 minutes in milliseconds
      if (currentTime - parseInt(lastSessionTime) > sessionTimeout) {
        console.log('Clearing session-only tokens after browser restart');
        return true;
      }
    }
  }
  
  // Update the last session time
  localStorage.setItem('lastSessionTime', currentTime.toString());
  
  // Check if Remember Me expiry has passed
  const tokenExpiry = localStorage.getItem('tokenExpiry');
  if (tokenExpiry) {
    const expiryDate = new Date(tokenExpiry);
    const now = new Date();
    if (now > expiryDate) {
      console.log('Clearing expired remember-me tokens');
      return true;
    }
  }
  
  return false;
};

// Function to clear all auth-related data from storage
const clearAuthStorage = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userId');
  localStorage.removeItem('email');
  localStorage.removeItem('firstName');
  localStorage.removeItem('tokenExpiry');
  localStorage.removeItem('wasSessionOnly');
  sessionStorage.removeItem('sessionOnly');
};


// Initialize state from localStorage
const getInitialState = () => {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  
  // For new tabs in incognito, we should be more lenient about token validation
  if (accessToken && refreshToken) {
    // Only clear tokens if they're definitely expired
    if (isTokenExpired(accessToken)) {
      clearAuthStorage();
      return {
        user: null,
        userId: null,
        accessToken: null,
        refreshToken: null,
        firstName: null,
        email: null,
        loading: false,
        error: null,
        tokenExpiring: false,
      };
    }
    
    // Update session markers but don't clear tokens for new tabs
    if (sessionStorage.getItem('sessionOnly') === 'true') {
      localStorage.setItem('wasSessionOnly', 'true');
    }
    
    // Always set tabSession to help identify tabs
    sessionStorage.setItem('tabSession', 'active');
    
    // Update last session time
    localStorage.setItem('lastSessionTime', new Date().getTime().toString());
  
    return {
      user: { id: localStorage.getItem('userId') },
      userId: localStorage.getItem('userId'),
      accessToken,
      refreshToken,
      firstName: localStorage.getItem('firstName'),
      email: localStorage.getItem('email'),
      loading: false,
      error: null,
      tokenExpiring: false,
    };
  }
  
  // If no tokens found, return empty state
  return {
    user: null,
    userId: null,
    accessToken: null,
    refreshToken: null,
    firstName: null,
    email: null,
    loading: false,
    error: null,
    tokenExpiring: false,
  };
};

const authSlice = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {
    loginStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      
      // Normalize different API response structures
      const payload = action.payload.data ? action.payload.data : action.payload;
      
      // Update Redux state
      state.user = { id: payload.user_id };
      state.userId = payload.user_id;
      state.accessToken = payload.access;
      state.refreshToken = payload.refresh;
      state.firstName = payload.first_name;
      state.email = payload.email;
      
      // Sync to localStorage
      localStorage.setItem('accessToken', payload.access);
      localStorage.setItem('refreshToken', payload.refresh);
      localStorage.setItem('userId', payload.user_id);
      if (payload.email) localStorage.setItem('email', payload.email);
      localStorage.setItem('firstName', payload.first_name);
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.user = null;
      state.userId = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.firstName = null;
      state.email = null;
      
      // Clear localStorage
      clearAuthStorage();

    },
    refreshTokenStart: (state) => {
      state.tokenExpiring = true;
    },
    refreshTokenSuccess: (state, action) => {
      state.accessToken = action.payload.access;
      state.tokenExpiring = false;
      
      // Update localStorage
      localStorage.setItem('accessToken', action.payload.access);
    },
    refreshTokenFailure: (state) => {
      state.tokenExpiring = false;
      // Don't logout here - that should be handled separately if needed
    },
    logout: (state) => {
      // Clear Redux state
      state.user = null;
      state.userId = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.firstName = null;
      state.email = null;
      state.error = null;
      
      // Clear localStorage and sessionStorage
      clearAuthStorage();
    }
  }
});

export const { 
  loginStart, 
  loginSuccess, 
  loginFailure, 
  refreshTokenStart,
  refreshTokenSuccess,
  refreshTokenFailure,
  logout 
} = authSlice.actions;

export default authSlice.reducer;