import axios from 'axios';

// Determine the base URL based on the current environment
const getBaseURL = () => {
  // Check if we're in production (on Digital Ocean)
  const currentHost = window.location.hostname;

  if (currentHost.includes('localhost')) {
    // In production, use the full URL with the IP
    return 'http://localhost:8000/api';
  }
  
  // In local development, use localhost
  return 'https://thandebendhe.com/api';
};

// Create an axios instance for API calls
const api = axios.create({
  baseURL: getBaseURL(),
  timeout: 10000, // Add a reasonable timeout
});

// Create a separate instance for token refresh to avoid infinite loops
const refreshApi = axios.create({
  baseURL: getBaseURL(),
  timeout: 10000,
});

// Variables to hold callback for dispatching actions
let tokenRefreshCallback = null;

// Function to set the callback for dispatching token actions
export const setAuthDispatch = (callback) => {
  tokenRefreshCallback = callback;
};

// Function to refresh the token
const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  
  if (!refreshToken) {
    console.error('No refresh token available');
    throw new Error('No refresh token available');
  }
  
  try {
    console.log('Attempting to refresh token');
    if (tokenRefreshCallback) {
      tokenRefreshCallback({ type: 'refreshTokenStart' });
    } else {
      console.error('Token refresh callback not set');
    }
    
    const response = await refreshApi.post('/auth/refresh/', { refresh: refreshToken });
    
    if (tokenRefreshCallback) {
      tokenRefreshCallback({ 
        type: 'refreshTokenSuccess', 
        payload: response.data 
      });
    }
    
    return response.data.access;
  } catch (error) {
    if (tokenRefreshCallback) {
      tokenRefreshCallback({ type: 'refreshTokenFailure' });
    }
    throw error;
  }
};

// Flag to prevent multiple refresh requests
let isRefreshing = false;
// Queue of requests to retry after token refresh
let refreshSubscribers = [];

// Helper to add request to queue
const subscribeTokenRefresh = (callback) => {
  refreshSubscribers.push(callback);
};

// Helper to notify all requests in queue
const onTokenRefreshed = (token) => {
  refreshSubscribers.forEach(callback => callback(token));
  refreshSubscribers = [];
};

// Helper to reject all requests in queue
const onRefreshError = (error) => {
  refreshSubscribers.forEach(callback => callback(null, error));
  refreshSubscribers = [];
};

// Add request interceptor to add auth token
api.interceptors.request.use(
  (config) => {
    // Skip adding token for explicitly marked requests
    if (config.skipAuth) {
      return config;
    }
    
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor to handle token refresh
api.interceptors.response.use(
  // Return successful responses as-is
  (response) => response,
  
  // Handle errors - specifically 401 Unauthorized
  async (error) => {
    const originalRequest = error.config;
    
    // Don't retry if we already tried, or if it's a refresh request, or if it's not a 401 error
    if (
      originalRequest._retry || 
      originalRequest.url === '/auth/refresh/' || 
      error.response?.status !== 401 ||
      originalRequest.skipTokenRefresh ||
      originalRequest.skipAuth
    ) {
      return Promise.reject(error);
    }
    
    // Mark that we're retrying this request
    originalRequest._retry = true;
    
    if (!isRefreshing) {
      isRefreshing = true;
      
      try {
        const newToken = await refreshAccessToken();
        isRefreshing = false;
        
        // Notify subscribers that token is refreshed
        onTokenRefreshed(newToken);
        
        // Retry original request with new token
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return api(originalRequest);
        
      } catch (refreshError) {
        isRefreshing = false;
        
        // Notify subscribers about refresh failure
        onRefreshError(refreshError);
        
        // If refresh fails, log out the user
        if (tokenRefreshCallback) {
          tokenRefreshCallback({ type: 'logout' });
        }
        
        return Promise.reject(refreshError);
      }
    }
    
    // If refresh is already in progress, add request to queue
    return new Promise((resolve, reject) => {
      subscribeTokenRefresh((token, err) => {
        if (err) {
          reject(err);
          return;
        }
        
        if (!token) {
          reject(new Error('Failed to refresh token'));
          return;
        }
        
        originalRequest.headers.Authorization = `Bearer ${token}`;
        resolve(api(originalRequest));
      });
    });
  }
);

export const register = async (formData) => {
  try {
    // Don't manipulate the formData, just send it directly
    const response = await api.post('/accounts/register/', formData, {
      skipAuth: true,
      // Don't manually set Content-Type - axios will set it correctly with the boundary
      headers: {}
    });
    return response.data;
  } catch (error) {
    console.error('Registration API error:', error);
    
    if (error.response) {
      // Log the error response for debugging
      console.error('Error response data:', error.response.data);
      console.error('Error status:', error.response.status);
      
      // If we have a structured error response, return it
      if (error.response.data && typeof error.response.data === 'object') {
        return {
          status: 'error',
          message: error.response.data.message || 'Registration failed',
          errors: error.response.data.errors || {}
        };
      }
    }
    
    // Re-throw the error for the component to handle
    throw error;
  }
};

export const resetPasswordWithToken = async (token, email, newPassword) => {
  try {
    const response = await api.post('/accounts/reset-password/', {
      token,
      email,
      new_password: newPassword
    }, {
      skipAuth: true // Skip adding auth token since this is a public endpoint
    });
    return response.data;
  } catch (error) {
    console.error('Reset password API error:', error.response?.data || error);
    throw error;
  }
};

export const login = async (email, password) => {
  try {
    const response = await api.post('/auth/login/', { email, password }, {
      skipAuth: true,
      skipTokenRefresh: true
    });
    return response.data;
  } catch (error) {
    console.error('Login API error:', error.response?.data || error);
    throw error;
  }
};

export const forgotPassword = async (email) => {
  try {
    const base_url = window.location.origin;
    const response = await api.post('/accounts/forgot-password/', { email, base_url  }, {
      skipAuth: true
    });
    return response.data;
  } catch (error) {
    console.error('Forgot password API error:', error.response?.data || error);
    throw error;
  }
};

export const getProfile = async (userId) => {
  try {
    const response = await api.get(`/profiles/${userId}/`);
    return response.data;
  } catch (error) {
    console.error('Get profile API error:', error.response?.data || error);
    throw error;
  }
};

export const browseProfiles = async () => {
  try {
    const response = await api.get('/browse-profiles/');
    
    // Handle the API response structure properly
    if (response.data && response.data.status === 'success') {
      return {
        status: 'success',
        results: response.data.results || []
      };
    } else {
      console.error('Unexpected API response format:', response.data);
      return {
        status: 'error',
        error: 'Unexpected response format',
        results: []
      };
    }
  } catch (error) {
    console.error('Browse profiles API error:', error.response?.data || error);
    return {
      status: 'error',
      error: error.response?.data?.error || 'Failed to fetch profiles',
      results: []
    };
  }
};

export const getMyProfile = async () => {
  try {
    const response = await api.get('/my-profile/');
    return {
      status: 'success',
      data: response.data
    };
  } catch (error) {
    console.error('My profile API error:', error.response?.data || error);
    throw error;
  }
};

export const getConnections = async () => {
  try {
    const response = await api.get('/connections/requests/');
    return response.data;
  } catch (error) {
    console.error('Get connections API error:', error.response?.data || error);
    throw error;
  }
};

export const sendConnectionRequest = async (receiverId) => {
  try {
    const response = await api.post('/connections/request/', { 
      receiver_id: receiverId
    });
    return response.data;
  } catch (error) {
    console.error('Send connection request API error:', error.response?.data || error);
    throw error;
  }
};

export const updateConnectionRequest = async (connectionId, action) => {
  try {
    const status = action === 'accept' ? 'Accepted' : 'Declined';
    console.log(`Updating connection ${connectionId} to ${status}`);
    
    const response = await api.put(`/connections/request/${connectionId}/`, {
      status: status
    });
    
    console.log('Update response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Update connection request API error:', error.response?.data || error);
    throw error;
  }
};

export const logoutApi = async (refresh_token) => {
  try {
    const response = await api.post('/auth/logout/', { refresh_token });
    return response.data;
  } catch (error) {
    console.error('Logout API error:', error.response?.data || error);
    throw error;
  }
};

export const requestAccountDeletion = async (reason) => {
  try {
    const response = await api.post('/accounts/delete-request/', { reason });
    return response.data;
  } catch (error) {
    console.error('Account deletion request error:', error.response?.data || error);
    throw error;
  }
};

export const sendSupportMessage = async (messageData) => {
  try {
    // Handle both simple message string and full message object
    let requestData = typeof messageData === 'string' 
      ? { message: messageData } 
      : messageData;
    
    const response = await api.post('/support/message/', requestData,
      {
        skipAuth: true
      }
    );
    return response.data;
  } catch (error) {
    console.error('Support message API error:', error.response?.data || error);
    throw error;
  }
};

export const getUserRelations = async () => {
  try {
    const response = await api.get('/user-relations/');
    return response.data;
  } catch (error) {
    console.error('User relations API error:', error.response?.data || error);
    throw error;
  }
};

export const sendContactRequest = async (receiverId) => {
  try {
    const response = await api.post('/contacts/request/', { receiver_id: receiverId });
    return response.data;
  } catch (error) {
    console.error('Send contact request API error:', error.response?.data || error);
    throw error;
  }
};

export const updateContactRequest = async (requestId, action) => {
  try {
    const status = action === 'accept' ? 'Accepted' : 'Declined';
    const response = await api.put(`/contacts/request/${requestId}/`, {
      status: status
    });
    return response.data;
  } catch (error) {
    console.error('Update contact request API error:', error.response?.data || error);
    throw error;
  }
};

// Function to block a user
export const blockUser = async (userId) => {
  try {
    const response = await api.post('/blocks/block/', { 
      user_id: userId 
    });
    return response.data;
  } catch (error) {
    console.error('Block user API error:', error.response?.data || error);
    throw error;
  }
};

// Function to unblock a user
export const unblockUser = async (userId) => {
  try {
    const response = await api.delete(`/blocks/unblock/${userId}/`);
    return response.data;
  } catch (error) {
    console.error('Unblock user API error:', error.response?.data || error);
    throw error;
  }
};
