import React from 'react';
import * as Label from '@radix-ui/react-label';
import * as HoverCard from '@radix-ui/react-hover-card';
import * as Dialog from '@radix-ui/react-dialog';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import * as Separator from '@radix-ui/react-separator';
import { cmToFeetInches } from '../utils';
import { UserCircle, Clock, User, Users, UserRound, Calendar, Ruler, Heart, 
  GraduationCap, Building2, DollarSign, FileText, Briefcase, Building, Home,
  Phone, MessageCircle, Star, Moon, Sun, MapPin, ImageOff } from 'lucide-react';

const profileThemes = {
  personal: {
    gradient: 'from-amber-50 via-yellow-50 to-amber-50',
    border: 'border-amber-200',
    iconBg: 'bg-amber-100',
    iconColor: 'text-amber-500',
    headerBg: 'bg-gradient-to-r from-amber-100 to-yellow-100',
    typography: {
      title: 'font-medium',
      label: 'font-medium',
      value: 'font-sans',
      helper: ''
    },
  },
  education: {
    gradient: 'from-violet-50 via-purple-50 to-violet-50',
    border: 'border-violet-200',
    iconBg: 'bg-violet-100',
    iconColor: 'text-violet-500',
    headerBg: 'bg-gradient-to-r from-violet-100 to-purple-100',
    typography: {
      title: 'font-medium',
      label: 'font-medium',
      value: 'font-sans',
      helper: ''
    },
  },
  professional: {
    gradient: 'from-emerald-50 via-teal-50 to-emerald-50',
    border: 'border-emerald-200',
    iconBg: 'bg-emerald-100',
    iconColor: 'text-emerald-500',
    headerBg: 'bg-gradient-to-r from-emerald-100 to-teal-100',
    typography: {
      title: 'font-medium',
      label: 'font-medium',
      value: 'font-sans',
      helper: ''
    },
  },
  horoscope: {
    gradient: 'from-orange-50 via-amber-50 to-orange-50',
    border: 'border-orange-200',
    iconBg: 'bg-orange-100',
    iconColor: 'text-orange-500',
    headerBg: 'bg-gradient-to-r from-orange-100 to-amber-100',
    typography: {
      title: 'font-medium',
      label: 'font-medium',
      value: 'font-sans',
      helper: ''
    },
  }
};

const ProfileField = ({ label, value, isHidden = false, type = 'personal', icon: Icon }) => {
  const theme = profileThemes[type];
  
  return (
    <div className="mb-3">
      <div className="grid grid-cols-[24px_100px_1fr] gap-2 items-center">
        {Icon && <Icon className="text-sky-400" size={16} />}
        <Label.Root className={`text-gray-600 text-sm ${theme.typography.label}`}>
          {label}:
        </Label.Root>
        {isHidden ? (
          <HoverCard.Root>
            <HoverCard.Trigger asChild>
              <div className={`text-gray-400 text-sm ${theme.typography.helper} cursor-help`}>
                [ Hidden. Visible to connections only ]
              </div>
            </HoverCard.Trigger>
            <HoverCard.Portal>
              <HoverCard.Content 
                className="bg-white p-3 rounded-lg shadow-lg border border-sky-100"
                sideOffset={5}
              >
                <p className="text-sm text-gray-600">This information will be visible after connecting.</p>
                <HoverCard.Arrow className="fill-white" />
              </HoverCard.Content>
            </HoverCard.Portal>
          </HoverCard.Root>
        ) : (
          <p className={`font-normal font-sans text-gray-700 break-words text-sm`}>{value}</p>
        )}
      </div>
      <Separator.Root className="h-px bg-gray-200 mt-3" decorative />
    </div>
  );
};

const ProfileSection = ({ title, icon: Icon, children, type = 'personal' }) => {
  const theme = profileThemes[type];
  
  return (
    <div className={`rounded-lg border-2 border-dotted border-gray-400 overflow-hidden transition-all duration-300`}>
      <div className={`bg-gradient-to-br ${theme.gradient}`}>
        <div className={`px-4 py-3 ${theme.headerBg}`}>
          <div className="flex items-center space-x-3">
            <div className={`p-2 rounded-lg ${theme.iconBg}`}>
              <Icon className={`h-4 w-4 ${theme.iconColor}`} />
            </div>
            <h2 className={`text-base text-gray-800 ${theme.typography.title}`}>{title}</h2>
          </div>
        </div>
      </div>
      <div className={`p-4 bg-gradient-to-br ${theme.gradient}`}>
        {children}
      </div>
    </div>
  );
};

const PersonalDetailsSection = ({ profile, isFull }) => (
  <div>
    <ProfileField label="User ID" value={profile?.user_id} icon={UserCircle} />
    <ProfileField label="Name" value={isFull ? `${profile?.first_name} ( ${profile?.family_name} )` : null} isHidden={!isFull} icon={User} />
    <ProfileField label="Community" value={profile?.community} icon={Users} />
    <ProfileField label="Gender" value={profile?.gender} icon={UserRound} />
    <ProfileField label="Age" value={`${profile?.age} years`} icon={Calendar} />
    <ProfileField label="Height" value={cmToFeetInches(profile?.height)} icon={Ruler} />
    <ProfileField label="Marital Status" value={profile?.marital_status} icon={Heart} />
  </div>
);

const DegreeCard = ({ title, degree, isHidden }) => (
  <div className="mb-3">
    <ProfileField label="Degree" value={degree.degree} type="education" icon={GraduationCap} />
    <ProfileField label="University" value={`${degree.university}, ${degree.location}`} type="education" isHidden={isHidden} icon={Building2} />
    <ProfileField label="Year Graduated" value={degree.year} type="education" isHidden={isHidden} icon={Calendar} />
  </div>
);

const EducationSection = ({ education, isFull, profile }) => {
  if (!education) {
    return <div className="text-sm">No education information available</div>;
  }

  return (
    <div>
      {(education?.highest_education === 'High school' || education?.highest_education === "PUC / 12th") ? (
        <div className="text-sm">Highest education: {education?.highest_education}</div>
      ) : (
        <>
          {education?.phd && education.phd.degree !== '' &&
            <DegreeCard degree={education.phd} isHidden={!isFull} />
          }
          {education?.masters && education.masters.degree !== '' &&
            <DegreeCard degree={education.masters} isHidden={!isFull} />
          }
          {education?.bachelors && education.bachelors.degree !== '' &&
            <DegreeCard degree={education.bachelors} isHidden={!isFull} />
          }
        </>
      )}
    </div>
  );
}

const ProfessionalSection = ({ professional, isFull }) => (
  <div>
    {professional?.currently_employed ? (
      <>
        <ProfileField label="Designation" value={professional.designation} icon={Briefcase} />
        <ProfileField label="Company" value={`${professional.company}, ${professional.work_city}`} isHidden={!isFull} icon={Building} />
        <ProfileField label="Annual Income" value={professional.annual_income} icon={DollarSign} />
      </>
    ) : (
      <ProfileField label="Details" value={professional?.employment_explanation} icon={FileText} />
    )}
  </div>
);

const FamilySection = ({ family, isFull }) => (
  <div>
    <div className="mb-4">
      {/* Father information with internal box */}
      <div className="border border-gray-200 rounded-lg p-3 mb-3 bg-gray-50">
        <Label.Root className="text-sm font-medium text-gray-500 mb-2 block">Father</Label.Root>
        <ProfileField label="Name" value={`${family?.father_name} ${family?.father_family}`} isHidden={!isFull} icon={User} />
        <ProfileField label="Occupation" value={family?.father_occupation} isHidden={!isFull} icon={Briefcase} />
      </div>
      
      {/* Mother information with internal box */}
      <div className="border border-gray-200 rounded-lg p-3 mb-3 bg-gray-50">
        <Label.Root className="text-sm font-medium text-gray-500 mb-2 block">Mother</Label.Root>
        <ProfileField label="Name and Thamane" value={`${family?.mother_name} ${family?.mother_family}`} isHidden={!isFull} icon={User} />
        <ProfileField label="Occupation" value={family?.mother_occupation} isHidden={!isFull} icon={Briefcase} />
      </div>
    </div>
    <div>
      <ProfileField label="Siblings" value={family?.siblings_count} icon={Users} />
      <ProfileField label="Family Assets" value={family?.assets} icon={Home} />
    </div>
  </div>
);

const ResidenceSection = ({ residence, isFull }) => (
  <div>
    <div>
      <ProfileField label="Location" value={`${residence?.residence_city}, ${residence?.residence_country}`} icon={MapPin} />
      {residence?.residence_details && (
        <ProfileField label="Additional Details" value={residence.residence_details} icon={FileText} />
      )}
    </div>
    <div>
      <ProfileField label="Parents' Residence" value={`${residence?.parents_city}, ${residence?.parents_country}`} isHidden={!isFull} icon={Home} />
    </div>
  </div>
);

const ContactSection = ({ contact, isFull }) => (
  <div>
    <ProfileField label="Phone" value={`${contact?.phone_number} (${contact?.phone_owner})`} isHidden={!isFull} icon={Phone} />
    <ProfileField label="WhatsApp" value={`${contact?.whatsapp_number} (${contact?.whatsapp_owner})`} isHidden={!isFull} icon={MessageCircle} />
  </div>
);
 
const HoroscopeSection = ({ profile }) => (
  <div>
    <ProfileField label="Gana" value={profile?.horoscope?.gana} type="horoscope" icon={Star} />
    <ProfileField label="Rashi" value={profile?.horoscope?.rashi} type="horoscope" icon={Moon} />
    <ProfileField label="Nakshatra" value={profile?.horoscope?.nakshatra} type="horoscope" icon={Sun} />
  </div>
);

const Err = ({visibility}) => {
  return (<div>
      <div>
        Photo visibility: {visibility
          ? " All registered members" 
          : " Connections only"}
      </div>
      <br />

    <div className="flex flex-col items-center justify-center py-6 bg-gray-50 rounded-lg border border-dashed border-gray-300">
      <ImageOff className="w-12 h-12 text-gray-400 mb-2" />
      <p className="text-gray-500 text-center">No photos available</p>
    </div>
  </div>
)};

const PhotosSection = ({ photos, shouldDisplay }) => {

  // Handle if photos is null or undefined
  if (!photos || !shouldDisplay) {
    return <Err visibility={photos?.public_visibility}/>
  }

  // Extract photo URLs from the photos object
  const photoUrls = [
    photos.photo_1,
    photos.photo_2,
    photos.photo_3
  ].filter(Boolean);

  if (photoUrls.length === 0) {
    return <Err visibility={photos?.public_visibility}/>
  }

  var baseUrl = window.location.origin;
  if (window.location.origin == "http://localhost:3000") {
    baseUrl = "http://localhost:8000";
  }

  return (
    <div>
    <div>
      Photo visibility: {photos?.public_visibility 
        ? " All registered members" 
        : " Connections only"}
    </div>
    <br />
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      {photoUrls.map((url, index) => (
        <Dialog.Root key={index}>
          <Dialog.Trigger asChild>
            <div className="relative cursor-pointer rounded-lg overflow-hidden shadow-sm border border-gray-300 group">
              <AspectRatio.Root ratio={4/5}>
                <div className="w-full h-full relative">
                  <img 
                    src={`${baseUrl}${url}`} 
                    alt={`Profile photo ${index + 1}`}
                    className="object-cover w-full h-full transition-transform group-hover:scale-105"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Crect width='100' height='100' fill='%23f0f0f0'/%3E%3Cpath d='M30,40 L70,40 L70,60 L30,60 Z' fill='%23cccccc'/%3E%3C/svg%3E";
                    }}
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity">
                    <div className="absolute bottom-2 left-2 text-white text-xs px-2 py-1 bg-black/30 rounded-md">
                      Photo {index + 1}
                    </div>
                  </div>
                </div>
              </AspectRatio.Root>
            </div>
          </Dialog.Trigger>
          <Dialog.Portal>
            <Dialog.Overlay className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50" />
            <Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-1 max-w-3xl w-[90vw] max-h-[90vh] z-50 overflow-hidden">
              <div className="relative w-full h-full">
                <img 
                  src={`${baseUrl}${url}`} 
                  alt={`Profile photo ${index + 1}`} 
                  className="w-full h-full object-contain"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Crect width='100' height='100' fill='%23f0f0f0'/%3E%3Cpath d='M30,40 L70,40 L70,60 L30,60 Z' fill='%23cccccc'/%3E%3C/svg%3E";
                  }}
                />
                <Dialog.Close className="absolute top-2 right-2 rounded-full w-8 h-8 flex items-center justify-center bg-white/90 hover:bg-white text-gray-800 hover:text-black transition-colors">
                  ×
                </Dialog.Close>
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      ))}
    </div>
    </div>
  );
};

export {
  PersonalDetailsSection,
  ProfileSection,
  ProfileField,
  FamilySection,
  EducationSection,
  ProfessionalSection,
  ResidenceSection,
  ContactSection,
  HoroscopeSection,
  PhotosSection,
};
