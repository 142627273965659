// src/components/auth/Register.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { register } from '../../services/api';
import { ArrowLeft, ArrowRight, AlertCircle, Loader, Check  } from 'lucide-react';
import { feetInchesToCm } from '../utils'
import { BasicInfo } from './steps/BasicInfo'
import { Horoscope } from './steps/Horoscope'
import { Family} from './steps/Family'
import { Residence} from './steps/Residence'
import { Education } from './steps/Education'
import { Professional } from './steps/Professional'
import { AboutMe } from './steps/AboutMe'
import { ContactInfo } from './steps/ContactInfo'
import { LoginInfo } from './steps/LoginInfo'

const Steps = {
  BASIC_INFO: 0,
  HOROSCOPE: 1,
  FAMILY: 2,
  RESIDENCE: 3,
  EDUCATION: 4,
  PROFESSIONAL: 5,
  ABOUT_ME: 6,
  CONTACT_INFO: 7,
  LOGIN_INFO: 8,
};

const Register = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(Steps.BASIC_INFO);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [identityFile, setIdentityFile] = useState(null);
  const [identityFileName, setIdentityFileName] = useState('');
  const [heightSelection, setHeightSelection] = useState({ feet: '', inches: '' });
  const [photos, setPhotos] = useState({
    photo1: null,
    photo2: null,
    photo3: null
  });

  const [formData, setFormData] = useState({
    // Basic info
    first_name: '',
    community: '',
    family_name: '',
    gender: '',
    height: '',
    marital_status: '',
    date_of_birth: '',
    profile_type: 'discreet', // Currently, unused
  
    // Horoscope
    gana: '',
    rashi: '',
    nakshatra: '',

    // Family
    father_name: '',
    father_family: '',
    father_occupation: '',
    mother_name: '',
    mother_family: '',
    mother_occupation: '',
    siblings_count: '',
    assets: '',

    // Residence
    residence_country: '',
    residence_city: '',
    parents_city: '',
    parents_country: '',
    residence_details: '',

    // Education
    highest_education: '',
    bachelors_degree: '',
    bachelors_university: '',
    bachelors_location: '',
    bachelors_year: '',
    masters_degree: '',
    masters_university: '',
    masters_location: '',
    masters_year: '',
    phd_degree: '',
    phd_university: '',
    phd_location: '',
    phd_year: '',

    // Professional
    currently_unemployed: false,
    employment_explanation: '',
    designation: '',
    company: '',
    work_city: '',
    annual_income: '',

    // About me
    about_me: '',
    photo_url_1: '',
    photo_url_2: '',
    photo_url_3: '',
    public_visibility: true,

    // Contact Info
    whatsapp_number: '',
    whatsapp_owner: '',
    phone_owner: '',
    phone_number: '',

    // Login info
    email: '',
    password: '',
    confirm_password: '',

    photo_validation: {
      isMainPhotoValid: false
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const handleHeightChange = (e) => {
    if (!e.target.value) {
      setHeightSelection({ feet: '', inches: '' });
      handleChange({
        target: {
          name: 'height',
          value: ''
        }
      });
      return;
    }

    const [feet, inches] = e.target.value.split('-');
    const newHeight = {
      feet: parseInt(feet),
      inches: parseInt(inches)
    };
    setHeightSelection(newHeight);
    const cm = feetInchesToCm(newHeight.feet, newHeight.inches);
    handleChange({
      target: {
        name: 'height',
        value: cm
      }
    });
  };

  const handleIdentityFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIdentityFile(file);
      setIdentityFileName(file.name);
    }
  };

  const handleRemoveFile = () => {
    setIdentityFile(null);
    setIdentityFileName('');
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const validateStep = () => {
    setError(null);
    
    switch (currentStep) {
      case Steps.BASIC_INFO:
        const basicInfoValidations = {
          first_name: formData.first_name && formData.first_name.length >= 2,
          family_name: formData.family_name && formData.family_name.length >= 2,
          community: formData.community && formData.community !== '',
          gender: formData.gender && formData.gender !== '',
          height: formData.height >= 100 && formData.height <= 250,
          marital_status: formData.marital_status && formData.marital_status !== '',
          identity_proof: identityFile !== null
        };
  
        if (!Object.values(basicInfoValidations).every(Boolean)) {
          setError('Please complete all required fields');
          return false;
        }
        break;
  
      case Steps.HOROSCOPE:
        const horoscopeValidations = {
          gana: formData.gana && formData.gana !== '',
          rashi: formData.rashi && formData.rashi !== '',
          nakshatra: formData.nakshatra && formData.nakshatra !== ''
        };
  
        if (!Object.values(horoscopeValidations).every(Boolean)) {
          setError('Please complete all required fields');
          return false;
        }
        break;
  
      case Steps.FAMILY:
        const familyValidations = {
          father_name: formData.father_name && formData.father_name.length >=2,
          father_family: formData.father_family && formData.father_family.length >=2,
          mother_name: formData.mother_name && formData.mother_name.length >=2,
          mother_family: formData.mother_family && formData.mother_family.length >=2,
          siblings_count: formData.siblings_count !== '',
        };
  
        if (!Object.values(familyValidations).every(Boolean)) {
          setError('Please complete all required fields');
          return false;
        }
        break;
  
      case Steps.RESIDENCE:
        const residenceValidations = {
          residence_country: formData.residence_country && formData.residence_country !== '',
          residence_city: formData.residence_city && formData.residence_city.length >= 2,
          parents_country: formData.parents_country && formData.parents_country !== '',
          parents_city: formData.parents_city && formData.parents_city.length >= 2,
        };
  
        if (!Object.values(residenceValidations).every(Boolean)) {
          setError('Please complete all required fields');
          return false;
        }
        break;
  
        case Steps.EDUCATION:
          const hasBachelors = ['Bachelors / Diploma', 'Masters', 'Phd'].includes(formData.highest_education);
          const hasMasters = ['Masters', 'Phd'].includes(formData.highest_education);
          const hasPhd = formData.highest_education === 'Phd';
        
          const educationValidations = {
            // Always validate highest education
            highest_education: formData.highest_education && formData.highest_education !== '',
            
            // Bachelor's validations (required for Bachelors, Masters, and PhD)
            bachelors_degree: hasBachelors ? (formData.bachelors_degree && formData.bachelors_degree !== '') : true,
            bachelors_university: hasBachelors ? (formData.bachelors_university && formData.bachelors_university !== '') : true,
            bachelors_location: hasBachelors ? (formData.bachelors_location && formData.bachelors_location !== '') : true,
            bachelors_year: hasBachelors ? (formData.bachelors_year && formData.bachelors_year !== '') : true,

            // Master's validations (required for Masters and PhD)
            masters_degree: hasMasters ? (formData.masters_degree && formData.masters_degree !== '') : true,
            masters_university: hasMasters ? (formData.masters_university && formData.masters_university !== '') : true,
            masters_location: hasMasters ? (formData.masters_location && formData.masters_location !== '') : true,
            masters_year: hasMasters ? (formData.masters_year && formData.masters_year !== '') : true,

            // PhD validations (required only for PhD)
            phd_degree: hasPhd ? (formData.phd_degree && formData.phd_degree !== '') : true,
            phd_university: hasPhd ? (formData.phd_university && formData.phd_university !== '') : true,
            phd_location: hasPhd ? (formData.phd_location && formData.phd_location !== '') : true,
            phd_year: hasPhd ? (formData.phd_year && formData.phd_year !== '') : true,
          };
        
          if (!Object.values(educationValidations).every(Boolean)) {
            setError('Please complete all required fields');
            return false;
          }
        
          // Additional validation: Year validation
          if (hasBachelors && formData.bachelors_year) {
            const bYear = parseInt(formData.bachelors_year);
            if (isNaN(bYear) || bYear < 1980 || bYear > new Date().getFullYear()+5) {
              setError('Please enter a valid Bachelor\'s completion year');
              return false;
            }
          }
        
          if (hasMasters && formData.masters_year) {
            const mYear = parseInt(formData.masters_year);
            if (isNaN(mYear) || mYear < 1980 || mYear > new Date().getFullYear()+5) {
              setError('Please enter a valid Master\'s completion year');
              return false;
            }
          }
        
          if (hasPhd && formData.phd_year) {
            const pYear = parseInt(formData.phd_year);
            if (isNaN(pYear) || pYear < 1980 || pYear > new Date().getFullYear()+5) {
              setError('Please enter a valid PhD completion year');
              return false;
            }
          }
        
          break;
  
      case Steps.PROFESSIONAL:
        const professionalValidations = {
          designation: !formData.currently_unemployed ? (formData.designation && formData.designation !== '') : true,
          company: !formData.currently_unemployed ? (formData.company && formData.company !== '') : true,
          work_city: !formData.currently_unemployed ? (formData.work_city && formData.work_city !== '') : true,
          annual_income: !formData.currently_unemployed ? (formData.annual_income && formData.annual_income !== '') : true,
          employment_explanation: formData.currently_unemployed ? (formData.employment_explanation && formData.employment_explanation !== '') : true
        };
  
        if (!Object.values(professionalValidations).every(Boolean)) {
          setError('Please complete all required fields');
          return false;
        }
        break;
        case Steps.ABOUT_ME:
          const aboutMeValidations = {
            // Bio validation - must be at least 50 characters
            about_me: formData.about_me && formData.about_me.trim().length >= 50,
            
            // Profile visibility choice validation (must be explicitly set)
            public_visibility: typeof formData.public_visibility === 'boolean',
            main_photo: formData.photo_validation.isMainPhotoValid,
          };
        
          // Check if any validation fails
          if (!Object.values(aboutMeValidations).every(Boolean)) {
            if (!aboutMeValidations.about_me) {
              setError('Please write at least 50 characters in your bio');
              return false;
            }
            if (!aboutMeValidations.public_visibility) {
              setError('Please choose your photo privacy settings');
              return false;
            }
            if (!aboutMeValidations.main_photo) {
              setError('Please upload at least one profile photo');
              return false;
            }
            return false;
          }
        
          // Additional validation for bio content
          if (formData.about_me.trim().length > 1000) {
            setError('Bio should not exceed 1000 characters');
            return false;
          }
        
          break;  
          case Steps.CONTACT_INFO:
            const contactValidations = {
              // WhatsApp validation
              whatsapp_number: formData.whatsapp_number && formData.whatsapp_number.length >= 8,
              whatsapp_owner: formData.whatsapp_owner && formData.whatsapp_owner !== '',
              
              // Phone validation
              phone_number: formData.phone_number && formData.phone_number.length >= 8,
              phone_owner: formData.phone_owner && formData.phone_owner !== '',
            };
          
            // Specific validation messages for each filled contact method
            if (formData.whatsapp_number || formData.whatsapp_owner) {
              if (!contactValidations.whatsapp_number) {
                setError('Please enter a valid WhatsApp number including country code');
                return false;
              }
              if (!contactValidations.whatsapp_owner) {
                setError('Please select the relationship for WhatsApp contact');
                return false;
              }
            }
          
            if (formData.phone_number || formData.phone_owner) {
              if (!contactValidations.phone_number) {
                setError('Please enter a valid phone number including country code');
                return false;
              }
              if (!contactValidations.phone_owner) {
                setError('Please select the relationship for phone contact');
                return false;
              }
            }

            // Ensure at least one contact method is provided
            if ((!formData.whatsapp_number || !formData.whatsapp_owner) && 
            (!formData.phone_number || !formData.phone_owner)) {
              setError('Please provide at least one complete contact method (phone or WhatsApp)');
              return false;
            }
          
            break;
  
      case Steps.LOGIN_INFO:
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const loginValidations = {
          email: formData.email && emailRegex.test(formData.email),
          password: formData.password && formData.password.length >= 6,
          password_match: formData.password === formData.confirm_password
        };
  
        if (!loginValidations.email || !loginValidations.password) {
          setError('Please enter a valid email and password (minimum 6 characters)');
          return false;
        }
  
        if (!loginValidations.password_match) {
          setError('Passwords do not match');
          return false;
        }
        break;
    }
    
    return true;
  };

  const handleNext = () => {
    if (validateStep()) {
      setError(null);
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleBack = () => {
    setError(null);
    setCurrentStep(prev => prev - 1);
  };

  const setParentPhotos = (photoData) => {
    // Keep photo objects for submission
    setPhotos({
      photo1: photoData.photo1,
      photo2: photoData.photo2,
      photo3: photoData.photo3
    });
    
    // Track validation status separately
    setFormData(prev => ({
      ...prev,
      photo_validation: {
        isMainPhotoValid: photoData.isMainPhotoValid
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep()) return;
  
    // Add explicit check for required photo
    if (!photos.photo1) {
      setError('A main profile photo is required');
      return;
    }

    setLoading(true);
    setError(null);
  
    try {
      // Create FormData object for file uploads
      const apiFormData = new FormData();
      
      // IMPORTANT: The key insight is that we need to send the core data as
      // STRING JSON, not as objects. This is crucial for Django to process it properly.
      
      // Create data objects WITHOUT file references
      const userData = {
        email: formData.email,
        password: formData.password,
        first_name: formData.first_name,
        family_name: formData.family_name,
        community: formData.community,
        gender: formData.gender,
        marital_status: formData.marital_status,
        height: parseInt(formData.height),
        date_of_birth: formData.date_of_birth,
        gana: formData.gana,
        rashi: formData.rashi,
        nakshatra: formData.nakshatra,
        phone_owner: formData.phone_owner,
        phone_number: formData.phone_number,
        whatsapp_owner: formData.whatsapp_owner,
        whatsapp_number: formData.whatsapp_number,
        about_me: formData.about_me || "Brief description"
        // NO identity_proof here - that will be added by the Django view
      };
      
      const educationData = {
        highest_education: formData.highest_education,
        bachelors_degree: formData.bachelors_degree || '',
        bachelors_university: formData.bachelors_university || '',
        bachelors_location: formData.bachelors_location || '',
        bachelors_year: formData.bachelors_year ? parseInt(formData.bachelors_year) : null,
        masters_degree: formData.masters_degree || '',
        masters_university: formData.masters_university || '',
        masters_location: formData.masters_location || '',
        masters_year: formData.masters_year ? parseInt(formData.masters_year) : null,
        phd_degree: formData.phd_degree || '',
        phd_university: formData.phd_university || '',
        phd_location: formData.phd_location || '',
        phd_year: formData.phd_year ? parseInt(formData.phd_year) : null
      };
      
      const familyData = {
        father_family: formData.father_family,
        father_name: formData.father_name,
        father_occupation: formData.father_occupation,
        mother_name: formData.mother_name,
        mother_family: formData.mother_family,
        mother_occupation: formData.mother_occupation,
        siblings_count: formData.siblings_count ? parseInt(formData.siblings_count) : 0,
        assets: formData.assets || ''
      };
      
      const residenceData = {
        residence_country: formData.residence_country,
        residence_city: formData.residence_city,
        parents_city: formData.parents_city,
        parents_country: formData.parents_country,
        residence_details: formData.residence_details || ''
      };
      
      const professionalData = {
        currently_employed: !formData.currently_unemployed,
        employment_explanation: formData.employment_explanation || '',
        designation: !formData.currently_unemployed ? formData.designation : '',
        company: !formData.currently_unemployed ? formData.company : '',
        work_city: !formData.currently_unemployed ? formData.work_city : '',
        annual_income: !formData.currently_unemployed ? formData.annual_income : ''
      };
      
      const photosData = {
        public_visibility: formData.public_visibility
        // NO photo files here - they will be added by the Django view
      };
      
      // Now add all data as STRING JSON (this is critical)
      apiFormData.append('user', JSON.stringify(userData));
      apiFormData.append('education', JSON.stringify(educationData));
      apiFormData.append('family', JSON.stringify(familyData));
      apiFormData.append('residence', JSON.stringify(residenceData));
      apiFormData.append('professional', JSON.stringify(professionalData));
      apiFormData.append('photos', JSON.stringify(photosData));
      
      // Add files separately - this is how the Django view expects it
      if (identityFile) {
        apiFormData.append('identity_proof', identityFile);
      }
      
      if (photos.photo1) {
        apiFormData.append('photo_1', photos.photo1);
      }
      if (photos.photo2) {
        apiFormData.append('photo_2', photos.photo2);
      }
      if (photos.photo3) {
        apiFormData.append('photo_3', photos.photo3);
      }
      
      console.log("Sending registration data with the following structure:");
      for (let [key, value] of apiFormData.entries()) {
        if (value instanceof File) {
          console.log(`${key}: File - ${value.name}`);
        } else {
          console.log(`${key}: ${value.substring(0, 50)}${value.length > 50 ? '...' : ''}`);
        }
      }
  
      const response = await register(apiFormData);
      if (response.status === 'success') {
        setSuccess(response.message || 'Registration successful! Please wait for the admin to verify and approve your account. You can login with your credentials after that.');
        // Optional: Add a timeout to redirect after showing the message
        setTimeout(() => {
          navigate('/login');
        }, 30000); // Redirect after 30 seconds
      } else {
        setError(response.message || 'Registration failed');
      }
    } catch (err) {
      console.error('Registration error:', err);
      if (err.response?.data?.errors) {
        // Format the error messages for better user readability
        const errorMessages = Object.entries(err.response.data.errors)
          .map(([field, messages]) => `${field}: ${messages.join(', ')}`)
          .join('\n');
        setError(errorMessages || 'Registration failed');
      } else {
        setError(err.response?.data?.message || err.message || 'Registration failed');
      }
    } finally {
      setLoading(false);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case Steps.BASIC_INFO:
        return (
          <BasicInfo
            formData={formData}
            handleChange={handleChange}
            heightSelection={heightSelection}
            handleHeightChange={handleHeightChange}
            identityFile={identityFile}
            handleFileChange={handleIdentityFileChange}
            selectedFileName={identityFileName}
            handleRemoveFile={handleRemoveFile}
          />
        );
      case Steps.HOROSCOPE:
        return <Horoscope
          formData={formData}
          handleChange={handleChange}
          />;
      case Steps.FAMILY:
        return <Family
          formData={formData}
          handleChange={handleChange}
          />;
      case Steps.RESIDENCE:
        return <Residence
          formData={formData}
          handleChange={handleChange}
          />;
      case Steps.EDUCATION:
        return <Education
          formData={formData}
          handleChange={handleChange}
          />;
      case Steps.PROFESSIONAL:
        return <Professional
          formData={formData}
          handleChange={handleChange}
          />;
      case Steps.ABOUT_ME:
        return <AboutMe
          formData={formData}
          handleChange={handleChange}
          setParentPhotos={setParentPhotos}
          parentPhotos={photos}
          />;
      case Steps.CONTACT_INFO:
        return <ContactInfo
          formData={formData}
          handleChange={handleChange}
          />; 
      case Steps.LOGIN_INFO: 
        return <LoginInfo
        formData={formData}
        handleChange={handleChange}
        />; 
      default:
        return null;
    }
  };

  const getStepTitle = () => {
    switch (currentStep) {
      case Steps.BASIC_INFO:
        return "Basic Information";
      case Steps.HOROSCOPE:
        return "Horoscope";
      case Steps.FAMILY:
        return "Family Information";
      case Steps.RESIDENCE:
        return "Residence Details";
      case Steps.EDUCATION:
        return "Education";
      case Steps.PROFESSIONAL:
        return "Professional Information";
      case Steps.ABOUT_ME:
        return "Photos and About Me";
      case Steps.CONTACT_INFO:
        return "Contact Information";
      case Steps.LOGIN_INFO:
        return "Login Information";
      default:
        return "";
    }
  };

  // Simple decorative background shape positions
  const bgCirclePositions = [
    { top: '10%', right: '5%', width: '250px', height: '250px', bg: 'bg-sky-200' },
    { bottom: '10%', left: '5%', width: '200px', height: '200px', bg: 'bg-blue-200' }
  ];

  return (
    <div className="">
      {/* Decorative background shapes */}
      {bgCirclePositions.map((pos, idx) => (
        <div 
          key={idx}
          className={`absolute rounded-full opacity-20 ${pos.bg}`}
          style={{ 
            top: pos.top || 'auto', 
            right: pos.right || 'auto', 
            bottom: pos.bottom || 'auto',
            left: pos.left || 'auto',
            width: pos.width,
            height: pos.height,
            zIndex: 0
          }}
        ></div>
      ))}
      
      <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8 relative z-10">
        {/* Header Section */}
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold bg-gradient-to-r from-sky-600 to-indigo-600 bg-clip-text text-transparent">
            Create Your Profile
          </h1>
          <div className="mt-3 flex items-center justify-center">
            <span className="px-4 py-1.5 rounded-full bg-gradient-to-r from-sky-50 to-indigo-50 text-sky-700 text-sm font-medium border border-sky-200 shadow-sm">
              Step {currentStep + 1} of 9: {getStepTitle()}
            </span>
          </div>
        </div>
  
        {/* Progress Bar */}
        <div className="w-full bg-gray-200 rounded-full h-2.5 mb-8 overflow-hidden shadow-inner">
          <div 
            className="bg-gradient-to-r from-sky-400 to-indigo-500 h-2.5 rounded-full transition-all duration-300"
            style={{ width: `${((currentStep + 1) / 9) * 100}%` }}
          ></div>
        </div>
  
        {/* Main Form Container */}
        <div className="rounded-xl bg-transparent border-solid border-4 border-blue-100 p-4 relative overflow-hidden">
                
            {/* Subtle background and inner container */}
            <div className="bg-blue-50 bg-opacity-30 rounded-lg p-8">
              <form onSubmit={(e) => e.preventDefault()}>
                {/* Dynamic Step Content */}
                {renderStepContent()}

                {/* Error Display */}
                {error && (
                  <div className="mt-6 p-4 rounded-lg bg-red-50 border border-red-200 shadow-sm">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <AlertCircle className="h-5 w-5 text-red-400" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-red-800 font-serif">{error}</p>
                      </div>
                    </div>
                  </div>
                )}
                {/* Success Display */}
                {success && (
                  <div className="mt-6 p-4 rounded-lg bg-green-50 border border-green-200 shadow-sm">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <Check className="h-5 w-5 text-green-400" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-green-800 font-serif">{success}</p>
                      </div>
                    </div>
                  </div>
                )}
                {/* Navigation Buttons */}
                <div className="mt-8 flex justify-between items-center pt-5 border-t border-blue-100">
                  <div>
                    {currentStep > 0 && (
                      <button
                        type="button"
                        onClick={handleBack}
                        className="inline-flex items-center px-6 py-3 border-2 border-blue-200 shadow-sm text-base font-medium rounded-lg text-blue-700 bg-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 transition-colors font-serif"
                      >
                        <ArrowLeft className="h-5 w-5 mr-2" />
                        Back
                      </button>
                    )}
                  </div>
                  <div>
                    {currentStep < Object.keys(Steps).length - 1 ? (
                      <button
                        type="button"
                        onClick={handleNext}
                        className="inline-flex items-center px-6 py-3 border-2 border-blue-300 text-base font-medium rounded-lg text-white bg-gradient-to-r from-blue-400 to-sky-400 hover:from-blue-500 hover:to-sky-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 shadow-sm hover:shadow-md transition-all font-serif"
                      >
                        Next
                        <ArrowRight className="h-5 w-5 ml-2" />
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={handleSubmit}
                        disabled={loading}
                        className="inline-flex items-center px-6 py-3 border-2 border-blue-300 text-base font-medium rounded-lg text-white bg-gradient-to-r from-blue-400 to-sky-400 hover:from-blue-500 hover:to-sky-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 shadow-sm hover:shadow-md transition-all disabled:opacity-50 disabled:cursor-not-allowed font-serif"
                      >
                        {loading ? (
                          <>
                            <Loader className="animate-spin h-5 w-5 mr-2" />
                            Creating Account...
                          </>
                        ) : (
                          <>
                            Create Account
                          </>
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
  
        {/* Helper Text */}
        <div className="mt-6 text-center">
          <p className="text-sm text-gray-600">
            Already have an account?{' '}
            <Link 
              to="/login" 
              className="font-medium text-sky-600 hover:text-sky-500 transition-colors"
            >
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;