import React, { useState, useEffect } from 'react';
import { UserCircle2, GraduationCap, Mail, Briefcase, Home, Phone, Calendar, Users, Images, Eye, EyeOff, MessageCircle, UserCog } from 'lucide-react';
import { getMyProfile } from '../../services/api';
import * as Tabs from '@radix-ui/react-tabs';
import { ErrorMessage } from '../components';
import ProfileControls from './ProfileControls';
import { SUPPORT_EMAIL } from '../constants';

import {
  ProfileSection,
  EducationSection,
  ProfessionalSection,
  ContactSection,
  HoroscopeSection,
  PersonalDetailsSection,
  ResidenceSection,
  FamilySection,
  PhotosSection
} from './Components';

const TabButton = ({ value, icon: Icon, children }) => (
  <Tabs.Trigger 
    value={value}
    className="flex items-center gap-2 px-4 py-2 rounded-md transition-all duration-200 text-gray-500 hover:text-gray-700 whitespace-nowrap data-[state=active]:bg-white data-[state=active]:text-sky-600 data-[state=active]:shadow-sm"
  >
    <Icon className="w-4 h-4" />
    <span className="text-sm font-medium">{children}</span>
  </Tabs.Trigger>
);

const MyProfile = () => {
  const [activeTab, setActiveTab] = useState('full');
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMyProfile = async () => {
      try {
        const response = await getMyProfile();
        setProfileData(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to fetch profile.');
        setLoading(false);
      }
    };

    fetchMyProfile();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-sky-500 border-t-transparent"/>
      </div>
    );
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const profile = profileData || {};
  const isFull = activeTab === 'full';

  return (
    <div className="container mx-auto px-4 py-4 min-h-screen">
      <div className="mb-6">
        <h1 className="text-2xl font-bold bg-gradient-to-r from-sky-600 to-indigo-600 bg-clip-text text-transparent flex items-center gap-3">
          <UserCog className="w-7 h-7 text-sky-500" />
          My Profile
        </h1>
      </div>

      <Tabs.Root value={activeTab} onValueChange={setActiveTab} className="flex flex-col">
        <div className="bg-white rounded-t-lg border border-b-0 border-gray-300 p-1 bg-gradient-to-b from-sky-50 to-white">
          <Tabs.List className="flex gap-1">
            <TabButton value="full" icon={Eye}>
              Full Profile
            </TabButton>
            <TabButton value="partial" icon={EyeOff}>
              Public View
            </TabButton>
          </Tabs.List>
        </div>

        <div className="border-x border-b border-gray-300 bg-sky-50/50 p-4 mb-4 text-gray-600 rounded-b-lg">
          <Tabs.Content value="full">
            <p className="text-sm flex items-center gap-2">
              <span className="italic text-sky-500">You are currently viewing your complete profile, which displays all details. This is the view that you and your connections will see. To change or update any of the information below, please use the support tab or email us at <span class="text-blue-400"> {SUPPORT_EMAIL}</span></span>
            </p>
          </Tabs.Content>
          <Tabs.Content value="partial">
            <p className="text-sm flex items-center gap-2">
              <span className="italic text-sky-500">This preview shows how your profile looks to other users. Certain fields are hidden initially. Once you match, more information will be revealed. To access contact details, send a contact request and wait for acceptance.</span>
            </p>
          </Tabs.Content>
        </div>

        {/* First row: Basic Information and a split section for control buttons and contact */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div>
            <ProfileSection title="Basic Information" icon={UserCircle2} type="education">
              <PersonalDetailsSection profile={profile} isFull={isFull} />
            </ProfileSection>
          </div>
          
          <div className="grid grid-cols-1 gap-4">
            {/* Control buttons section */}
            <ProfileControls
              profile={profile}
              profileType="self"
              connection={null}
              contactRequest={null}
            />
            
            {/* Contact information section */}
            <div>
              <ProfileSection title="Contact Information" icon={Phone} type="personal">
                <ContactSection contact={profile?.contact} isFull={isFull} />
              </ProfileSection>
            </div>
          </div>
        </div>
        
        {/* Second row: Photos section spanning the full width */}
        <div className="mb-4">
          <ProfileSection title="Photos" icon={Images} type="professional">
            <PhotosSection photos={profile.photos} shouldDisplay={isFull || profile.photos.public_visibility}/>
          </ProfileSection>
        </div>

        {/* Third row: About Me section spanning the full width */}
        <div className="mb-4">
          <ProfileSection title="About Me" icon={UserCircle2} type="horoscope">
            {profile.about_me || 'Add a description about yourself to help others know you better.'}
          </ProfileSection>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Left Column */}
          <div className="flex flex-col gap-4">            
            <ProfileSection title="Family" icon={Users} type="education">
              <FamilySection family={profile?.family} isFull={isFull} />
            </ProfileSection>

            <ProfileSection title="Education" icon={GraduationCap} type="education">
              <EducationSection education={profile?.education} isFull={isFull} />
            </ProfileSection>
          </div>
          
          {/* Right Column */}
          <div className="flex flex-col gap-4">
            <ProfileSection title="Horoscope" icon={Calendar} type="education">
              <HoroscopeSection profile={profile} />
            </ProfileSection>

            <ProfileSection title="Residence" icon={Home} type="education">
              <ResidenceSection residence={profile?.residence} isFull={isFull} />
            </ProfileSection>
            
            <ProfileSection title="Professional" icon={Briefcase} type="education">
              <ProfessionalSection professional={profile?.professional} isFull={isFull} />
            </ProfileSection>
          </div>
      </div>
      </Tabs.Root>
    </div>
  );
};

export default MyProfile;