// src/components/auth/ResetPassword.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Lock, Eye, EyeOff, AlertCircle, Check, ArrowLeft } from 'lucide-react';
import { resetPasswordWithToken } from '../../services/api';

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  // Get token and email from URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const emailFromUrl = queryParams.get('email');
  
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(emailFromUrl || '');
  
  // Redirect if no token is provided
  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);
  
  // Password validation
  const [passwordValid, setPasswordValid] = useState({
    length: false,
  });
  
  // Update validation state as user types
  useEffect(() => {
    setPasswordValid({
      length: newPassword.length >= 6,
    });
  }, [newPassword]);
  
  // Check if all validations pass
  const allValidationsPassed = Object.values(passwordValid).every(validation => validation);
  
  // Check if passwords match
  const passwordsMatch = newPassword && confirmPassword && newPassword === confirmPassword;
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate input
    if (!allValidationsPassed) {
      setError('Please ensure your password meets all requirements.');
      return;
    }
    
    if (!passwordsMatch) {
      setError('Passwords do not match.');
      return;
    }
    
    if (!email) {
      setError('Email is required to reset your password.');
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      const response = await resetPasswordWithToken(token, email, newPassword);      
      if (response.status === 'success') {
        setSuccess(true);
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } else {
        setError(response.data.message || 'An error occurred. Please try again.');
      }
    } catch (err) {
      console.log(err);
      setError(err.response?.data?.message || 'Invalid or expired token. Please request a new password reset.');
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="max-w-md mx-auto relative min-h-[60vh] flex items-center justify-center">
      {/* Decorative elements remain unchanged */}
      <div className="absolute top-20 right-10 w-64 h-64 bg-blue-100 rounded-full opacity-20 animate-pulse" style={{ animationDuration: '8s', zIndex: -1 }}></div>
      <div className="absolute bottom-20 left-10 w-64 h-64 bg-indigo-100 rounded-full opacity-20 animate-pulse" style={{ animationDuration: '10s', zIndex: -1 }}></div>
      
      {/* Decorative pattern - top */}
      <div className="absolute -top-10 left-0 right-0 h-16 overflow-hidden">
        <svg className="absolute h-32 w-full" preserveAspectRatio="none" viewBox="0 0 1200 120" xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.05 }}>
          <path d="M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18.19 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z" fill="#4299E1" />
        </svg>
      </div>
      
      {/* Main container with dotted border */}
      <div className="rounded-xl bg-transparent border-solid border-4 border-blue-100 p-4 relative overflow-hidden w-full">
        {/* Inner content with subtle background */}
        <div className="bg-blue-50 bg-opacity-30 rounded-lg p-6">
          <div className="text-center space-y-2">
            <div className="inline-flex items-center justify-center mb-2">
              <div className="w-12 h-12 relative">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-300 to-sky-400 rounded-full opacity-30 animate-ping" style={{ animationDuration: '3s' }}></div>
                <div className="relative flex items-center justify-center w-full h-full bg-gradient-to-r from-blue-400 to-sky-400 rounded-full">
                  <Lock className="h-6 w-6 text-white" />
                </div>
              </div>
            </div>
            <h2 className="text-2xl font-bold text-blue-500 font-serif">Reset Password</h2>
            <p className="text-gray-600 font-serif text-sm">
              Please set your new password below
            </p>
            
            <div className="mt-2 inline-flex items-center justify-center px-3 py-1 rounded-full bg-blue-50 border border-blue-100">
              <Check className="h-3 w-3 text-blue-500 mr-1" />
              <span className="text-blue-700 font-medium text-xs">Secure Your Account</span>
            </div>
          </div>
          
          {success ? (
            <div className="mt-6 p-4 rounded-lg bg-green-50 border border-green-200">
              <div className="flex">
                <div className="flex-shrink-0">
                  <Check className="h-5 w-5 text-green-400" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-green-800 font-serif">
                    Password reset successful! Redirecting to login page...
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4 mt-6">
              {/* Email field - now disabled and prepopulated */}
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700 font-serif">Email Address</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                  </div>
                  <input 
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full pl-10 pr-12 py-2 bg-gray-50 border-2 border-blue-100 rounded-lg focus:ring-2 focus:ring-blue-300 focus:border-blue-300 transition-all font-serif disabled:opacity-80 disabled:cursor-not-allowed"
                    placeholder="Your email"
                    required
                    disabled={!!emailFromUrl} // Disable if email is provided in URL
                    readOnly={!!emailFromUrl} // Make it read-only for better accessibility
                  />
                </div>
                {emailFromUrl && (
                  <p className="text-xs text-gray-500 italic">Email address is linked to your reset token and cannot be changed.</p>
                )}
              </div>
              
              {/* Rest of the form remains unchanged */}
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700 font-serif">New Password</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Lock className="text-blue-400 h-5 w-5" />
                  </div>
                  <input 
                    type={showPassword ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="w-full pl-10 pr-12 py-2 bg-white border-2 border-blue-100 rounded-lg focus:ring-2 focus:ring-blue-300 focus:border-blue-300 transition-all font-serif"
                    placeholder="Enter new password"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-blue-600 transition-colors"
                  >
                    {showPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                  </button>
                </div>
                
                {/* Password strength indicators */}
                <div className="grid grid-cols-2 gap-2 mt-2">
                  <div className={`flex items-center text-xs ${passwordValid.length ? 'text-green-600' : 'text-gray-500'}`}>
                    <div className={`w-4 h-4 mr-2 rounded-full flex items-center justify-center ${passwordValid.length ? 'bg-green-100' : 'bg-gray-100'}`}>
                      {passwordValid.length ? <Check className="h-3 w-3" /> : ''}
                    </div>
                    At least 6 characters
                  </div>
                </div>
              </div>
              
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700 font-serif">Confirm Password</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Lock className="text-blue-400 h-5 w-5" />
                  </div>
                  <input 
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className={`w-full pl-10 pr-12 py-2 bg-white border-2 ${
                      confirmPassword ? (passwordsMatch ? 'border-green-200' : 'border-red-200') : 'border-blue-100'
                    } rounded-lg focus:ring-2 focus:ring-blue-300 focus:border-blue-300 transition-all font-serif`}
                    placeholder="Confirm new password"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-blue-600 transition-colors"
                  >
                    {showConfirmPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                  </button>
                </div>
                
                {confirmPassword && !passwordsMatch && (
                  <p className="text-xs text-red-600 mt-1">Passwords do not match</p>
                )}
              </div>

              {error && (
                <div className="p-4 rounded-lg bg-red-50 border border-red-200">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <AlertCircle className="h-5 w-5 text-red-400" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-800 font-serif">{error}</p>
                    </div>
                  </div>
                </div>
              )}

              <button
                type="submit"
                disabled={loading || !allValidationsPassed || !passwordsMatch || !email}
                className="w-full py-2 px-4 bg-gradient-to-r from-blue-400 to-sky-400 hover:from-blue-500 hover:to-sky-500 text-white font-medium rounded-lg shadow-sm hover:shadow-md transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed font-serif border-2 border-blue-300"
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Resetting password...
                  </div>
                ) : 'Reset Password'}
              </button>
              
              <div className="text-center">
                <button
                  type="button"
                  onClick={() => navigate('/login')}
                  className="inline-flex items-center text-sm text-blue-500 hover:text-blue-600 font-medium transition-colors font-serif"
                >
                  <ArrowLeft className="h-4 w-4 mr-1" />
                  Back to login
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;