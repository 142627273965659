import React, { useState, useEffect, useRef } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { 
  UserCircle2, 
  Users, 
  Search, 
  Heart, 
  Settings, 
  LogOut,
  Menu,
  X,
  XCircle,
  MessageCircle,
  ChevronDown,
  User,
  Sparkles,
  Shield,
  Mail,
} from 'lucide-react';
import { logoutApi } from '../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../auth/authSlice';
import { useRelations } from './RelationsContext'; // Import the context hook

const Header = ({ dispatch, navigate, refreshToken, userId, firstName  }) => {
  // Header component code remains unchanged
  const [showUserMenu, setShowUserMenu] = useState(false);
  const buttonRef = useRef(null);
  const menuRef = useRef(null);

  // Close the menu when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target) && 
          buttonRef.current && !buttonRef.current.contains(event.target)) {
        setShowUserMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef, buttonRef]);

  // Ensure proper positioning on window resize
  useEffect(() => {
    const handleResize = () => {
      if (showUserMenu && buttonRef.current && menuRef.current) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const menuEl = menuRef.current;
        
        // Update dropdown position to match button width
        menuEl.style.width = `${buttonRect.width}px`;
        menuEl.style.right = '0';
      }
    };

    window.addEventListener('resize', handleResize);
    // Initial positioning
    if (showUserMenu) {
      handleResize();
    }
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [showUserMenu]);

  const handleLogout = async () => {
    try {
      if (refreshToken) {
        await logoutApi(refreshToken);  // Updated function name
      }
      dispatch(logout());
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
      // Show error to user
      alert('There was an issue logging out from the server, but you have been logged out locally.');
      dispatch(logout());
      navigate('/login');
    }
  };

  return (
    <header className="fixed top-0 left-0 right-0 bg-white bg-opacity-90 border-b border-blue-100 h-16 z-30 shadow-sm">
      {/* Decorative backgrounds */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-10 -right-10 w-32 h-32 bg-blue-300 rounded-full opacity-10"></div>
        <div className="absolute top-5 right-20 w-16 h-16 bg-purple-300 rounded-full opacity-10"></div>
        <div className="absolute -top-5 right-40 w-24 h-24 bg-indigo-300 rounded-full opacity-15"></div>
        <div className="absolute top-10 left-20 w-20 h-20 bg-sky-300 rounded-full opacity-10"></div>
        <div className="absolute -left-10 -top-10 w-32 h-32 bg-teal-300 rounded-full opacity-10"></div>
        
        {/* Subtle wave pattern */}
        <svg className="absolute inset-0 h-full w-full" preserveAspectRatio="none" viewBox="0 0 1200 120" xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.05 }}>
          <path d="M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18.19 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z" opacity=".25" fill="#4F46E5" />
          <path d="M0 0v15.81c13 21.11 27.64 41.05 47.69 56.24C99.41 111.27 165 111 224.58 91.58c31.15-10.15 60.09-26.07 89.67-39.8 40.92-19 84.73-46 130.83-49.67 36.26-2.85 70.9 9.42 98.6 31.56 31.77 25.39 62.32 62 103.63 73 40.44 10.79 81.35-6.69 119.13-24.28s75.16-39 116.92-43.05c59.73-5.85 113.28 22.88 168.9 38.84 30.2 8.66 59 6.17 87.09-7.5 22.43-10.89 48-26.93 60.65-49.24V0z" opacity=".5" fill="#4F46E5" />
        </svg>
      </div>

      <div className="h-full mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="flex items-center justify-between h-full">
          <div className="flex items-center">
            <div className="ml-4 lg:ml-0">
              <Link to="/my_profile" className="flex items-center group">
                <div className="mr-2 relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-sky-400 rounded-full opacity-30 group-hover:animate-ping" style={{ animationDuration: '3s' }}></div>
                  <div className="relative flex items-center justify-center w-8 h-8 bg-gradient-to-r from-blue-400 to-sky-400 rounded-full">
                    <Heart className="h-4 w-4 text-white" />
                  </div>
                </div>
                <span className="text-2xl font-serif font-bold bg-gradient-to-r from-blue-500 to-sky-500 bg-clip-text text-transparent transition-all duration-300 group-hover:from-sky-500 group-hover:to-blue-500">Kodava Sangama</span>
                <div className="ml-2">
                  <Sparkles className="h-5 w-5 text-blue-400" />
                </div>
              </Link>
            </div>
          </div>

          <div className="flex items-center gap-4">
            {/* Welcome message */}
            {userId && (
              <div className="hidden sm:flex items-center mr-4">
                <div className="">
                  <div className="flex items-center space-x-2">
                    <Sparkles className="h-4 w-4 text-green-500" />
                    <div>
                      <span className="font-serif font-medium text-green-800">
                        <span className="text-green-500 font-bold">Welcome {firstName}!</span>
                      </span>
                      <div className="text-xs text-yellow-1000 font-mono mt-0.7">
                        User ID: {userId}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            
            {/* User Menu with proper positioning */}
            <div className="relative">
              {/* User Menu Button */}
              <button
                ref={buttonRef}
                onClick={() => setShowUserMenu(!showUserMenu)}
                className="flex items-center gap-1 px-4 py-2 text-sm font-medium text-blue-700 bg-blue-50 hover:bg-blue-100 rounded-lg border-2 border-blue-200 shadow-sm transition-all duration-200 focus:outline-none"
                aria-label="User menu"
                aria-expanded={showUserMenu}
                aria-haspopup="true"
              >
                <User className="h-5 w-5 text-blue-500 mr-1" />
                <span className="hidden sm:inline font-serif">My Account</span>
                <ChevronDown className={`h-4 w-4 transition-transform duration-200 ${showUserMenu ? 'rotate-180' : ''}`} />
              </button>

              {/* Dropdown Menu - Properly positioned */}
              {showUserMenu && (
                <div 
                  ref={menuRef}
                  className="absolute right-0 mt-1 min-w-full bg-white rounded-lg shadow-lg border border-blue-100 z-50"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                >
                  <div className="py-1">
                    <Link
                      to="/my_profile"
                      className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-blue-50 font-serif w-full text-left"
                      onClick={() => setShowUserMenu(false)}
                      role="menuitem"
                    >
                      <UserCircle2 className="h-4 w-4 mr-2 text-blue-500" />
                      My Profile
                    </Link>
                    <Link
                      to="/delete-account"
                      className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-blue-50 font-serif w-full text-left"
                      onClick={() => setShowUserMenu(false)}
                      role="menuitem"
                    >
                      <XCircle className="h-4 w-4 mr-2 text-blue-500" />
                      Delete Account
                    </Link>
                    <button
                      onClick={() => {
                        setShowUserMenu(false);
                        handleLogout();
                      }}
                      className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-blue-50 font-serif"
                      role="menuitem"
                    >
                      <LogOut className="h-4 w-4 mr-2 text-blue-500" />
                      Logout
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const Sidebar = () => {
  // Sidebar component code remains unchanged
  const location = useLocation();
  const isActive = (path) => location.pathname === path;
  // Use the relations context to get counts
  const { counts, loading } = useRelations();

  // Main navigation link component
  const NavLink = ({ to, icon: Icon, children, special = false, count, showSparkles = true }) => (
    <Link
      to={to}
      className={`flex items-center px-4 py-3 text-sm font-medium rounded-lg transition-all duration-200 ${
        isActive(to)
          ? 'bg-gradient-to-r from-green-100 to-green-50 text-green-700 border-l-4 border-green-500'
          : special 
            ? 'text-rose-700 hover:bg-rose-50 border-l-4 border-transparent hover:border-rose-300' 
            : 'text-gray-700 hover:bg-blue-50 border-l-4 border-transparent hover:border-blue-300'
      }`}
    >
      <div className="flex items-center flex-1">
        <Icon className={`h-5 w-5 min-w-5 mr-3 ${isActive(to) ? 'text-green-600' : special ? 'text-rose-500' : 'text-blue-400'}`} />
        <span className="font-serif truncate">
          {children} {count !== undefined && `(${loading ? '...' : count})`}
        </span>
      </div>
      
      {isActive(to) && showSparkles && (
        <div className="ml-auto">
          <Sparkles className="h-4 w-4 text-green-400" />
        </div>
      )}
    </Link>
  );

  // Create a special category link with heading
  const CategoryHeading = ({ icon: Icon, children }) => (
    <div className="px-4 py-2 text-sm font-medium text-blue-700 flex items-center">
      <div className="flex items-center">
        <Icon className="h-5 w-5 mr-3 text-blue-500" />
        <span className="font-serif">{children}</span>
      </div>
    </div>
  );

  // Create a sub-link for nested navigation
  const SubNavLink = ({ to, icon: Icon, children, count, showSparkles = false }) => (
    <Link
      to={to}
      className={`flex items-center px-4 py-2.5 text-sm font-medium rounded-lg transition-all duration-200 pl-8 ${
        isActive(to)
          ? 'bg-gradient-to-r from-green-100 to-green-50 text-green-700 border-l-4 border-green-500'
          : 'text-gray-700 hover:bg-blue-50 border-l-4 border-transparent hover:border-blue-300'
      }`}
    >
      <div className="flex items-center flex-1">
        <Icon className={`h-4 w-4 min-w-4 mr-2 ${isActive(to) ? 'text-green-600' : 'text-blue-400'}`} />
        <span className="font-serif truncate">
          {children} {count !== undefined && `(${loading ? '...' : count})`}
        </span>
      </div>
      
      {isActive(to) && showSparkles && (
        <div className="ml-auto">
          <Sparkles className="h-4 w-4 text-green-400" />
        </div>
      )}
    </Link>
  );

  // Decorative divider with gradient and pattern
  const Divider = () => (
    <div className="relative h-px mx-3 my-3">
      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-blue-300 to-transparent"></div>
      <div className="absolute inset-0 bg-[radial-gradient(circle,_#4f46e5_1px,_transparent_1px)] bg-[length:8px_8px] opacity-30"></div>
    </div>
  );

  return (
    <aside
      className="fixed left-0 w-64 bg-gradient-to-b from-indigo-50 via-blue-50 to-sky-50 top-16 bottom-0 border-r border-blue-100 shadow-sm z-20"
    >
      {/* Decorative sidebar elements */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        <div className="absolute bottom-0 right-0 w-64 h-64 bg-blue-100 rounded-full opacity-30" style={{ transform: 'translate(50%, 50%)' }}></div>
        <svg className="absolute bottom-0 w-full" preserveAspectRatio="none" viewBox="0 0 800 150" style={{ opacity: 0.1 }}>
          <path d="M0,150 C150,50 350,50 500,100 C650,150 750,100 800,0 L800,150 L0,150 Z" fill="#4F46E5"></path>
        </svg>
      </div>
      
      <div className="flex-1 flex flex-col overflow-y-auto relative z-10">
        <div className="flex items-center justify-center mt-6 mb-4">
          <div className="inline-flex items-center px-4 py-1.5 rounded-full bg-blue-50 border border-blue-100">
            <Heart className="h-4 w-4 text-green-500 mr-2" />
            <span className="text-green-600 text-xs font-medium font-serif">Exclusive Kodava Matrimony</span>
          </div>
        </div>
        
        <nav className="flex-1 px-2 py-4 space-y-1">
          {/* Top divider - above My Profile */}
          <Divider />
          
          {/* Profile section */}
          <NavLink to="/my_profile" icon={UserCircle2} showSparkles={true}>
            My Profile
          </NavLink>
          
          {/* Divider below My Profile */}
          <Divider />
          
          {/* My Requests Category */}
          <div className="pt-1 pb-2">
            <CategoryHeading icon={Users}>
              My Requests
            </CategoryHeading>
            <div className="mt-1 space-y-1">
              <SubNavLink 
                to="/express-interests" 
                icon={Heart} 
                count={counts.expressInterests}
                showSparkles={false}
              >
                Express Interests
              </SubNavLink>
              <SubNavLink 
                to="/contact-requests" 
                icon={Mail} 
                count={counts.contactRequests}
                showSparkles={false}
              >
                Contact Requests
              </SubNavLink>
            </div>
          </div>
          
          <Divider />
          
          {/* Blocked Profiles section */}
          <NavLink 
            to="/blocked-profiles" 
            icon={Shield} 
            count={counts.blockedProfiles}
            showSparkles={false}
          >
            Blocked Profiles
          </NavLink>
          
          <Divider />
          
          {/* Search Profiles section */}
          <NavLink to="/search" icon={Search} showSparkles={true}>
            Search Profiles
          </NavLink>
          
          <Divider />
          
          {/* Support section */}
          <NavLink to="/protected-support" icon={MessageCircle} showSparkles={true}>
            Support
          </NavLink>
          
          {/* Bottom divider */}
          <Divider />
        </nav>
      </div>
    </aside>
  );
};

// Updated Footer to be positioned at the bottom of the page and span full width
const Footer = () => (
  <footer className="fixed bottom-0 left-0 right-0 bg-white bg-opacity-90 backdrop-filter backdrop-blur-sm border-t border-blue-100 z-20">
    <div className="container mx-auto h-10 flex items-center justify-center">
      <div className="flex items-center">
        <Heart className="h-4 w-4 text-blue-400 mr-2" />
        <p className="text-sm text-gray-600 font-serif">&copy; {new Date().getFullYear()} Kodava Sangama. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  // Get values from Redux state with localStorage fallbacks
  const reduxRefreshToken = useSelector(state => state.auth.refreshToken);
  const reduxUserId = useSelector(state => state.auth.userId);
  const reduxFirstName = useSelector(state => state.auth.firstName);
  
  // Use localStorage as fallback if Redux state is missing data
  const refreshToken = reduxRefreshToken || localStorage.getItem('refreshToken');
  const userId = reduxUserId || localStorage.getItem('userId');
  const firstName = reduxFirstName || localStorage.getItem('firstName');
  
  const isAuthPage = ['/login', '/register'].includes(location.pathname);

  if (isAuthPage) {
    return <Outlet />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-sky-50 to-indigo-50 flex flex-col relative">
      {/* Decorative elements */}
      <div className="absolute top-40 right-20 w-80 h-80 bg-gradient-to-br from-blue-300 to-sky-300 rounded-full filter blur-3xl opacity-20 -z-10"></div>
      <div className="absolute bottom-40 left-20 w-80 h-80 bg-gradient-to-br from-sky-300 to-indigo-300 rounded-full filter blur-3xl opacity-20 -z-10"></div>
      
      <Header
        dispatch={dispatch}
        navigate={navigate}
        refreshToken={refreshToken}
        userId={userId}
        firstName={firstName}
      />
      
      <Sidebar />
      
      <div className="flex flex-col min-h-screen pt-16 pl-64 pb-16">
        <main className="flex-1 px-4 sm:px-6 lg:px-8 py-8">
          <div className="mx-auto">
            <Outlet />
          </div>
        </main>
      </div>
      
      <Footer />
    </div>
  );
};

export default Layout;