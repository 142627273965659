import React, { useState, useEffect } from 'react';
import { Clock, Ban, MessageCircle, Heart, Phone, AlertTriangle, Check, X } from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';
import * as Separator from '@radix-ui/react-separator';
import { sendConnectionRequest, updateConnectionRequest, updateContactRequest, getProfile } from '../../services/api';

// Separated confirmation dialog for better reusability
const ConfirmationDialog = ({ isOpen, onClose, onConfirm, title, description, confirmButtonStyle, confirmText, isProcessing }) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50" />
        <Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-w-md w-[90%] shadow-xl">
          <Dialog.Title className="text-lg font-medium text-gray-900 flex items-center gap-2">
            <AlertTriangle className="w-5 h-5 text-amber-500" />
            {title}
          </Dialog.Title>
          <Separator.Root className="h-px bg-gray-200 my-4" decorative />
          <Dialog.Description className="text-sm text-gray-600 mb-4">
            {description}
          </Dialog.Description>
          
          <div className="flex justify-end gap-3">
            <Dialog.Close asChild>
              <button 
                className="px-4 py-2 rounded-lg text-sm font-medium bg-white border border-gray-300 hover:bg-gray-50"
                disabled={isProcessing}
              >
                Cancel
              </button>
            </Dialog.Close>
            <button 
              onClick={onConfirm}
              disabled={isProcessing}
              className={`px-4 py-2 rounded-lg text-sm font-medium ${confirmButtonStyle}`}
            >
              {isProcessing ? 'Processing...' : confirmText}
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

// Button components for better reusability
const ActionButton = ({ onClick, disabled, className, icon: Icon, children }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`px-4 py-2 rounded-lg text-sm font-medium ${className} transition-colors duration-200 flex items-center justify-center gap-2`}
  >
    {Icon && <Icon className="w-4 h-4" />}
    {children}
  </button>
);

// Status message component
const StatusMessage = ({ type, icon: Icon, children }) => {
  const bgColors = {
    info: "bg-blue-50 border-blue-200 text-blue-700",
    warning: "bg-amber-50 border-amber-200 text-amber-700",
    success: "bg-emerald-50 border-emerald-200 text-emerald-700",
    error: "bg-red-50 border-red-200 text-red-700",
    purple: "bg-purple-50 border-purple-200 text-purple-700",
    gray: "bg-gray-50 border-gray-200 text-gray-500"
  };

  return (
    <div className={`px-4 py-2 rounded-lg ${bgColors[type]} text-sm flex items-center justify-center gap-2 border`}>
      {Icon && <Icon className="w-4 h-4" />}
      {children}
    </div>
  );
};

const ProfileControls = ({
  profile,
  profileType,
  connection,
  contactRequest,
  onSendConnectionRequest,
  onSendContactRequest,
  onBlockUser
}) => {
  // State
  const [connectionRequestSent, setConnectionRequestSent] = useState(false);
  const [contactRequestSent, setContactRequestSent] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [profileData, setProfileData] = useState({ 
    connection, 
    contactRequest, 
    profileType 
  });
  
  // Dialog state
  const [dialogConfig, setDialogConfig] = useState({
    isOpen: false,
    type: null,
    title: '',
    description: '',
    confirmButtonStyle: '',
    confirmText: ''
  });
  
  // Update profileData when props change
  useEffect(() => {
    // Match backend property names - backend uses 'id' instead of 'connection_id'
    setProfileData({
      connection,
      contactRequest,
      profileType
    });
    
    // Log the connection data for debugging
    if (connection) {
      console.log('Connection data received:', connection);
    }
    
    if (contactRequest) {
      console.log('Contact request data received:', contactRequest);
    }
  }, [connection, contactRequest, profileType]);
  
  // Determine profile state
  const isMyProfile = profileType === 'self';
  const isConnected = profileData.connection && profileData.connection.status === 'Accepted';
  const isPending = profileData.connection && profileData.connection.status === 'Pending';
  const isDeclined = profileData.connection && profileData.connection.status === 'Declined';

  const isContactRequestDeclined = profileData.contactRequest && profileData.contactRequest.status === 'Declined';
  // Check the user's ID from localStorage
  const currentUserId = localStorage.getItem('userId');
  
  // Determine request directions
  const hasIncomingRequest = profileData.connection && 
    profileData.connection.status === 'Pending' && 
    profileData.connection.receiver === currentUserId;

  const hasIncomingContactRequest = profileData.contactRequest && 
    profileData.contactRequest.status === 'Pending' && 
    profileData.contactRequest.sender !== currentUserId;
  
  const hasOutgoingContactRequest = profileData.contactRequest && 
    profileData.contactRequest.status !== 'Declined' &&
    !hasIncomingContactRequest;
  
  // Function to get connection or contact ID, matching backend's use of 'id'
  const getConnectionId = (connection) => {
    if (!connection) return null;
    // Backend uses 'id' for both connection_id and request_id
    return connection.id;
  };
  
  // Function to refresh profile data
  const refreshProfileData = async () => {
    try {
      const userId = profile.user_id;
      const response = await getProfile(userId);
      
      // Update local state
      setProfileData({
        connection: response.connection,
        contactRequest: response.contact_request,
        profileType: response.profile_type
      });
      
      // Reload page to ensure everything is in sync
      window.location.reload();
    } catch (error) {
      console.error("Failed to refresh profile data:", error);
    }
  };

  // Action handlers
  const handleSendConnectionRequest = async () => {
    if (isMyProfile) return;
    setIsProcessing(true);
    
    try {
      const success = await onSendConnectionRequest();
      setConnectionRequestSent(true);
      if (success) {
        await refreshProfileData();
      }
    } catch (error) {
      console.error("Failed to send connection request: ", error);
    } finally {
      setIsProcessing(false);
    }
  };
  
  const handleSendContactRequest = async () => {
    if (isMyProfile || !isConnected) return;
    setIsProcessing(true);
    
    try {
      const success = await onSendContactRequest();
      setContactRequestSent(true);
      if (success) {
        await refreshProfileData();
      }
    } catch (error) {
      console.error("Failed to send contact request:", error);
    } finally {
      setIsProcessing(false);
    }
  };
  
  // Dialog configuration helpers
  const getDialogConfig = (type) => {
    const configs = {
      'block': {
        title: 'Block User',
        description: "Blocking this user will prevent them from seeing your profile or contacting you. They won't be notified. Are you sure you want to proceed?",
        confirmButtonStyle: 'bg-red-600 text-white hover:bg-red-700 border border-red-700',
        confirmText: 'Block User'
      },
      'accept-connection': {
        title: 'Accept Connection',
        description: 'Are you sure you want to accept this connection request?',
        confirmButtonStyle: 'bg-emerald-600 text-white hover:bg-emerald-700 border border-emerald-700',
        confirmText: 'Accept'
      },
      'decline-connection': {
        title: 'Decline Connection',
        description: 'Are you sure you want to decline this connection request?',
        confirmButtonStyle: 'bg-red-600 text-white hover:bg-red-700 border border-red-700',
        confirmText: 'Decline'
      },
      'accept-contact': {
        title: 'Accept Contact Request',
        description: 'Accepting will share your contact details with this user. Are you sure?',
        confirmButtonStyle: 'bg-emerald-600 text-white hover:bg-emerald-700 border border-emerald-700',
        confirmText: 'Accept'
      },
      'decline-contact': {
        title: 'Decline Contact Request',
        description: 'Are you sure you want to decline this contact request?',
        confirmButtonStyle: 'bg-red-600 text-white hover:bg-red-700 border border-red-700',
        confirmText: 'Decline'
      }
    };
    
    return configs[type] || {
      title: 'Confirm Action',
      description: 'Are you sure you want to proceed with this action?',
      confirmButtonStyle: 'bg-sky-600 text-white hover:bg-sky-700 border border-sky-700',
      confirmText: 'Confirm'
    };
  };
  
  const openConfirmation = (type) => {
    if (isMyProfile) return;
    
    const config = getDialogConfig(type);
    setDialogConfig({
      isOpen: true,
      type,
      ...config
    });
  };
  
  const closeConfirmation = () => {
    setDialogConfig(prev => ({ ...prev, isOpen: false }));
  };
  
  // Handle the confirm action based on the dialog type
  const handleConfirmAction = async () => {
    const { type } = dialogConfig;
    setIsProcessing(true);
    
    try {
      let success = false;
      
      switch(type) {
        case 'block':
          success = await onBlockUser();
          if (success) {
            console.log('User blocked successfully, redirecting to Blocked Profiles page');
            // Use window.location for navigation if we're in the browser
            window.location.href = '/blocked-profiles';
            return; // Early return to prevent additional operations
          }
        break;
        case 'accept-connection':
          // Get connection ID using helper method
          const connectionId = getConnectionId(profileData.connection);
          if (connectionId) {
            console.log(`Accepting connection with ID: ${connectionId}`);
            await updateConnectionRequest(connectionId, 'accept');
            success = true;
          } else {
            console.error("Missing ID for connection request", profileData.connection);
          }
          break;
        case 'decline-connection':
          // Get connection ID using helper method
          const declineConnId = getConnectionId(profileData.connection);
          if (declineConnId) {
            console.log(`Declining connection with ID: ${declineConnId}`);
            await updateConnectionRequest(declineConnId, 'decline');
            success = true;
          } else {
            console.error("Missing ID for connection request", profileData.connection);
          }
          break;
        case 'accept-contact':
          // Get contact request ID using helper method
          const contactId = getConnectionId(profileData.contactRequest);
          if (contactId) {
            console.log(`Accepting contact request with ID: ${contactId}`);
            await updateContactRequest(contactId, 'accept');
            success = true;
          } else {
            console.error("Missing ID for contact request", profileData.contactRequest);
          }
          break;
        case 'decline-contact':
          // Get contact request ID using helper method
          const declineContactId = getConnectionId(profileData.contactRequest);
          if (declineContactId) {
            console.log(`Declining contact request with ID: ${declineContactId}`);
            await updateContactRequest(declineContactId, 'decline');
            success = true;
          } else {
            console.error("Missing ID for contact request", profileData.contactRequest);
          }
          break;
        default:
          console.error(`Unknown action type: ${type}`);
      }
      
      if (success) {
        await refreshProfileData();
      }
    } catch (error) {
      console.error(`Failed to ${type}:`, error);
    } finally {
      setIsProcessing(false);
      closeConfirmation();
    }
  };

  // Render helper functions for cleaner JSX
  const renderLastActive = () => (
    <div className="flex items-center gap-2 text-sm text-gray-600">
      <Clock className="w-4 h-4 text-sky-500" />
      <span>
        Last active: {profile?.last_login || 'Never logged in'}
      </span>
    </div>
  );
  
  const renderIncomingConnectionButtons = () => (
    <div className="space-y-2">
      <StatusMessage type="warning" icon={Heart}>
        This person has expressed interest in connecting.
      </StatusMessage>
      <div className="grid grid-cols-2 gap-2">
        <ActionButton 
          onClick={() => openConfirmation('accept-connection')}
          disabled={isProcessing}
          className="bg-emerald-600 text-white hover:bg-emerald-700 border border-emerald-700"
          icon={Check}
        >
          Accept
        </ActionButton>
        <ActionButton 
          onClick={() => openConfirmation('decline-connection')}
          disabled={isProcessing}
          className="bg-red-600 text-white hover:bg-red-700 border border-red-700"
          icon={X}
        >
          Decline
        </ActionButton>
      </div>
    </div>
  );
  
  const renderIncomingContactButtons = () => (
    <div className="space-y-2">
      <StatusMessage type="purple" icon={Phone}>
        This person has requested your contact details. Please review their profile and decide.
      </StatusMessage>
      <div className="grid grid-cols-2 gap-2">
        <ActionButton 
          onClick={() => openConfirmation('accept-contact')}
          disabled={isProcessing}
          className="bg-emerald-600 text-white hover:bg-emerald-700 border border-emerald-700"
          icon={Check}
        >
          Accept
        </ActionButton>
        <ActionButton 
          onClick={() => openConfirmation('decline-contact')}
          disabled={isProcessing}
          className="bg-red-600 text-white hover:bg-red-700 border border-red-700"
          icon={X}
        >
          Decline
        </ActionButton>
      </div>
    </div>
  );
  
  const renderConnectionRequestButton = () => (
    <div className="flex justify-center">
      <ActionButton
        onClick={handleSendConnectionRequest}
        disabled={connectionRequestSent || isMyProfile || isProcessing}
        className={isMyProfile || connectionRequestSent || isProcessing
          ? 'bg-gray-100 text-gray-500 border border-gray-300 cursor-not-allowed'
          : 'bg-sky-600 text-white hover:bg-sky-700 border border-sky-700'
        }
        icon={Heart}
      >
        {isProcessing ? 'Processing...' : connectionRequestSent ? 'Interest Expressed' : 'Express Interest'}
      </ActionButton>
    </div>
  );
  
  const renderPendingConnectionStatus = () => (
    <StatusMessage type="warning" icon={Clock}>
      You have expressed interest. Please await a response.
    </StatusMessage>
  );
  
  const renderDeclinedContactRequestStatus = () => {
    // Check who was the sender to determine who declined
    const isSender = profileData.contactRequest && 
      profileData.contactRequest.sender === currentUserId;
    
    return (
      <StatusMessage type="error" icon={X}>
        {isSender 
          ? "This person has declined your contact request." 
          : "You have declined this person's contact request."}
      </StatusMessage>
    );
  };

  const renderContactRequestButton = () => (
    
    <div className="flex flex-col items-center space-y-4">
      <StatusMessage type="warning" icon={Clock}>
        You have a potential match. Please review their profile and request contact information to proceed.
      </StatusMessage>
      
      <ActionButton
        onClick={handleSendContactRequest}
        disabled={contactRequestSent || isMyProfile || isProcessing}
        className={isMyProfile || contactRequestSent || isProcessing
          ? 'bg-gray-100 text-gray-500 border border-gray-300 cursor-not-allowed'
          : 'bg-purple-600 text-white hover:bg-purple-700 border border-purple-700'
        }
        icon={Phone}
      >
        {isProcessing ? 'Processing...' : contactRequestSent ? 'Contact Request Sent' : 'Request Contact Details'}
      </ActionButton>
    </div>
  );
  
  const renderContactRequestStatus = () => (
    <StatusMessage type="info" icon={MessageCircle}>
      Contact Request: {profileData.contactRequest?.status || 'Processing'}
    </StatusMessage>
  );
  
  const renderDeclinedConnectionStatus = () => {
    // Check who was the sender to determine who declined
    const isSender = profileData.connection && profileData.connection.sender === currentUserId;
    
    return (
      <StatusMessage type="error" icon={X}>
        {isSender 
          ? "This person has declined your connection request." 
          : "You have declined this person's connection request."}
      </StatusMessage>
    );
  };

  const renderBlockUserButton = () => (
    <div className="flex justify-center">
      <ActionButton
        onClick={() => openConfirmation('block')}
        disabled={isMyProfile || isProcessing}
        className={isMyProfile || isProcessing
          ? 'bg-gray-100 text-gray-500 border border-gray-300 cursor-not-allowed'
          : 'bg-white text-red-600 border border-red-300 hover:bg-red-50'
        }
        icon={Ban}
      >
        {isProcessing ? 'Processing...' : 'Block User'}
      </ActionButton>
    </div>
  );
  
  const renderMyProfileNotice = () => (
    <StatusMessage type="gray">
      These controls are inactive on your own profile. You'll find buttons to block users, accept or decline requests on other members' profiles.
    </StatusMessage>
  );

  return (
    <div className="border-2 border-dotted border-gray-400 rounded-lg p-4 bg-white">
      <div className="flex flex-col gap-4">
        {/* Last Active Status */}
        {renderLastActive()}
        
        <Separator.Root className="h-px bg-gray-200" decorative />
        
        {/* Action Buttons Section */}
        <div className="flex flex-col gap-3">
          <h3 className="text-sm font-medium text-gray-700">Profile Actions</h3>
          
          {/* Conditional Rendering of Action Components */}
          {hasIncomingRequest && renderIncomingConnectionButtons()}
          {hasIncomingContactRequest && renderIncomingContactButtons()}
          {!isMyProfile && !isConnected && !isPending && !hasIncomingRequest && !isDeclined && renderConnectionRequestButton()}
          {isPending && !hasIncomingRequest && renderPendingConnectionStatus()}
          {isDeclined && renderDeclinedConnectionStatus()}
          {isConnected && !hasOutgoingContactRequest && !hasIncomingContactRequest && !isContactRequestDeclined && renderContactRequestButton()}
          {hasOutgoingContactRequest && !isContactRequestDeclined && renderContactRequestStatus()}
          {isContactRequestDeclined && renderDeclinedContactRequestStatus()}
          {!isMyProfile && renderBlockUserButton()}
          {isMyProfile && renderMyProfileNotice()}
        </div>
      </div>
      
      {/* Confirmation Dialog */}
      <ConfirmationDialog
        isOpen={dialogConfig.isOpen}
        onClose={closeConfirmation}
        onConfirm={handleConfirmAction}
        title={dialogConfig.title}
        description={dialogConfig.description}
        confirmButtonStyle={dialogConfig.confirmButtonStyle}
        confirmText={dialogConfig.confirmText}
        isProcessing={isProcessing}
      />
    </div>
  );
};

export default ProfileControls;