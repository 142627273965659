import React from 'react';
import { User, Users, Briefcase, Building, Shield, CheckCircle2 } from 'lucide-react';
import { InlineInputWithValidation, InputWithValidation, InfoCard } from '../../components';

export const Family = ({ formData, handleChange }) => {
  // Validation states
  const validations = {
    father_name: formData.father_name && formData.father_name.length >= 2,
    father_family: formData.father_family && formData.father_family.length >= 2,
    father_occupation: formData.father_occupation && formData.father_occupation.length >=2,
    mother_name: formData.mother_name && formData.mother_name.length >= 2,
    mother_occupation: formData.mother_occupation && formData.mother_occupation.length >=2,
    mother_family: formData.mother_family && formData.mother_family.length >= 2,
    siblings_count: formData.siblings_count !== '',
    assets: formData.assets?.length >= 2 || false,
  };

  return (
    <div className="space-y-6">

      {/* Father's Information */}
      <InfoCard 
        title="Father's Information" 
        icon={User} 
        type="personal"
      >
        <div className="space-y-4">
          <InlineInputWithValidation
            label="Father's Name"
            icon={User}
            value={formData.father_name}
            isValid={validations.father_name}
            required={true}
          >
            <input
              type="text"
              name="father_name"
              value={formData.father_name}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder="Enter father's name"
              required
            />
          </InlineInputWithValidation>

          <InlineInputWithValidation
            label="Father's Family Name (Manepedha)"
            icon={Users}
            value={formData.father_family}
            isValid={validations.father_family}
            helper="Please provide your father's caste or religion if family name is not applicable."
            required={true}
          >
            <input
              type="text"
              name="father_family"
              value={formData.father_family}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder="Enter father's family name"
              required
            />
          </InlineInputWithValidation>

          <InlineInputWithValidation
            label="Father's Occupation"
            icon={Briefcase}
            value={formData.father_occupation}
            isValid={validations.father_occupation}
            required={true}
            helper="Examples:  Coffee Estate Owner, Ex Army, Retired School Teacher, Government Employee"
          >
            <input
              type="text"
              name="father_occupation"
              value={formData.father_occupation}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder="Enter father's occupation"
            />
          </InlineInputWithValidation>
        </div>
      </InfoCard>

      {/* Mother's Information */}
      <InfoCard 
        title="Mother's Information" 
        icon={User} 
        type="personal"
      >
        <div className="space-y-4">
          <InlineInputWithValidation
            label="Mother's Name"
            icon={User}
            value={formData.mother_name}
            isValid={validations.mother_name}
            required={true}
          >
            <input
              type="text"
              name="mother_name"
              value={formData.mother_name}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder="Enter mother's name"
              required
            />
          </InlineInputWithValidation>

          <InlineInputWithValidation
            label="Mother's Family Name (Thamane)"
            icon={Users}
            value={formData.mother_family}
            isValid={validations.mother_family}
            helper="Please provide your mother's caste or religion if family name is not applicable."
            required={true}
          >
            <input
              type="text"
              name="mother_family"
              value={formData.mother_family}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder="Enter mother's family name"
              required
            />
          </InlineInputWithValidation>

          <InlineInputWithValidation
            label="Mother's Occupation"
            icon={Briefcase}
            value={formData.mother_occupation}
            isValid={validations.mother_occupation}
            required={true}
            helper="Examples: Homemaker, School Teacher, Bank Manager, Doctor, Business Owner"
          >
            <input
              type="text"
              name="mother_occupation"
              value={formData.mother_occupation}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              placeholder="Enter mother's occupation"
            />
          </InlineInputWithValidation>
        </div>
      </InfoCard>

      {/* Siblings Information */}
      <InfoCard 
        title="Siblings Information" 
        icon={Users} 
        type="profile"
      >
        <div className="space-y-4">
          <InlineInputWithValidation
            label="Number of Siblings"
            icon={Users}
            value={formData.siblings_count}
            isValid={validations.siblings_count}
            required={true}
          >
            <input
              type="number"
              name="siblings_count"
              value={formData.siblings_count}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 focus:border-sky-400 bg-white"
              min="0"
              placeholder="brothers + sisters"
            />
          </InlineInputWithValidation>
        </div>
      </InfoCard>

      {/* Assets Information */}
      <InfoCard 
        title="Family Assets" 
        icon={Building} 
        type="verification"
      >
        <div className="space-y-4">
          <InputWithValidation
            label="Property & Assets"
            value={formData.assets}
            isValid={validations.assets}
            required={true}
            helper={
              <div className="space-y-1">
                <p>Examples:</p>
                <ul className="list-disc pl-4 space-y-1">
                  <li>House in Madikeri town and 5 acres of coffee estate in Suntikoppa</li>
                  <li>3 acres of coffee estate and 4 acres of paddy fields in Virajpet</li>
                  <li>Apartment and 2 sites in Bangalore, 10 acres coffee estate in Somwarpet</li>
                  <li>Commercial building in Madikeri, 15 acres mixed plantation in Siddapur</li>
                </ul>
              </div>
            }
          >
            <textarea
              name="assets"
              value={formData.assets}
              onChange={handleChange}
              className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-400 focus:border-teal-400 bg-white min-h-[120px]"
              placeholder="Describe family assets..."
            />
          </InputWithValidation>
        </div>
      </InfoCard>
    </div>
  );
};

export default Family;