import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loginSuccess } from './authSlice';

const ProtectedRoute = () => {
  const { accessToken, userId } = useSelector(state => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  
  // On component mount, check if we need to restore auth state from localStorage
  useEffect(() => {
    // If Redux doesn't have the token but localStorage does, restore it
    if (!accessToken) {
      const storedToken = localStorage.getItem('accessToken');
      const storedRefreshToken = localStorage.getItem('refreshToken');
      const storedUserId = localStorage.getItem('userId');
      const storedFirstName = localStorage.getItem('firstName');
      const storedEmail = localStorage.getItem('email');
      
      if (storedToken && storedUserId) {
        // Restore authentication state to Redux
        dispatch(loginSuccess({
          access: storedToken,
          refresh: storedRefreshToken,
          user_id: storedUserId,
          first_name: storedFirstName,
          email: storedEmail
        }));
      }
    }
  }, [accessToken, dispatch]);
  
  // Check both Redux state and localStorage
  const isAuthenticated = accessToken || localStorage.getItem('accessToken');

  if (!isAuthenticated) {
    // Redirect to login page, but save the intended destination
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;