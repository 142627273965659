import React, { useEffect, useState } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { Link } from 'react-router-dom';
import { 
  UserPlus, 
  UserCheck, 
  UserX, 
  Clock, 
  Send,
  CheckCircle2,
  XCircle,
  ExternalLink,
  Image,
  Heart,
  Mail,
  Shield,
  MessageSquare,
} from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { sendContactRequest } from '../../services/api';

export const Badge = ({ status }) => {
  const styles = {
    Pending: "text-amber-600 bg-amber-50 border border-amber-200 px-2 py-0.5 rounded-full",
    Accepted: "text-teal-600 bg-teal-50 border border-teal-200 px-2 py-0.5 rounded-full",
    Declined: "text-red-600 bg-red-50 border border-red-200 px-2 py-0.5 rounded-full"
  };

  const icons = {
    Pending: <Clock className="w-4 h-4" />,
    Accepted: <CheckCircle2 className="w-4 h-4" />,
    Declined: <XCircle className="w-4 h-4" />
  };

  return (
    <span className={`${styles[status]} text-sm font-medium inline-flex items-center gap-1`}>
      {icons[status]}
      {status}
    </span>
  );
};

export const TabButton = ({ children, value, icon: Icon }) => {
  return (
    <Tabs.Trigger 
      value={value}
      className="px-6 py-3 text-sm font-medium transition-all relative group data-[state=active]:text-sky-600"
    >
      <div className="flex items-center gap-2">
        <Icon className="w-4 h-4" />
        {children}
      </div>
      <div className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-sky-500 to-indigo-500 scale-x-0 group-data-[state=active]:scale-x-100 transition-transform" />
    </Tabs.Trigger>
  );
};

export const ActionButton = ({ onClick, variant = 'primary', children, showIcon = true }) => {
  const styles = {
    accept: "bg-teal-50 text-teal-700 hover:bg-teal-100 border border-teal-200 shadow-sm",
    reject: "bg-red-50 text-red-700 hover:bg-red-100 border border-red-200 shadow-sm",
    cancel: "bg-gray-50 text-gray-700 hover:bg-gray-100 border border-gray-200 shadow-sm"
  };

  const icons = {
    accept: <CheckCircle2 className="w-4 h-4" />,
    reject: <XCircle className="w-4 h-4" />,
    cancel: <XCircle className="w-4 h-4" />
  };

  return (
    <button
      className={`${styles[variant]} px-3 py-1.5 rounded-lg text-sm font-medium transition-all inline-flex items-center gap-2`}
      onClick={onClick}
    >
      {showIcon && icons[variant]}
      {children}
    </button>
  );
};

export const TableHeader = ({ children, className = '' }) => (
  <th className={`px-6 py-3 text-left font-medium text-gray-700 text-sm bg-sky-50 border-x border-gray-200 ${className}`}>
    {children}
  </th>
);

export const TableCell = ({ children, className = '' }) => (
  <td className={`px-6 py-4 ${className}`}>
    {children}
  </td>
);


const TableDialogContent = ({ selectedAction, selectedUserId, onConfirm, onCancel }) => (
  <AlertDialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-[400px] max-w-[90vw] shadow-xl border border-gray-300">
    <AlertDialog.Title className="text-xl font-semibold bg-gradient-to-r from-sky-600 to-indigo-600 bg-clip-text text-transparent mb-4">
      {selectedAction === 'accept' ? 'Accept Request' : 'Reject Request'}
    </AlertDialog.Title>
    <AlertDialog.Description className="text-gray-600 mb-6">
      Are you sure you want to {selectedAction} the request?
    </AlertDialog.Description>
    <div className="flex justify-end gap-4">
      <AlertDialog.Cancel asChild>
        <ActionButton variant="cancel" onClick={onCancel}>Cancel</ActionButton>
      </AlertDialog.Cancel>
      <AlertDialog.Action asChild>
        <ActionButton variant={selectedAction === 'accept' ? 'accept' : 'reject'} onClick={onConfirm}>
          {selectedAction === 'accept' ? 'Accept' : 'Reject'}
        </ActionButton>
      </AlertDialog.Action>
    </div>
  </AlertDialog.Content>
);

const ConnectionRow = ({ connection, type, showActions, onActionClick, onContactRequest, idx, contactRequests = [] }) => {
  // Determine which ID to use based on the type
  const handleAction = (action) => {
    // If it's in the contacts tab, use request_id
    if (type === 'pending_received' && connection.request_id) {
      onActionClick(connection.request_id, action);
    }
    // If it's in the connections tab, use connection_id
    else if (connection.connection_id) {
      onActionClick(connection.connection_id, action);
    }
  };

  return (
    <tr className={`${idx % 2 === 0 ? 'bg-white' : 'bg-sky-50/30'} hover:bg-sky-50/70 transition-colors`}>
      <TableCell className="border-x border-gray-200">
      <Link 
        to={`/profile/${connection.user_id}`} 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-sky-600 hover:text-sky-800 inline-flex items-center gap-2">
        {connection.user_id}
        <ExternalLink className="w-4 h-4 opacity-50 group-hover:opacity-100" />
      </Link>
        {connection.first_name && connection.first_name !== "" && (
          <span className="text-gray-600 ml-2">({connection.first_name})</span>
        )}
      </TableCell>
      <TableCell className="border-r border-gray-200">
        {connection.updated_at ? 
          formatDistanceToNow(new Date(connection.updated_at), { addSuffix: true })
          : 'N/A'
        }
      </TableCell>
      <TableCell className="border-r border-gray-200">
        <Badge status={type.includes('pending') ? 'Pending' : type === 'accepted' ? 'Accepted' : 'Declined'} />
      </TableCell>
      {(showActions || type === 'accepted') && (
        <TableCell className="text-center border-r border-gray-200 space-x-2">
          {showActions && (
            <>
              <ActionButton variant="accept" onClick={() => handleAction('accept')}>
                Accept
              </ActionButton>
              <ActionButton variant="reject" onClick={() => handleAction('reject')}>
                Decline
              </ActionButton>
            </>
          )}
          {type === 'accepted' && !connection.request_id && (
            contactRequests.some(req => req.user_id === connection.user_id) ? (
              <span className="text-amber-600 inline-flex items-center gap-2 text-sm">
                Check Contact Requests tab
              </span>
            ) : (
              <ActionButton 
                variant="accept" 
                onClick={() => onContactRequest(connection.user_id)}
                showIcon={true}
              >
                Send Contact Request
              </ActionButton>
            )
          )}
          {type === 'accepted' && connection.request_id && (
            <span className="text-sky-600 inline-flex items-center gap-2 text-sm">
              Congratulations! Please open the user profile for contact details.
            </span>
          )}
        </TableCell>
      )}
    </tr>
  );
};

const ConnectionTableHeader = ({ showActions, type }) => (
  <thead>
    <tr>
      <TableHeader>User ID</TableHeader>
      <TableHeader>Last Activity</TableHeader>
      <TableHeader>Status</TableHeader>
      {(showActions || type === 'accepted') && (
        <TableHeader className="text-center pr-6">Actions</TableHeader>
      )}
    </tr>
  </thead>
);

export const ConnectionTable = ({ connections, type, showActions, onAction, contactRequests = [], onRefresh  }) => {
  const [dialogState, setDialogState] = useState({
    isOpen: false,
    userId: null,
    action: null
  });

  if (!connections?.length) {
    return <div className="text-gray-500 p-4 text-center text-sm">No requests found</div>;
  }

  const handleActionClick = (id, action) => {
    setDialogState({
      isOpen: true,
      id,
      action
    });
  };

  const handleConfirm = () => {
    if (onAction) {
      // dialogState.id is actually storing our ID (either request_id or connection_id)
      const id = type === 'contact_request' ? dialogState.id : dialogState.id;
      onAction(id, dialogState.action);
    }
    setDialogState({ isOpen: false, id: null, action: null });
  };

  const handleSendContactRequest = async (userId) => {
    try {
      await sendContactRequest(userId);
      if (onRefresh) {
        onRefresh();
      }
    } catch (error) {
      console.error('Error sending contact request:', error);
      // Could add error notification here
    }
  };

  return (
    <div className="w-full">
      <table className="min-w-full border border-gray-300 divide-y divide-gray-200">
        <ConnectionTableHeader showActions={showActions} type={type}/>
        <tbody className="divide-y divide-gray-200">
          {connections.map((connection, idx) => (
            <ConnectionRow
              key={connection.connection_id}
              connection={connection}
              type={type}
              showActions={showActions}
              onActionClick={handleActionClick}
              onContactRequest={handleSendContactRequest}
              idx={idx}
              contactRequests={contactRequests}
            />
          ))}
        </tbody>
      </table>

      <AlertDialog.Root 
        open={dialogState.isOpen} 
        onOpenChange={(isOpen) => setDialogState(prev => ({ ...prev, isOpen }))}
      >
        <AlertDialog.Portal>
          <AlertDialog.Overlay className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
          <TableDialogContent
            selectedAction={dialogState.action}
            selectedUserId={dialogState.userId}
            onConfirm={handleConfirm}
            onCancel={() => setDialogState({ isOpen: false, userId: null, action: null })}
          />
        </AlertDialog.Portal>
      </AlertDialog.Root>
    </div>
  );
};


export const DeclinedSection = ({ title, connections }) => (
  <div className="space-y-2">
    <h3 className="text-lg font-medium text-gray-800 mb-3 px-1 flex items-center gap-2">
      <UserX className="w-5 h-5 text-red-500" />
      {title}
    </h3>
    <ConnectionTable 
      connections={connections}
      type="declined"
    />
  </div>
);

export const ConnectionsTab = ({ data, onUpdateConnection, onRefresh  }) => {
  const allContactRequests = [
    ...(data?.contact_requests?.pending_sent_requests || []),
    ...(data?.contact_requests?.pending_received_requests || []),
    ...(data?.contact_requests?.accepted_requests || []),
    ...(data?.contact_requests?.declined_sent_requests || []),
    ...(data?.contact_requests?.declined_received_requests || [])
  ];
  if (!data) return null;

  const sections = [
    {
      title: "Received Express Interests",
      data: data.pending_received_requests,
      type: "pending_received",
      showActions: true,
      onAction: onUpdateConnection,
      icon: <Heart className="w-5 h-5 text-rose-500" />
    },
    {
      title: "Sent Express Interests",
      data: data.pending_sent_requests,
      type: "pending_sent",
      icon: <Send className="w-5 h-5 text-sky-500" />
    },
    {
      title: "Accepted Express Interests",
      data: data.accepted_connections,
      type: "accepted",
      icon: <UserCheck className="w-5 h-5 text-teal-500" />
    },
    {
      title: "Declined by Me",
      data: data.declined_received_requests,
      type: "declined_received",
      icon: <UserX className="w-5 h-5 text-red-500" />
    },
    {
      title: "Declined by Others",
      data: data.declined_sent_requests,
      type: "declined_sent",
      icon: <UserX className="w-5 h-5 text-orange-500" />
    }
  ];

  return (
    <div className="space-y-4">
      {sections.map(section => (
        <div key={section.type} className="bg-white rounded-lg shadow-sm border border-gray-300 overflow-hidden">
          <h3 className="px-6 py-4 border-b border-gray-300 bg-gradient-to-r from-sky-50 to-white flex items-center">
            <span className="text-lg text-gray-800 font-medium flex items-center gap-2">
              {section.icon}
              {section.title}
            </span>
            {section.data.length > 0 && (
              <span className="ml-3 text-gray-500">
                ({section.data.length})
              </span>
            )}
          </h3>
          <ConnectionTable 
            connections={section.data}
            type={section.type}
            showActions={section.showActions}
            onAction={section.onAction}
            contactRequests={section.type === 'accepted' ? allContactRequests : []}
            onRefresh={onRefresh} 
          />
        </div>
      ))}
    </div>
  );
};

export const ContactRequestsTab = ({ data, onUpdateContact  }) => {
  if (!data) return null;

  const sections = [
    {
      title: "Received Contact Requests",
      data: data.pending_received_requests,
      type: "pending_received",
      showActions: true,
      onAction: onUpdateContact,
      icon: <Mail className="w-5 h-5 text-amber-500" />
    },
    {
      title: "Sent Contact Requests",
      data: data.pending_sent_requests,
      type: "pending_sent",
      icon: <Send className="w-5 h-5 text-sky-500" />
    },
    {
      title: "Accepted Contact Requests",
      data: data.accepted_requests,
      type: "accepted",
      icon: <CheckCircle2 className="w-5 h-5 text-teal-500" />
    },
    {
      title: "Declined by Me",
      data: data.declined_received_requests,
      type: "declined_received",
      icon: <XCircle className="w-5 h-5 text-red-500" />
    },
    {
      title: "Declined by Others",
      data: data.declined_sent_requests,
      type: "declined_sent",
      icon: <XCircle className="w-5 h-5 text-orange-500" />
    }
  ];

  return (
    <div className="space-y-4">
      {sections.map(section => (
        <div key={section.type} className="bg-white rounded-lg shadow-sm border border-gray-300 overflow-hidden">
          <h3 className="px-6 py-4 border-b border-gray-300 bg-gradient-to-r from-sky-50 to-white flex items-center">
            <span className="text-lg text-gray-800 font-medium flex items-center gap-2">
              {section.icon}
              {section.title}
            </span>
            {section.data.length > 0 && (
              <span className="ml-3 text-gray-500">
                ({section.data.length})
              </span>
            )}
          </h3>
          <ConnectionTable 
            connections={section.data}
            type={section.type}
            showActions={section.showActions}
            onAction={section.showActions ? onUpdateContact : undefined}
          />
        </div>
      ))}
    </div>
  );
};

export const BlockedUsersTab = ({ data, onUnblock }) => {  // Add onUnblock prop
  if (!data || !Array.isArray(data)) {
    return (
      <div className="text-gray-500 p-6 text-center bg-sky-50 rounded-lg border border-gray-300 text-sm">
        No blocked users found
      </div>
    );
  }

  const handleUnblock = (userId) => {
    if (onUnblock) {
      onUnblock(userId);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-300 overflow-hidden">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <TableHeader className="border-x border-gray-200">User ID</TableHeader>
            <TableHeader className="border-x border-gray-200">Actions</TableHeader>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data.map((block, idx) => (
            <tr key={block.block_id} className={`${idx % 2 === 0 ? 'bg-white' : 'bg-sky-50/30'} hover:bg-sky-50/70 transition-colors`}>
              <TableCell className="border-x border-gray-200">
                {block.user_id}
              </TableCell>
              <TableCell className="text-center border-x border-gray-200">
                <ActionButton
                  variant="reject"
                  onClick={() => handleUnblock(block.user_id)}
                  showIcon={false}
                >
                  Unblock
                </ActionButton>
              </TableCell>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};