import React from 'react';
import { Link } from 'react-router-dom';
import { UserPlus, LogIn, Search, Heart, Users, Shield, Check, Star, Sparkles, Flower } from 'lucide-react';
import kodavaLogo  from './kodava_symbol.png';

const HomePage = () => {
  return (
    <div className="space-y-16 overflow-hidden">

      {/* Hero Section */}
      <section className="relative pt-8 pb-16">

      <div 
          className="absolute inset-0 bg-cover bg-center bg-no-repeat opacity-10 z-0" 
          style={{ backgroundImage: `url(${kodavaLogo})` }}
        ></div>

        {/* Enhanced decorative elements */}
        <div className="absolute top-20 right-20 w-96 h-96 bg-blue-100 rounded-full opacity-20 animate-pulse" style={{ animationDuration: '10s', zIndex: -1 }}></div>
        <div className="absolute bottom-10 left-20 w-96 h-96 bg-teal-100 rounded-full opacity-20 animate-pulse" style={{ animationDuration: '12s', zIndex: -1 }}></div>
        <div className="absolute top-40 left-40 w-64 h-64 bg-purple-100 rounded-full opacity-15 animate-pulse" style={{ animationDuration: '9s', zIndex: -1 }}></div>
        
        {/* Enhanced decorative pattern - top */}
        <div className="absolute top-0 left-0 right-0 h-16 overflow-hidden">
          <svg className="absolute h-32 w-full" preserveAspectRatio="none" viewBox="0 0 1200 120" xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.07 }}>
            <path d="M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18.19 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z" fill="#6366f1" />
          </svg>
        </div>
        
        {/* Enhanced floating decorative elements */}
        <div className="absolute top-20 left-1/4 w-12 h-12 text-indigo-200 opacity-20 animate-bounce" style={{ animationDuration: '4s' }}>
          <Sparkles className="w-full h-full" />
        </div>
        <div className="absolute bottom-40 right-1/4 w-10 h-10 text-teal-200 opacity-20 animate-bounce" style={{ animationDuration: '5s' }}>
          <Star className="w-full h-full" />
        </div>
        
        <div className="max-w-5xl mx-auto text-center px-4 sm:px-6 lg:px-8 relative">
          <div className="inline-flex items-center justify-center mb-6">
            <div className="w-16 h-16 relative">
              <div className="absolute inset-0 bg-gradient-to-r from-teal-300 to-indigo-400 rounded-full opacity-30 animate-ping" style={{ animationDuration: '3s' }}></div>
              <div className="relative flex items-center justify-center w-full h-full bg-gradient-to-r from-teal-400 to-indigo-400 rounded-full shadow-lg">
                <Heart className="h-8 w-8 text-white" />
              </div>
            </div>
          </div>
          
          <h1 className="text-2xl font-bold text-gray-800 sm:text-3xl sm:tracking-tight lg:text-4xl font-serif">
            Find Your Perfect Match
            <span className="block text-teal-500 mt-2">
              Kodava Community
            </span>
          </h1>
          
          <p className="mt-6 text-gray-600 max-w-2xl mx-auto text-lg font-serif">
            Connect with compatible partners who share your values, traditions, and cultural heritage.
          </p>
          
          <div className="mt-6 inline-flex items-center justify-center px-5 py-2 rounded-full bg-teal-50 border border-teal-100">
            <span className="text-teal-700 font-medium text-sm">Exclusive Kodava Matrimony</span>
          </div>
    
          <div className="mt-2 inline-flex items-center justify-center px-5 py-2 rounded-full bg-indigo-50 border border-indigo-100">
            <span className="text-indigo-700 font-medium text-sm">100% Free Service</span>
          </div>

          <div className="mt-2 inline-flex items-center justify-center px-5 py-2 rounded-full bg-purple-50 border border-purple-100">
            <span className="text-purple-700 font-medium text-sm">Verified Profiles Only</span>
          </div>

          <div className="mt-2 inline-flex items-center justify-center px-5 py-2 rounded-full bg-teal-50 border border-teal-100">
            <span className="text-teal-700 font-medium text-sm">Discreet Mode</span>
          </div>
  
          <div className="mt-8 flex flex-wrap justify-center gap-4">
            <Link 
              to="/register" 
              className="inline-flex items-center px-6 py-3 border-2 border-teal-300 text-base font-medium rounded-lg shadow-sm text-white bg-gradient-to-r from-teal-400 to-indigo-400 hover:from-teal-500 hover:to-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-400 transition-all duration-300 font-serif group"
            >
              <div className="relative mr-2">
                <div className="absolute inset-0 rounded-full animate-ping bg-white opacity-20 group-hover:opacity-30" style={{ animationDuration: '2s' }}></div>
                <UserPlus className="h-5 w-5 relative" />
              </div>
              Register Now
            </Link>
            <Link 
              to="/login" 
              className="inline-flex items-center px-6 py-3 border-2 border-indigo-200 shadow-sm text-base font-medium rounded-lg text-indigo-600 bg-white hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400 transition-all duration-300 font-serif"
            >
              <LogIn className="h-5 w-5 mr-2" />
              Login
            </Link>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="bg-gradient-to-b from-white to-teal-50 py-16 relative">
        {/* Decorative wave pattern */}
        <div className="absolute top-0 left-0 right-0 h-12 overflow-hidden">
          <svg className="absolute inset-0 h-full w-full" preserveAspectRatio="none" viewBox="0 0 1200 120" xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.1 }}>
            <path d="M0,0 C100,30 200,70 300,20 C400,-20 500,30 600,50 C700,70 800,30 900,40 L900,0 L0,0 Z" fill="#5eead4" />
          </svg>
        </div>
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="text-center mb-12">
            <div className="inline-block bg-white p-2 rounded-full shadow-sm mb-4">
              <div className="bg-gradient-to-r from-teal-400 to-indigo-400 rounded-full p-2">
                <Sparkles className="h-6 w-6 text-white" />
              </div>
            </div>
            <h2 className="text-2xl font-bold text-teal-600 font-serif">Why Choose Our Platform</h2>
            <p className="mt-3 text-gray-600 max-w-2xl mx-auto">
              We've created a unique space where traditions meet modern connections
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {/* Feature 1 */}
            <div className="bg-white rounded-xl p-6 border border-teal-100 shadow-sm hover:shadow-md transition-all duration-300 group">
              <div className="inline-flex items-center justify-center p-3 bg-teal-50 rounded-lg group-hover:bg-teal-100 transition-colors duration-300">
                <Search className="h-6 w-6 text-teal-500" />
              </div>
              <h3 className="mt-4 text-lg font-medium text-gray-800 font-serif">Advanced Matching</h3>
              <p className="mt-2 text-sm text-gray-600">
                Find compatible partners based on values that matter to you
              </p>
            </div>

            {/* Feature 2 */}
            <div className="bg-white rounded-xl p-6 border border-indigo-100 shadow-sm hover:shadow-md transition-all duration-300 group">
              <div className="inline-flex items-center justify-center p-3 bg-indigo-50 rounded-lg group-hover:bg-indigo-100 transition-colors duration-300">
                <Heart className="h-6 w-6 text-indigo-500" />
              </div>
              <h3 className="mt-4 text-lg font-medium text-gray-800 font-serif">Cultural Focus</h3>
              <p className="mt-2 text-sm text-gray-600">
                Designed specifically for our traditions and heritage
              </p>
            </div>

            {/* Feature 3 */}
            <div className="bg-white rounded-xl p-6 border border-purple-100 shadow-sm hover:shadow-md transition-all duration-300 group">
              <div className="inline-flex items-center justify-center p-3 bg-purple-50 rounded-lg group-hover:bg-purple-100 transition-colors duration-300">
                <Users className="h-6 w-6 text-purple-500" />
              </div>
              <h3 className="mt-4 text-lg font-medium text-gray-800 font-serif">Verified Profiles</h3>
              <p className="mt-2 text-sm text-gray-600">
                Authentic profiles to build trust in our community
              </p>
            </div>

            {/* Feature 4 */}
            <div className="bg-white rounded-xl p-6 border border-teal-100 shadow-sm hover:shadow-md transition-all duration-300 group">
              <div className="inline-flex items-center justify-center p-3 bg-teal-50 rounded-lg group-hover:bg-teal-100 transition-colors duration-300">
                <Shield className="h-6 w-6 text-teal-500" />
              </div>
              <h3 className="mt-4 text-lg font-medium text-gray-800 font-serif">Privacy Control</h3>
              <p className="mt-2 text-sm text-gray-600">
                You decide who sees your information
              </p>
            </div>
          </div>
          
          {/* Decorative elements */}
          <div className="absolute -bottom-6 left-1/4 transform -translate-x-1/2">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.15 }}>
              <circle cx="20" cy="20" r="20" fill="#5eead4"/>
            </svg>
          </div>
          <div className="absolute -bottom-10 right-1/4 transform translate-x-1/2">
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ opacity: 0.15 }}>
              <path d="M30 0L36.5 23.5L60 30L36.5 36.5L30 60L23.5 36.5L0 30L23.5 23.5L30 0Z" fill="#6366f1"/>
            </svg>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-gradient-to-r from-teal-400 to-indigo-500 relative">
        <div className="absolute top-0 w-full h-12 bg-white" style={{ clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 0)' }}></div>
        
        {/* Decorative floating elements */}
        <div className="absolute top-1/4 left-1/5 opacity-10">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 0L14.6942 8.2918L23.4127 9.5279L17.1855 15.7082L18.7082 24L12 19.5L5.2918 24L6.8145 15.7082L0.5873 9.5279L9.3058 8.2918L12 0Z" fill="white"/>
          </svg>
        </div>
        <div className="absolute bottom-1/4 right-1/5 opacity-10">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="white"/>
          </svg>
        </div>
        
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between relative">
          <div>
            <div className="inline-flex items-center justify-center mb-4 bg-white bg-opacity-20 p-2 rounded-full">
              <Heart className="h-6 w-6 text-white" />
            </div>
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-3xl font-serif">
              Ready to find your match?
            </h2>
            <div className="mt-3 inline-flex items-center justify-center px-4 py-1 rounded-full bg-white bg-opacity-20">
              <span className="text-white text-sm font-medium">Free</span>
            </div>
          </div>
          
          <div className="mt-8 flex flex-wrap lg:mt-0 lg:flex-shrink-0 gap-4">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/register"
                className="inline-flex items-center justify-center px-5 py-3 border-2 border-white text-base font-medium rounded-lg text-teal-600 bg-white hover:bg-teal-50 shadow-sm transition-all duration-200 font-serif group"
              >
                <div className="mr-2 relative">
                  <div className="absolute inset-0 rounded-full animate-ping bg-teal-300 opacity-0 group-hover:opacity-60" style={{ animationDuration: '1.5s' }}></div>
                  <UserPlus className="h-5 w-5 relative" />
                </div>
                Get started
              </Link>
            </div>
          </div>
        </div>
        
        <div className="absolute bottom-0 w-full h-12 bg-white" style={{ clipPath: 'polygon(0 100%, 100% 0, 100% 100%, 0 100%)' }}></div>
      </section>
    </div>
  );
};

export default HomePage;