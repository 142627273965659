export const feetInchesToCm = (feet, inches) => {
  if (!feet || inches === undefined) return '';
  return Math.round((feet * 12 + inches) * 2.54);
};

export const generateHeightOptions = () => {
  const options = [];
  const minFeet = 4;
  const maxFeet = 6;
  
  for (let feet = minFeet; feet <= maxFeet; feet++) {
    // For 4 feet, start from 4 inches
    const startInches = feet === minFeet ? 4 : 0;
    // For 6 feet, stop at 9 inches
    const endInches = feet === maxFeet ? 9 : 11;
    
    for (let inches = startInches; inches <= endInches; inches++) {
      const cm = feetInchesToCm(feet, inches);
      options.push({ feet, inches, cm });
    }
  }
  
  return options;
};

export const formatDate = (dateString) => {
  if (!dateString) return '';
  // Use UTC methods to avoid time zone issues
  const date = new Date(dateString);
  const day = date.getUTCDate();
  const month = date.toLocaleString('default', { month: 'short', timeZone: 'UTC' });
  const year = date.getUTCFullYear();
  return `${day.toString().padStart(2, '0')} ${month} ${year}`;
};

export const cmToFeetInches = (cm) => {
  if (!cm) return null;
  
  // Convert cm to inches and round to nearest inch
  const totalInches = Math.round(cm / 2.54);
  
  // Calculate feet and remaining inches
  const feet = Math.floor(totalInches / 12);
  const inches = totalInches % 12;
  
  return `${feet}' ${inches}" (${cm} cm)`;
};