// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer, { 
  refreshTokenStart, 
  refreshTokenSuccess, 
  refreshTokenFailure,
  logout
} from './components/auth/authSlice';
import { setAuthDispatch } from './services/api';

const store = configureStore({
  reducer: {
    auth: authReducer,
    // Add other reducers here as needed
  },
});

// Set up the dispatcher for API auth actions
setAuthDispatch(action => {
  switch (action.type) {
    case 'refreshTokenStart':
      store.dispatch(refreshTokenStart());
      break;
    case 'refreshTokenSuccess':
      store.dispatch(refreshTokenSuccess(action.payload));
      break;
    case 'refreshTokenFailure':
      store.dispatch(refreshTokenFailure());
      break;
    case 'logout':
      store.dispatch(logout());
      break;
    default:
      break;
  }
});

export default store;